export default {
  bind: function(el, { value = {} }, vnode) {
    let { title, date, onShorcut, onApply, onDate } = value;

    if (!date) date = new Date();

    el.style.userSelect = "none";
    el.style.cursor = "pointer";

    el.onfocus = (e) => {
      e.target.blur();
    };

    el.onclick = function(e) {
      e.preventDefault();
      Go.Vue.view({
        title: title,
        component: () => import("@/components/widgets/Calendar.vue"),
        class: "menu bottom-mbl",
        closeOutside: true,
        cardStyle: {
          "min-height": "calc(var(--screen-height)/4)",
          "max-height": "calc(var(--screen-height) - calc(var(--header-height) * 2))",
        },
        date: date,
        animation: "bottomIn",
        onApply: function(date) {
          el.value = date;
          if (Go.is(onApply, "function")) onApply(date, value);
          if (Go.is(onDate, "function")) onDate(date, value);
        },
        onShorcut: onShorcut,
      });
    };
  },
};
