const GO_NUMBER = {};

export default GO_NUMBER;

GO_NUMBER.random = function(min = 0, max = 10000) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

GO_NUMBER.number_convert = function(value, from = "bytes", to = "megabytes") {
  const units = {
    bytes: 1,
    kilobytes: 1024,
    megabytes: 1048576,
    gigabytes: 1073741824,
    terabytes: 1099511627776,
    petabytes: 1125899906842624,
    exabytes: 1152921504606846976,
    zettabytes: 1180591620717411303424,
    yottabytes: 1208925819614629174706176,
  };

  return (value * units[from]) / units[to];
};

GO_NUMBER.toPositive = function(number) {
  return Math.abs(number);
};

GO_NUMBER.positive = function(number) {
  return Math.abs(number);
};

GO_NUMBER.toNegative = function(number) {
  return Math.abs(number) * -1;
};

GO_NUMBER.negative = function(number) {
  return Math.abs(number) * -1;
};

GO_NUMBER.onlyNumber = function(number) {
  if (!Go.is(number, "string")) {
    return number;
  }

  return Number(number.replace(/\D/g, ""));
};

GO_NUMBER.onlyNum = function() {
  return GO_NUMBER.onlyNumber(...arguments);
};
