import api from "@/api";
import router from "../router";
import socket from "../api/socket";
import store from "@/store";
import { parseJwt } from "../assets/js/session";
import global from "../assets/js/global";

export default {
  state: () => ({
    user: parseJwt(process.env.VUE_APP_TOKEN_NAME),
    profile: null,
    ageAccepted: false,
    UserMenu: false,
    profileReview: false,
    profileExplicitContentAlert: false,
    files: { items: [], total: 0 },
    newFile: false,
    fileMenu: null,
    fileEdit: null,
    fileDelete: null,
  }),
  mutations: {
    setAgeAccepted: function(state, status) {
      state.ageAccepted = status;
    },
    UserMenu: function(state, status) {
      if (String(status) === "undefined") {
        status = true;
      }
      state.UserMenu = status;
    },
    setUserNotReadNotifications: function(state, data) {
      state.profile.notifications = data.total;
    },
  },
  actions: {
    initSocketUser: function({ commit, dispatch }) {
      if (socket.io) return;
      socket.start({});
      dispatch("listenMessages");
      dispatch("listenProgress");
      dispatch("listenPostProgress");
      dispatch("listenSocketDefaults");
      dispatch("listenNotifications");
      dispatch("listenCounter");
    },
    updateUser: async function({ commit, state, dispatch }, data) {
      if (data && data.token) {
        localStorage.setItem(process.env.VUE_APP_TOKEN_NAME, data.token);
      }
      state.user = parseJwt(process.env.VUE_APP_TOKEN_NAME);
      commit("resetComments");
      dispatch("resetMessages");
      dispatch("initSocketUser");
      return state.user;
    },
    checkUser: async function({ commit, state, dispatch }) {
      if (global.specialChars(state.user.user)) {
        await Go.sleep(6000);
        Go.Vue.view({
          close: false,
          class: "menu",
          title: "Account",
          animation: "topIn",
          component: () => import("../components/user/UpdateUserName.vue"),
        });
      }
    },
    removeUser: async function({ commit, state }) {
      localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME);
      state.user = null;
      socket.stop({});
      return state.user;
    },
    loginSuccessRedirect: function({ commit, state }) {
      const nav = localStorage.getItem("meta-nav") && JSON.parse(localStorage.getItem("meta-nav"));
      let next = nav?.approvedTo ? nav.approvedTo : "/";
      const payload = store.getters["payload"];

      if (payload.after && payload.post) {
        next = `/${payload.creator}?post=${payload.post}`;
        router.push(next).catch(() => {});
        commit("payload", { after: "", post: "", creator: "" });
        return;
      }

      if (next === "currentPath") {
        next = nav?.fullPath ? nav.fullPath : "/";
      }

      router.push(next).catch(() => {});
      localStorage.getItem("meta-nav") && localStorage.removeItem("meta-nav");
    },
    getProfile: async function({ commit, state, dispatch }, callback) {
      if (!state.user) return;

      const profile = api.get(process.env.VUE_APP_API_HOST + "user/profile");

      profile.then((response) => {
        state.profile = response.data.profile;
        dispatch("checkUser");
        if (Go.is(callback, "Function")) {
          callback(response.data.profile);
        }
      });

      profile.catch((error) => {
        Go.Vue.alert({ message: Go.getErrorMessage(error), onClose: () => location.reload() });
      });
    },
  },
};
