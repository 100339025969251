const KeyPress = function(e) {
  this.e = e;
  this.key(e);
};

const Shorcut = function(e) {
  if (e.code === "Escape") {
    this.elements = document.querySelectorAll(".onEscape, [onEscape], [onescape], .onEscape .button.close");
    this.elements.forEach((element) => {
      element.click();
    });
  }

  this.analizeKey = new KeyPress(e);
};

document.addEventListener("keydown", Shorcut, false);

KeyPress.prototype.key = function(e) {
  // check if ctrl + enter is pressed
  if (e.ctrlKey && e.keyCode === 13) {
    Go.Vue.UserAdminMenu();
  }
};
