const Image = {};

export default Image;

Image.read = function(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function(e) {
      let url = URL.createObjectURL(file);
      resolve({ src: url });
    };
    reader.onerror = function(e) {
      reject(e);
    };
    reader.readAsDataURL(file);
  });
};
