export default {
  state: () => ({
    word: "",
    topCreators: {
      page: 1,
      limit: 30,
      items: [],
    },
  }),
  actions: {
    setSearchTopCreators: ({ commit, state }, { items, page, limit }) => {
      state.topCreators.items = items;
      state.topCreators.limit = limit;
      state.topCreators.page = page;
    },
  },
};
