import Animate from "./go.animate.js";

const Style = {};

export default Style;

Style.setStyle = function(el, style = {}) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  if (!el) {
    return;
  }

  for (let key in style) {
    el.style[key] = style[key];
  }
};

Style.animate = function(data = {}) {
  return new Animate(data);
};
