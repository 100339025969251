<template>
  <main class="view-control" v-if="$user && $user.name">
    <CommentsView v-if="$route.query.comments" />
    <LikesView v-if="$route.query.likes" />
    <Messages v-if="$route.query.messages" />
    <MessagesChat v-if="$route.query.messages && $route.query.messages !== 'wall'" />
    <AdminPostReviewConfirm v-if="postReview" />
    <AdminMenuMobile v-if="AdminMenuMobile" />
    <CreatorMenuMobile v-if="CreatorMenuMobile" />
    <Statistics v-if="$route.query.statistics" />
    <PostVisor v-if="post_view" />
    <StoryVisor v-if="story_view" />
    <MediaVisor />
    <UserProfileReview v-if="isUserProfileReview" />
    <UserProfileExplicitContentAlert v-if="isUserProfileExplicitContentAlert" />
    <UserNewFile v-if="$store.state.user.newFile || $store.state.user.fileEdit" />
    <UserFileMenu v-if="$store.state.user.fileMenu" />
    <UserFileDelete v-if="$store.state.user.fileDelete" />
    <AppMenu v-if="isAppMenu" />
    <Unlock />
    <FeatureSoon v-if="$store.state.FeatureSoon" />
    <ViewsModalsController />
    <Incentives />
    <NotificationsLive v-if="hasNotifications" />
  </main>
  <main class="view-control" v-else>
    <PostVisor v-if="post_view" />
    <StoryVisor v-if="story_view" />
    <AgeAdvice v-if="ageAcceptConfirm === 'confirm'" />
    <ViewsModalsController />
    <Incentives />
  </main>
</template>

<script>
export default {
  components: {
    ViewsModalsController: () => import("./ViewsModalsController.vue"),
    CommentsView: () => import("../components/comments/CommentsView.vue"),
    AdminPostReviewConfirm: () => import("../components/admin/AdminPostReviewConfirm.vue"),
    MediaVisor: () => import("../components/widgets/MediaVisor.vue"),
    PostVisor: () => import("../components/widgets/PostVisor.vue"),
    StoryVisor: () => import("../components/stories/StoryVisor.vue"),
    LikesView: () => import("../components/likes/LikesView.vue"),
    Messages: () => import("../components/messages/Messages.vue"),
    MessagesChat: () => import("../components/messages/MessagesChat.vue"),
    Statistics: () => import("../components/statistics/Statistics.vue"),
    AdminMenuMobile: () => import("../components/admin/AdminMenuMobile.vue"),
    CreatorMenuMobile: () => import("../components/creator/CreatorMenuMobile.vue"),
    AgeAdvice: () => import("../components/landing/AgeAdvice.vue"),
    AppMenu: () => import("../components/app/AppMenu.vue"),
    UserProfileReview: () => import("../components/user/UserProfileReview.vue"),
    UserProfileExplicitContentAlert: () => import("../components/user/UserProfileExplicitContentAlert.vue"),
    Unlock: () => import("../components/buy/Unlock.vue"),
    UserNewFile: () => import("../components/user/UserNewFile.vue"),
    UserFileMenu: () => import("../components/user/UserFileMenu.vue"),
    UserFileDelete: () => import("../components/user/UserFileDelete.vue"),
    FeatureSoon: () => import("../components/widgets/FeatureSoon.vue"),
    NotificationsLive: () => import("../components/notifications/NotificationsLive.vue"),
    Incentives: () => import("../components/incentives/Incentives.vue"),
  },
  computed: {
    postReview: function() {
      return this.$store.state.posts.postReview;
    },
    AdminMenuMobile: function() {
      return this.$store.state.admin.AdminMenuMobile;
    },
    CreatorMenuMobile: function() {
      return this.$store.state.creator.CreatorMenuMobile;
    },
    ageAcceptConfirm: function() {
      return this.$store.state.user.ageAccepted;
    },
    post_view: function() {
      return this.$route.query.post_view;
    },
    story_view: function() {
      return this.$route.query.story_view;
    },
    isAppMenu: function() {
      return this.$store.state.user.UserMenu;
    },
    isUserProfileReview: function() {
      return this.$store.state.user.profileReview || this.$route.query.profile === "review";
    },
    isUserProfileExplicitContentAlert: function() {
      return this.$store.state.user.profileExplicitContentAlert;
    },
    hasNotifications: function() {
      return this.$store.state.notifications.lives.length > 0;
    },
  },
};
</script>
