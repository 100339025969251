const Misc = {};

export default Misc;

Misc.normalizeUrl = function(url) {
  const ignore = ["http", "https", "/", "www.", "itools/", "apps/"];

  const fixUrl = (start, matrix) => {
    matrix = ["apps/", "itools/"];
    if (matrix.includes(start)) {
      return `/${url}`;
    }
    return `${url}`;
  };

  for (const i in ignore) {
    if (url.startsWith(ignore[i])) {
      return fixUrl(ignore[i]);
    }
  }

  let baseUrl = document.querySelector("base")?.href;

  const hasProcess = typeof process !== "undefined";

  if (hasProcess) {
    return `/${url}`;
  }

  if (baseUrl) {
    return `${baseUrl}/${url}`;
  }

  let appName = Language.get("app_name");

  if (appName) {
    return `/${this.normalize(appName)}/${url}`;
  }

  return `/${this.normalize("nextos")}/${url}`;
};

Misc.normalize = function(str) {
  // filter only numbers and letters
  str = str.replace(/[^a-z0-9]/gi, "");

  // remove all spaces
  str = str.replace(/\s/g, "");

  // lowercase
  str = str.toLowerCase();

  return str;
};

Misc.encode_url = function(url) {
  return encodeURI(url).replace(/[!'()*]/g, function(c) {
    return "%" + c.charCodeAt(0).toString(16);
  });
};

Misc.decode_url = function(url) {
  return decodeURIComponent(url);
};

Misc.url_encode = function(url) {
  return Misc.encode_url(url);
};
