import { ifAuthenticated, ifNotAuthenticated } from "../assets/js/session";
import links from "../assets/js/links";

const routes = [
  {
    path: "/",
    name: "home",
    alias: ["/home", "/admin", "/notifications", "/members"],
    component: () => import("../views/Home.vue"),
    meta: {
      sitemap: {
        priority: 1,
      },
    },
    children: [
      {
        path: "/user",
        beforeEnter: (to, from, next) => {
          next(links["my-profile"]);
        },
      },
      {
        path: "/user/credits",
        beforeEnter: (to, from, next) => {
          next(links["my-credits"]);
        },
      },
      {
        path: "/user/subscriptions",
        beforeEnter: (to, from, next) => {
          next(links["my-subscriptions"]);
        },
      },
      {
        path: "/post/create",
        component: () => import("../components/creator/CreatorPostNew.vue"),
        beforeEnter: ifAuthenticated,
        meta: { requireRoles: ["creator"], deniedTo: "/", approvedTo: "next()" },
      },
      {
        path: "/post/edit/:id",
        component: () => import("../components/creator/CreatorPostNew.vue"),
        beforeEnter: ifAuthenticated,
        meta: { requireRoles: ["creator"], deniedTo: "/", approvedTo: "next()" },
      },
      {
        path: "/user/gifts",
        beforeEnter: (to, from, next) => {
          next(links["creator-statistics"]);
        },
      },
    ],
  },
  {
    path: "/terms",
    component: () => import("../components/support/PageDocs.vue"),
    meta: { component: "terms", title: "Terms", subtitle: "My Fans 1A Terms and Conditions" },
  },
  {
    path: "/privacy",
    component: () => import("../components/support/PageDocs.vue"),
    meta: { component: "privacy", title: "Privacy", subtitle: "My Fans 1A Privacy Policy" },
  },
  {
    path: "/usc2257",
    component: () => import("../components/support/PageDocs.vue"),
    meta: { component: "USC2257", title: "18 U.S.C & 2257 STATEMENT", subtitle: "18 U.S.C 2257 Record-Keeping Requirements Compliance Statement" },
  },
  {
    path: "/dmca",
    component: () => import("../components/support/PageDocs.vue"),
    meta: { component: "DMCA", title: "DMCA", subtitle: "DMCA Policy – My Fans 1A®" },
  },
  {
    path: "/support/billing",
    component: () => import("../components/support/PageDocs.vue"),
    meta: { component: "Billing", title: "Billing", subtitle: "Billing support – My Fans 1A®" },
  },
  {
    path: "/content-removal",
    component: () => import("../components/support/Removal.vue"),
  },
  {
    path: "/support",
    component: () => import("../views/View.vue"),
    children: [
      {
        path: "",
        component: () => import("../components/support/support.vue"),
      },
      {
        path: "/faq",
        component: () => import("../components/support/faq.vue"),
      },
    ],
  },
  
  {
    path: "/post/:post",
    component: () => import("../components/home/PostView.vue"),
  },
  {
    path: "/gallery",
    component: () => import("../views/Home.vue"),
    children: [
      {
        path: "/gallery",
        beforeEnter: (to, from, next) => {
          next("/?feed=gallery");
        },
      },
    ],
  },
  {
    path: "/:creator",
    component: () => import("../components/feed/FeedCreator.vue"),
    beforeEnter: ifAuthenticated,
    meta: { deniedTo: "/login", approvedTo: "currentPath" },
    children: [
      {
        path: "/:creator/post/create",
        component: () => import("../components/creator/CreatorPostNew.vue"),
      },
    ],
  },
];

export default routes;
