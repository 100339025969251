const Posts = (module.exports = {});

Posts.postsMiddleware = function(posts, { page } = {}) {
  let isFeed = Go.Vue.$route.query.feed_mode !== "plus";
  isFeed &&= Go.Vue.$route.query.feed_mode !== "grid";

  const currCreator = Go.Vue.$store.state.creator.current && !Go.Vue.isAdminCreator();

  if (currCreator && page === 1 && isFeed) {
    const postConnect = Posts.createConnectPost();
    Go.pushOn(posts, postConnect, 1);
  }

  return posts;
};

Posts.createConnectPost = function() {
  const post = {
    _id: Go.uuid(),
    user: Go.Vue.getAdminUser(),
    messageComponent: "PostConnectCard",
    isAdmin: true,
    cardClass: `cleanPostCard`,
    message: `<div>Seng gift</div>`,
    media: [],
    creator: Go.Vue.$store.state.creator.current,
  };

  return post;
};
