const Media = {};

export default Media;

Media.isMedia = function(media, type) {
  this.isType = "";
  this.hasStream = media.info && media.info.streams && media.info.streams[0];

  if (this.hasStream) {
    this.isType = this.hasStream.codec_type.includes(type);
  }

  if (this.isType) {
    return this.isType;
  }

  this.isType = media.type.includes(type);

  if (this.isType) {
    return this.isType;
  }

  this.path = media.src;

  this.extension = Go.getExtensionFromPath(this.path);
  this.beforeExtension = this.path.substring(0, this.path.lastIndexOf("."));
  this.beforeExtension = Go.getExtensionFromPath(this.beforeExtension);

  if (type === "video" && Media.mediaVideoFormats.includes(this.extension)) {
    return true;
  }

  if (type === "video" && Media.mediaVideoFormats.includes(this.beforeExtension)) {
    return true;
  }

  return false;
};

Media.mediaVideoFormats = [
  "mp4",
  "webm",
  "ogg",
  "ogv",
  "avi",
  "mov",
  "qt",
  "wmv",
  "asf",
  "asx",
  "flv",
  "swf",
  "m4v",
  "mkv",
  "vob",
  "mpg",
  "mpeg",
  "mpe",
  "mpv",
  "m2v",
  "m4p",
  "m4b",
  "m4r",
  "3gp",
  "3g2",
];
