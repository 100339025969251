export default {
  fadeIn: {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  leftIn: {
    from: {
      transform: "translateX(-100%)",
    },
    to: {
      transform: "translateX(0)",
    },
  },
  rightIn: {
    from: {
      transform: "translateX(100%)",
    },
    to: {
      transform: "translateX(0)",
    },
  },
  topIn: {
    from: {
      opacity: 0,
      transform: "translate3d(0, -100%, 0)",
    },
    to: {
      opacity: 1,
      transform: "translate3d(0, 0, 0)",
    },
  },
  bottomIn: {
    from: {
      opacity: 0,
      transform: "translate3d(0, 100%, 0)",
    },
    to: {
      opacity: 1,
      transform: "translate3d(0, 0, 0)",
    },
  },
  zoomIn: {
    from: {
      transform: "scale(0.5)",
    },
    to: {
      transform: "scale(1)",
    },
  },
  zoomOut: {
    from: {
      transform: "scale(1.5)",
    },
    to: {
      transform: "scale(1)",
    },
  },
  rotateIn: {
    from: {
      transform: "rotate(-90deg)",
    },
    to: {
      transform: "rotate(0)",
    },
  },
  midTopIn: {
    from: {
      opacity: 0,
      transform: "translate3d(0, -50%, 0)",
    },
    to: {
      opacity: 1,
      transform: "translate3d(0, 0, 0)",
    },
  },
  midBottomIn: {
    from: {
      opacity: 0,
      transform: "translate3d(0, 50%, 0)",
    },
    to: {
      opacity: 1,
      transform: "translate3d(0, 0, 0)",
    },
  },
  slideBottomIn: {
    from: {
      opacity: 0,
      transform: "translateY(100%)",
    },
    to: {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
  slideBottomIn3D: {
    from: {
      opacity: 0,
      transform: "translateZ(-100px) translateY(100%)",
    },
    to: {
      opacity: 1,
      transform: "translateZ(0) translateY(0)",
    },
  },
};
