const Notification = function(data) {
  this.notification = data.notification;
  this.payload = data.payload;
  this.state = data.state;
  this.product = this.payload && this.payload.product;
  this.init();
};

export default Notification;

Notification.prototype.init = function() {
  this.live = {
    _id: this.notification._id,
    message: this.notification.message,
  };

  if (this.product) {
    this.productImg = this.getProductImg();
  }

  if (this.productImg) {
    this.live.message = `<span class="notificationLiveProductImage" style="--img:url('${this.productImg}')"></span>`;
    this.live.message += `<span class="notificationLiveText">${this.notification.message}</span>`;
  }

  this.state.lives.push(this.live);
};

Notification.prototype.getProductImg = function() {
  this.media = this.product.media[0];
  return this.media && this.media.src;
};
