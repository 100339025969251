const en1 = require("./en1");

module.exports = {
  ...en1,
  app_name: "My Fans 1A",
  app_key_name: "myfans1a",
  hi: "Hi",
  all: "All",
  dollars: "Dollars",
  billing: "Billing",
  usd: "USD",
  verifications: "Verifications",
  verify_identity: "Verify your identity",
  verify_identity_title: "Verify your identity and enjoy the full user experience",
  verify_identity_advatage_1: "Customize your profile",
  verify_identity_advatage_1_desc: "Customize your profile to show your real name and your photo",
  verify_identity_advatage_2: "Get free credits",
  get_up_to: "Get up to",
  verify_identity_go: "Verify my identity",
  complete_profile_info_title: "Complete your profile information",
  accept_user_terms: "I accept the user terms",
  id_card_label: "ID Card",
  passport_label: "Passport",
  drive_license_label: "Driving License",
  verify_result_title: "Verify result",
  verify_progress_text: "Your identity verification is being processed, please wait ...",
  verify_progress_time: "This process can take up to 24 to 72 hours to complete",
  verifications_label: "Verifications",
  verify_reject: "Reject",
  verify_accept: "Accept",
  idcard: "ID Card",
  passport: "Passport",
  drivelic: "Driving License",
  view_more: "View more",
  notifications: "Notifications",
  credits: "Credits",
  free: "Free",
  verifime_now: "Verify me now",
  admin_label: "Admin",
  creator_label: "Creator",
  user_label: "User",
  accout_already_verfified_legend: "Your account is already verified",
  enjoy_full_user_experience: "Enjoy the full user experience",
  enjoy: "Enjoy",
  content_review: "Content review",
  content_review_info: "Before your content is published, it must be reviewed by an administrator. This can take from 24 up to 72 hors.",
  avatar: "Avatar",
  cover: "Cover",
  content: "Content",
  status: "Status",
  uploaded: "Uploaded",
  explicit_content_policy: "Explicit content policy",
  explicit_content_deny: "Deny the following:",
  explicit_content_deny_1:
    "Content that denigrates a natural person or group of natural persons, or promotes hatred or discrimination against them because of their ethnicity or race, religion, disability, age, nationality, veteran status, sexual orientation, gender, gender identity, or any other characteristic that is associated with systemic marginalization or discrimination",
  explicit_content_deny_2: "Content that harasses, stalks or intimidates a natural person or a group of natural persons",
  explicit_content_deny_3: "Content that is hateful, racist, or otherwise offensive to a natural person or a group of natural persons",
  explicit_content_deny_4: "Content that is intended to exploit others",
  explicit_content_deny_5: "Content that promotes violence or aggression",
  explicit_content_sexual_deny_1: "Content that could be construed as promoting a sexual act in exchange for compensation.",
  explicit_content_sexual_deny_2: "Content promoting the sexual exploitation of minors.",
  i_inderstand: "I understand",
  by_click_terms: "By clicking on the button below, you confirm that you have read and accepted the Terms and Conditions",
  file_for: "File for",
  media_for: "Media for",
  accept_user_terms_desc: "By clicking on the button below, you accept the terms and conditions of the platform",
  users: "Users",
  documents: "Documents",
  profile: "Profile",
  picture: "Picture",
  statistics_label: "Statistics",
  others_label: "Others",
  posts_label: "Posts",
  stories_label: "Stories",
  address_company: "18501 Pines Blvd Suite #300 Pembroke Pines, FL 33029",
  pending: "Pending",
  reject: "Reject",
  rejected: "Rejected",
  accept: "Accept",
  cancel: "Cancel",
  language: "Language",
  check_content_submit_1:
    "I certify under penalty of perjury that I have verified the identity and age of all persons depicted in the content to ensure that all persons depicted are adults, and I can provide supporting documents on request.",
  check_content_submit_2:
    "I certify under penalty of perjury that I have obtained and am keeping on record written consent from all persons depicted in the content to be uploaded specific to the following: (i) consent to be depicted in the content; (ii) consent to allow for the public distribution of the content and to upload the content to the Website; and (iii) if the content will be made available for downloading by other users, consent to have the content downloaded.",
  check_content_submit_3:
    "I certify that this submission otherwise complies with the <a bold link='/terms' target='_blank'>Terms-of-Service Agreement</a> and the Creator Agreement.",
  i_certify: "I certify",
  files: "Files",
  file: "File",
  not_files_found: "No files found",
  file_name: "File name",
  video_icon_1a: `https://myfans1a.s3.amazonaws.com/miscellaneous/icon-video-50-01.png`,
  uploading: "Uploading",
  invalid_file_type: "Invalid file type",
  delete_file: "Delete file",
  delete: "Delete",
  delete_file_confirm: "Are you sure you want to delete this file?",
  deleting: "Deleting",
  terms_and_conditions: "Terms and Conditions",
  privacy_policy: "Privacy Policy",
  billing_support: "Billing Support",
  contact_us: "Contact Us",
  billing_inquiries_message:
    "For Billing Inquiries, or to cancel your membership, please visit <a target='_blank' href='http://cs.segpay.com/'>Segpay.com</a>, our authorized sales agent.",
  contact_for_any_inquiries: "Contact for any inquiries",
  feature_is_comming: "Feature is coming soon",
  feature_is_comming_desc:
    "This feature is under development, our platform is constantly updated to provide you with the best user experience, please try again later.",
  development: "Development",
  continue: "Continue",
  with: "With",
  creators_content_is_coming: "Creators content is coming soon",
  fans_service_is_coming: "Fans Service on Hold till further Notice",
  pay_methods: "Pay Methods",
  check_out: "Check out",
  total_users: "Total Users",
  total_fans: "Total Fans",
  total_creators: "Total Creators",
  from: "From",
  to: "To",
  period: "Period",
  posts: "Posts",
  delete_post: "Delete post",
  delete_post_confirm: "Are you sure you want to delete this post?",
  read_more: "Read more",
  manage_your_files: "Manage your files",
  my_content: "My content",
  all_your_notifications: "All your notifications",
  no_has_notifications: "You don't have notifications",
  no_has_subscribers: "You don't have subscribers",
  this_post_was_deleted: "This post was deleted",
  public: "Public",
  subscriber: "Subscriber",
  purchase: "Purchase",
  price: "Price",
  video_poster_title: "Video poster",
  capture: "Capture",
  last_seen: "Last seen",
  online: "Online",
  creating_your_post: "Creating your post",
  close: "Close",
  close_all: "Close all",
  select_file: "Select a file",
  my_subscribers: "My subscribers",
  all_your_subscribers: "All your subscribers",
  sending_post: "Sending post",
  processing: "Processing",
  new: "New",
  approved: "Approved",
  rejected: "Rejected",
  pending: "Pending",
  plus: "Plus",
  my_feed: "My feed",
  edit_post: "Edit post",
  edit_story: "Edit story",
  create_post: "Create post",
  create_story: "Create story",
  premium: "Premium",
  landing_page: "Landing page",
  slider: "Slider",
  top_creators: "Top creators",
  stories: "Stories",
  update: "Update",
  updating: "Updating",
  success: "Success",
  position: "Position",
  user_platform_active_message: "User is active on the platform",
  bought: "Bought",
  saved: "Saved",
  all_content: "All content",
  finish: "Finish",
  statistics_label: "Statistics",
  subscription: "Subscription",
  subscribe: "Subscribe",
  monthly: "Monthly",
  month: "Month",
  display_name: "Display name",
  technical_support: "Technical Support",
  office_hours: "Office hours",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
  closed: "Closed",
  explore_all_posts: "Explore all posts",
  message: "Message",
  send_flowers: "Send flowers",
  flowers: "Flowers",
  gift: "Gift",
  tip: "Tip",
  my_stories: "My stories",
  meet_our_top_creators: "Meet our top creators",
  select_language: "Select language",
  home: {
    path: "/",
    title: "Home",
    description: "We created and develop top models",
  },
  about: {
    path: "/about",
    title: "About us",
    description: "Know about us",
  },
  gallery: {
    path: "/gallery",
    title: "Our gallery",
    description: "Our gallery",
  },
  blog: {
    path: "/blog",
    title: "Our blog",
    description: "Our blog",
  },
  contact: {
    path: "/contact",
    title: "Contact us",
    description: "Contact us",
  },
  help: {
    path: "/help",
    title: "FAQ",
    description: "FAQ",
  },
  signup: {
    path: "/signup",
    title: "Sign Up",
    description: "Sign Up",
  },
  error: {
    title: "An error has occurred",
    reload_btn: "Reload",
    "404": {
      title: "Sorry",
      description: "Page not found",
      reason: "The link you followed may be broken, or the page may have been removed.",
    },
    "500": {
      title: "Sorry",
      description: "Server error",
      reason: "An error has been found on the application server.",
    },
  },
  admin: {
    path: "/admin",
    title: "Management",
    ratings: {
      title: "Valuations",
      header: {
        btn_all: "All",
        btn_approved: "Approved",
        btn_disapproved: "Disapproved",
        btn_unchecked: "New",
      },
    },
  },
  income: {
    sort: {
      old: "Older",
      recent: "Most recent",
      max: "Higher value",
      min: "Lower value",
    },
  },
  post: {
    "admin-review-confirm": "The post status will change to",
  },
  words: {
    to: "to",
    new: "New",
    newa: "New",
    admitted: "Admitted",
    approve: "Approve",
    approved: "Approved",
    disapprove: "Disapprove",
    disapproved: "Disapproved",
    noadmitted: "No admitted",
    noselected: "No selected",
    selected: "Selected",
    waiting: "Waiting",
    pending: "Pending",
    hired: "Hired",
    deserter: "Deserter",
    night: "Night",
    late: "Late",
    morning: "Morning",
    open: "Open",
    phone: "Phone",
    whatsapp: "Whatsapp",
    email: "Email",
    all: "All",
    day: "Day",
    month: "Month",
    months: "Months",
    year: "Year",
    medellin: "Medellín",
    pereira: "Pereira",
    cucuta: "Cúcuta",
    bogota: "Bogotá",
    others: "Others",
    collect: "Collect",
    tips: "Tips",
    tip: "Tip",
    flower: "Flowers",
    flowers: "Flowers",
    gift: "Gift",
    gifts: "Gifts",
    message: "Message",
    messages: "Messages",
    live: "Live",
    "order by": "Order by",
    reply: "Reply",
    send: "Send",
    likes: "Likes",
    like: "Like",
    comments: "Comments",
    accept: "Accept",
    cancel: "Cancel",
    confirm: "Confirm",
    statistics: "Statistics",
    "active-fans": "Active fans",
    "expired-fans": "Expired fans",
    last: "Last",
    subscriptions: "Subscriptions",
    earnings: "Earnings",
    total: "Total",
    users: "Users",
    posts: "Posts",
    general: "General",
    sales: "Sales",
    content: "Content",
    credits: "Credits",
    dollars: "Dollars",
    creators: "Creators",
    fans: "Fans",
    subscribers: "Subscribers",
    connections: "Connections",
    "connection-time": "Connection time",
    "sales-bags": "Sales bags",
    bags: "Bags",
    of: "Of",
    "credit-bag": "Credit bag",
    stories: "Stories",
    report_post: "Report post",
    complaints: "Complaints",
    admin: "Admin",
    post: "Post",
    creator: "Creator",
    become_creator: "Become creator",
    become_creator_title: "Apply to the world's most exclusive fans platform",
    setup_your_own_domain: "Setup your own domain",
    setup_your_own_domain_desc: "Create your own domain and start selling your fans",
    your_content_is_safe: "Your content is safe",
    your_content_is_safe_desc: "Your content is safe and secure",
    setup_your_subscription: "Setup your montly subscription",
    setup_your_subscription_desc: "Setup your monthly subscription and start selling your fans",
    apply_now: "Apply now",
    step: "Step",
    complete_profile: "Complete profile",
    continue: "Continue",
    come_back: "Come back",
    go_back: "Go back",
    user_name: "User name",
    full_name: "Full name",
    about_you: "About you",
    location: "Location",
    date_of_birth: "Date of birth",
    accept_creator_terms: "Accept creator terms",
    accept_creator_terms_desc: "By clicking on the button below, you accept the terms and conditions of the creator platform",
    plaease_complete: "Please complete",
    verify_identity: "Verify identity",
    verify_document_front: "A high quality photo of your official government ID (Passport or Drivers License) all by itself front",
    verify_document_back: "A high quality photo of your official government ID (Passport or Drivers License) all by itself back",
    verify_face_desc: "Photo of that same ID held up next to your face (smart phone selfies are not valid)",
    verify_upload_desc: "Please upload 3 photos",
    verify_privacy_files: "Identification uploads are never shared with 3rd parties.",
    document_id_front: "Document ID Front",
    document_id_back: "Document ID Back",
    document_id_face: "Document ID AND Face",
    creator_become_docs_confirm: "By clicking on the button below, you confirm that you have read and accepted the Creator Terms and Conditions",
    payouts: "Payouts",
    story: "Story",
    adult_content: "Adult content",
    age_advice: "By using the website you acknowledge that you are over 18 years old.",
    and: "and",
    create_story: "Create story",
    edit_story: "Edit story",
    terms_agree: "Accept the terms of use of the platform",
    story_send_label: "Send your story",
    see: "See",
    replies: "Replies",
    more: "More",
    liked_to: "Liked by",
    select_file: "Select file",
    doc_cc: "CC",
    passport: "Passport",
    profile: "Profile",
    my_profile: "My profile",
    home: "Home",
    notifications: "Notifications",
    ourGallery: {
      title: "Look at the",
      subTitle: "GALLERY",
    },
    roles: {
      super: "Super Administrator",
      admin: "Administrator",
      coord: "Coordinator",
      user: "User",
    },
    header: ["It is a lifestyle", "Winning Factory", "The leading company"],
    title: "Models 1A",
    legal: "Legal",
  },
  updates: {
    title: "Update Available",
    message: "The content of this application has been updated, please refresh to apply the changes",
    button: "Update",
  },
  faq: {
    title: "Faqs",
    faqs_users: {
      title: "Users Faq",
      faqs: [
        {
          question: "How do I follow a Creator?",
          answer: "<p>On the creator's page or in search click the Follow button.</p><p>or search for {CreatorUserName}</p>",
        },
        {
          question: "How do I cancel my Subscription?",
          answer: "<p>Go to your accounts (Following) page</p><p>(www.myfans1a.com/favorites).</p>",
        },
      ],
    },
    faqs_creators: {
      title: "Creators Faq",
      faqs: [
        { question: "How do I post?", answer: "Go to your profile page: https://www.myfans1a.com/{YourUsername}." },
        { question: "How much do I make?", answer: "You are paid 80% of what your fans pay (subscriptions and tips)." },
        { question: "How often do I get paid?", answer: "You are paid weekly." },
      ],
    },
  },
  faqs: [
    { question: "¿What is MyFans1A?", answer: "It´s a social network that connects creators and fans from all over the world." },
    {
      question: "¿How does MyFans1A work?",
      answer:
        "By becoming a creator you can start monetizing your content through a monthly subscription, personalized greetings, tips, messaging and our main tool MyFans1A Plus where you can share your most valuable content.",
    },
    {
      question: "¿What makes MyFans1A different from OnlyFans?",
      answer:
        "MyFans1A is mainly characterized by having a sneak preview of exclusive creators content on its home page, generating more exposure, fan traffic and better sales probabilities. Our experience and technology allow us to offer you paid and organic traffic, using a fusion method that currently none of the platforms offers.",
    },
    {
      question: "¿How can I generate income on MyFans1A?",
      answer:
        "<ul list-padding-bottom><li>Be over 18 years</li><li>Become a creator</li><li>Upload content and set the price</li><li>Have more interaction with your fans</li><li>Keep your profile updated and active by creating content regularly</li></ul>",
    },
    {
      question: "¿What are the requirements to become a creator on MyFans1A?",
      answer:
        "<ul list-padding-bottom><li>Being 18 or older.</li><li>Complete your profile (Create a username, full name, brief description, country, date of birth).</li><li>Accept terms and conditions to become a creator.</li><li>Verify your identity (Original ID)</li><li>The approval of your account will arrive in your MyFans1A message box.</li></ul>",
    },
    {
      question: "¿What percentage can I earn and how often can I get paid?",
      answer: "A creator can earn up to 80% of the revenue generated and payments are made every 15 days.",
    },
    {
      question: "Where can I generate more revenue: in the monthly subscription or in the sale of Plus1A content?",
      answer:
        "The subscription is limited to a fixed monthly amount, while in Plus1A content you can set a higher value per post. However, having ample premium content will motivate your fans to purchase higher value Plus1A content.",
    },
    {
      question: "How Fan can I subscribe and access Premium content with the purchase of credits?",
      answer: "No, the subscription is a separate transaction.",
    },
    {
      question: "Can a Fan have access to Plus1A content if they purchase a subscription?",
      answer: "No, in order to access Plus1A content, a purchase of credits must be made.",
    },
    {
      question: "Are my credits due at some point?",
      answer: "No, credits never expire.",
    },
    {
      question: "What information will be reflected in my bank statement?",
      answer: "Segpay.com *onemedia will be reflected on your bank statement at the time of purchase.",
    },
    {
      question: "What payment methods do you offer for creators?",
      answer: `<ul>
        <li>
          1. We offer a convenient method of payment through our trading partner
          partner https://segcards.com/ where you can open an account, receive a visa card in your name
          your name and your winnings will be securely debited from your segpay account.
          your segpay account. You will then be able to make payments or withdrawals at any
          where the visa card is accepted.
        </li>
        <li>2. Transfer to your bank account in local currency.</li>
        <li>3. Transfer via Paxum.</li>
      </ul>`,
    },
    {
      question: "Do you have a referral program?",
      answer: "Yes, for each referral you get 5% of their total sales.",
    },
    {
      question: "How can I cancel my subscription?",
      answer: "By sending an email to support@myfans1a.com or through the unsubscribe option in the platform.",
    },
    {
      question: "How can I delete my account?",
      answer:
        "By sending an email to support@myfans1a.com with the request. However, active subscribers will have access to the content until the subscription expires.",
    },
    { question: "¿How can I contact you?", answer: "<a link='/support'>Click on this link to see our contact information and opening hours.</a>" },
  ],
  report: {
    why: "Why do you want to report this post?",
    fake_info: "Fake information",
    violent_content: "Violent content",
    fraud: "Fraud",
    spam: "Spam",
    hate_speech: "Hate speech or symbols",
    related_a_minor: "Related to a minor",
    other: "Other",
    has_response: "Has response",
    not_has_response: "Not has response",
    add_answer: "Add a answer...",
    end: "End",
  },
  landing: {
    "creators-top-title": "MEET OUR TOP CREATORS",
    "model-label": "Model",
    "model-exclusive-label": "Exclusive Model",
  },
  support: {
    title: "Support",
    phone_number: "17868255987",
    email: "support@myfans1a.com",
    creator_support: {
      title: "Creator support",
      info:
        '<div><p><strong>Contact us:</strong></p><ul><li>1-(000)-000-000</li><li><a href="mailto:support@myfans1a.com">support@myfans1a.com</a></li></ul></div>',
    },
    user_support: {
      title: "User support",
      info:
        '<div><p><strong>Contact us:</strong></p><ul><li>1-(000)-000-000</li><li><a href="mailto:support@myfans1a.com">support@myfans1a.com</a></li></ul></div>',
    },
    technical_support: {
      title: "Technical support",
      info:
        '<div><p><strong>Contact us:</strong></p><ul><li>1-(000)-000-000</li><li><a href="mailto:support@myfans1a.com">support@myfans1a.com</a></li></ul></div>',
    },
  },
  statistics: {
    expired: "Expired",
    cancelled: "Cancelled",
    "unlock-content": "Unlock content",
    "total-earnings": "Total earnings",
    qty: "Quantity",
    credits: "Credits",
    subscriptions: "Subscriptions",
    users: "Users",
    "by-country": "By country",
    "by-age": "By age",
    "by-con": "Hour connection",
    "total-fans": "Total Fans",
    "total-creators": "Total Creators",
    "total-sales-credits": "Total sales Credits",
    "total-sales-dollars": "Total sales Dollars",
  },
  periods: {
    custom: "Custom",
    "last-month": "Last month",
    "last-3-months": "Last 3 months",
    "last-6-months": "Last 6 months",
    "last-12-months": "Last 12 months",
  },
};
