export const methods = {
  isLoading: function(state) {
    if (state && this.silent) return;
    let [status, lockapp] = [state, false];
    const isObj = this.$global.isObject(state);
    if (isObj) {
      status = state.state;
      lockapp = state.lockapp;
    }
    this.loading = status;
    this.$store.commit("loading", status);
    this.$store.commit("lockapp", lockapp);
    this.silent = false;
  },
  lockapp: function(state) {
    this.$store.commit("lockapp", state);
  },
  goBack: function() {
    const MyView = this.$refs.MyView;
    if (MyView) {
      MyView.close(() => {
        this.$router.go(-1);
      });
    } else {
      this.goRouteBack();
      //this.$router.go(-1);
    }
  },
  UserMenu: function(status) {
    this.$store.commit("UserMenu", status);
  },
  setQuery: function(myQuery) {
    let currentQuery = this.$route.query;
    let newQuery = { query: { ...currentQuery, ...myQuery } };
    this.$router.push(newQuery).catch(() => {});
    this.$emit("query");
  },
  replaceQuery: function(myQuery) {
    let newQuery = { query: { ...myQuery } };
    this.$router.push(newQuery).catch(() => {});
    this.$emit("query");
  },
  mkLink: function(path, className, conf = {}) {
    let { exact } = conf;
    let isPath = this.$global.linkInclude(this.$route, path);
    if (exact) {
      let { fullPath } = this.$route;
      let regx = new RegExp(`${path}$`);
      isPath = regx.test(fullPath);
    }
    return isPath ? className || "router-link-exact-active" : "";
  },
  isPath: function(path) {
    return path === this.$route.path;
  },
  mediaType: function(type) {
    if (!type) return;
    let media;

    const isObj = this.$global.isObject(type);

    if (isObj) {
      if (type?.name?.includes("img")) return "image";
      if (type?.type?.includes("img")) return "image";
      if (type?.type?.includes("image")) return "image";
      if (type?.type?.includes("video")) return "video";
      media = type;
      type = media.type;
    } else {
      media = { type };
      if (type?.includes("image")) return "image";
      if (type?.includes("video")) return "video";
    }

    const isImage = this.$global.isImage(type);
    if (isImage) return "image";

    const isVideo = this.$global.isVideo(type);
    if (isVideo) return "video";

    return this.detectMediaType(media);
  },
  detectMediaType: function(media) {
    const isImage = this.$global.isImage(media.src);
    if (isImage) return "image";

    const isVideo = this.$global.isVideo(media.src);
    if (isVideo) return "video";

    return "unknown";
  },
  cloudfront: function(media) {
    return this.$global.cloudfront(media, this.$locale["cloudfront_domain"]);
  },
  number_format: function(number, decimals) {
    return this.$global.number_format(number, decimals);
  },
  getPercent: function(mount, total) {
    return Math.round((mount / total) * 100);
  },
  getDiscountPercent: function(regularObtain, plusObtain) {
    const percent = ((regularObtain - plusObtain) / plusObtain) * 100;
    return percent.toFixed(0);
  },
  el: function(selector, callback) {
    if (!selector) return;
    const el = document.querySelector(selector);
    if (el) {
      if (callback) return callback(el);
      else return el;
    }
  },
  getTimeLapse: function(data = {}) {
    let { date } = data;

    let dateFrom = date;
    let dateTo = new Date(Date.now());

    const { days, hours, minutes, seconds } = this.$global.dateDiff(dateFrom, dateTo);
    const months = (days / 30).toFixed();

    if (!days && !hours && !minutes) {
      return this.$locale["object"]["user-post"]["post-few-seconds-lapse"]?.replaceAll("$seconds", seconds);
    } else if (!days && !hours) {
      return this.$locale["object"]["user-post"]["post-minutes-lapse"]?.replaceAll("$minutes", minutes);
    } else if (!days) {
      return this.$locale["object"]["user-post"]["post-hours-lapse"]?.replaceAll("$hours", hours);
    } else if (days < 30) {
      return this.$locale["object"]["user-post"]["post-days-lapse"]?.replaceAll("$days", days);
    } else {
      return this.$locale["object"]["user-post"]["post-months-lapse"]?.replaceAll("$months", months);
    }
  },
  lowercase: function(str) {
    return str?.toLowerCase();
  },
  uppercase: function(str) {
    return str?.toUpperCase();
  },
  lower: function(str) {
    return this.lowercase(str);
  },
  upper: function(str) {
    return this.uppercase(str);
  },
  capitalize: function(str) {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  },
  showSuggest: function({ index, each }) {
    const { adminStatus } = this.$route.query;
    const isShow = index >= each ? index % each === 0 : false;

    if (this.$isCreator) {
      return false;
    }

    if (!adminStatus && isShow) {
      return true;
    }
  },
  date_locale: function(date) {
    return this.$global.dateLocaleFormat(date);
  },
  sleep: function(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },
  normalize: function(str) {
    return this.$global.normalizeString(str);
  },
  randomNumber: function(min = 0, max = 100, opt = {}) {
    if (opt.int) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    } else {
      return Math.random() * (max - min) + min;
    }
  },
  words: function(key) {
    return this.$store.state.locale.words[key];
  },
  encode_url: function(url) {
    return encodeURI(url).replace(/[!'()*]/g, function(c) {
      return "%" + c.charCodeAt(0).toString(16);
    });
  },
  url_encode: function(url) {
    return this.encode_url(url);
  },
  loadHTML: function(url) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, false);
    xhr.send(null);
    return xhr.responseText;
  },
  cleanHTML: function(html) {
    return this.$global.cleanHTML(html);
  },
  goHome: function() {
    this.$router.push({ path: "/" });
  },
  phone_format: function(phone) {
    return this.$global.phone_format(phone);
  },
  hasTextNodes: function(text) {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = text;
    const nodes = tmp.childNodes;
    return nodes.length > 1;
  },
  prependChild: function(parent, newChild) {
    parent.insertBefore(newChild, parent.firstChild);
  },
  scrollIntoView: function(el) {
    if (!Go.is(el, "HTMLElement")) {
      el = document.querySelector(el);
    }
    if (el) {
      el.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  },
  getFileCover: function({ file, thumbnail }) {
    if (this.mediaType(file.type) === "image") {
      return file.location;
    }

    if (this.mediaType(file.type) === "video") {
      return (thumbnail && thumbnail.location) || this.$locale["video_icon_1a"];
    }
  },
  goFilter: function(filter, path) {
    const currentPath = path ? path : this.$creatorFeed ? this.$creatorFeed.user : "/";
    this.$router.push({ path: currentPath, query: filter }).catch(() => {});
    this.$global.scrollToEl({ el: ".main-feed", topgap: 60 });
    this.$emit("filter");
  },
  gotToUp: function() {
    this.$global.scrollToEl({ el: "body", topgap: 60 });
  },
  isAdminCreator: function() {
    // if current creator feed is admin
    const creator = this.$creator && this.$creator.toLowerCase();
    const appName = this.$locale["app_name"] && this.$locale["app_name"].toLowerCase();
    const appKeyName = this.$locale["app_key_name"] && this.$locale["app_key_name"].toLowerCase();

    if (creator === appKeyName || creator === appName) {
      return true;
    }

    return false;
  },
  navigateToHome: function() {
    if (this.$isMobile) {
      return this.setQuery({ explore: true });
    }

    this.$router.push({ path: "/" }).catch(() => {});
  },
  onPaste: function(e) {
    e.preventDefault();
    const text = (e.clipboardData || window.clipboardData).getData("text/plain");
    document.execCommand("insertHTML", false, text);
  },
  getAdminUser: function() {
    return this.$locale["adminUser"];
  },
};
