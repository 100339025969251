import ElementCycles from "./go.component.cycles";

const Component = function() {};
const component = new Component();

export default { component: (id, src) => component.load(id, src) };

Component.prototype.load = function(id, src) {
  if (Go.is(src, "Object")) {
    this.register(id, src);
    return;
  }
};

Component.prototype.register = async function(id, component) {
  const { default: _component } = await component();

  class MyComponent extends ElementCycles {
    component() {
      return {
        name: id,
      };
    }
  }

  Object.assign(MyComponent.prototype, _component);

  try {
    window.customElements.define(id, MyComponent);
  } catch (error) {
    console.log(error);
  }
};
