import api from "@/api";

export default {
  state: () => ({
    home: {},
    sales: {},
    complaints: [],
    statistics: {},
    bags: {
      items: [],
      statistics: {},
    },
    fans: {
      items: [],
      statistics: {},
    },
    AdminMenuMobile: false,
  }),
  mutations: {
    setAdminComplaints: async function(state, { complaints, statistics }) {
      if (statistics.page === 1) {
        state.complaints = complaints;
        state.statistics = statistics;
      } else if (statistics.page > 1) {
        state.complaints.push(...complaints);
        state.statistics.page = statistics.page;
      }
      state.statistics.hasMore = state.statistics.total > statistics.page * statistics.limit;
    },
  },
};
