const env = process.env.VUE_APP_MODE;
const host = process.env.VUE_APP_HOST;
const dictionary = {
  cities: {
    Medellín: "medellin",
    Bogotá: "bogota",
    Cúcuta: "cucuta",
    Pereira: "pereira",
  },
  roles: {
    coordinator: "coord",
    superadmin: "super",
    model: "model",
    monitor: "monitor",
  },
  apps: {
    autologin: "AutoLogin",
    administration: "Administración",
    reservation: "Reservas",
    statistics: "Estadísticas",
    website: "Sitio web",
    pays: "Recibos de pago",
  },
  apps_icons: [
    {
      name: "Autologin",
      key: "autologin",
      icon: "https://new-web-site-bucket.s3.amazonaws.com/apps/icons/Autologin.svg",
      link: env === "prod" ? "https://apps.models1a.com/main" : env === "dev" ? "https://autologin.omgworldwidegroup.com/main" : `${host}:8080/main`,
    },
    {
      name: "administración",
      key: "administration",
      icon: "https://new-web-site-bucket.s3.amazonaws.com/apps/icons/Cumplea%C3%B1os+y+aniversario.svg",
      link:
        env === "prod" ? "https://administration.models1a.com/" : env === "dev" ? "https://administration.omgworldwidegroup.com/" : `${host}:8082`,
    },
    {
      name: "Reservas",
      key: "reservation",
      icon: "https://new-web-site-bucket.s3.amazonaws.com/apps/icons/Reservas.svg",
      link:
        env === "prod" ? "https://administration.models1a.com/" : env === "dev" ? "https://administration.omgworldwidegroup.com/" : `${host}:8082`,
    },
    {
      name: "Estadísticas",
      key: "statistics",
      icon: "https://new-web-site-bucket.s3.amazonaws.com/apps/icons/Estadisticas.svg",
      link:
        env === "prod" ? "https://administration.models1a.com/" : env === "dev" ? "https://administration.omgworldwidegroup.com/" : `${host}:8082`,
    },
    {
      name: "Recibos de pago",
      key: "pays",
      icon: "https://new-web-site-bucket.s3.amazonaws.com/apps/icons/Generar+recibo+de+pago.svg",
      link:
        env === "prod" ? "https://administration.models1a.com/" : env === "dev" ? "https://administration.omgworldwidegroup.com/" : `${host}:8082`,
    },
    {
      name: "Registro",
      key: "website",
      icon: "https://new-web-site-bucket.s3.amazonaws.com/apps/icons/Registro.svg",
      link: env === "prod" ? "http://www.models1a.com/admin" : env === "dev" ? "https://new-site.omgworldwidegroup.com/admin" : `${host}:8081/admin`,
    },
  ],
};

module.exports = { dictionary };
