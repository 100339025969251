import COMPONENT_EVENTS from "../components/go.component.events.js";

const GO_EVENTS = { _events: [], Events: COMPONENT_EVENTS };

export default GO_EVENTS;

GO_EVENTS.emit = function(event, data) {
  if (Go.is(event, "string") && event.includes(" ")) {
    event = event.split(" ");
  }

  if (Go.is(event, "Array")) {
    event.forEach((e) => {
      GO_EVENTS.emit(e, data);
    });
    return;
  }

  GO_EVENTS._events.forEach((e) => {
    if (e.event === event || e.event.startsWith(event + ":")) {
      e.callback(data);
    }
  });
};

GO_EVENTS.on = function(event, callback) {
  if (Array.isArray(event)) {
    event.forEach((e) => {
      GO_EVENTS.on(e, callback);
    });
    return;
  }

  GO_EVENTS._events.push({ event, callback });
};

GO_EVENTS.off = function(event) {
  if (Go.is(event, "string") && event.includes(" ")) {
    event = event.split(" ");
  }

  if (Go.is(event, "Array")) {
    event.forEach((e) => {
      GO_EVENTS.off(e);
    });
    return;
  }

  Go.removeObjectFromArray(GO_EVENTS._events, "event", event);
};

GO_EVENTS.once = function(event, callback) {
  if (Go.is(event, "string") && event.includes(" ")) {
    event = event.split(" ");
  }

  if (Go.is(event, "Array")) {
    event.forEach((e) => {
      GO_EVENTS.once(e, callback);
    });
    return;
  }

  GO_EVENTS.off(event, callback);
  GO_EVENTS.on(event, callback);
};
