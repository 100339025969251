export default {
  methods: {
    AdminStatisticsUserMenu: function(user) {
      this.view({
        title: this.$locale["user"],
        user: user,
        component: () => import("./AdminUserOptions.vue"),
        class: "menu bottom-mbl",
        closeOutside: true,
        animate: {
          from: {
            opacity: 0,
            transform: "translate3d(0, 50%, 0)",
          },
          to: {
            opacity: 1,
            transform: "translate3d(0, 0, 0)",
          },
        },
      });
    },
  },
};
