/*
@Created By
@Luis Carlos Hurtado
*/
export default {
  install(Vue, options) {
    Vue.mixin({
      methods: {
        appendToObj: function(key, value) {
          const currentStates = { ...this.$state };
          if (typeof value === "object") {
            let objectCurrent = { ...currentStates[key], ...value };
            currentStates[key] = objectCurrent;
          } else {
            currentStates[key] = value;
          }
          this.$store.state.states = currentStates;
        },
        state: function(state) {
          const keys = Object.keys(state);
          for (const key of keys) {
            this.appendToObj(key, state[key]);
          }
        },
      },
      computed: {
        $state: function() {
          return this.$store.state.states;
        },
      },
    });
  },
};
