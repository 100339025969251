class Component extends HTMLElement {
  constructor() {
    super();
    this.attrs = Go.parseAllAttributes(this);
    this.props = this.attrs;
  }

  disconnectedCallback() {
    this.cleanResources();
    this.remove();
  }

  connectedCallback() {
    this.beforeRender();
    this.render();
    this.afterRender();
  }

  beforeRender() {}
  render() {}
  afterRender() {}
  cleanResources() {}
}

export default Component;
