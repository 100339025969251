const GO_DRAG = function(el) {
  this.el = el;
  this.started = false;
  this.startX = 0;
  this.startY = 0;
  this.distanceX = 0;
  this.distanceY = 0;
  this.direction = "none";
  this.lastX = 0;
  this.lastY = 0;
  Go.extends(this, Go.Events);
  this.listen();
};

export default { drag: (el) => new GO_DRAG(el) };

GO_DRAG.prototype.listen = function() {
  if (!Go.is(this.el, "HTMLElement")) {
    this.el = document.querySelector(this.el);
  }

  this.el.onmousedown = this.moveStart.bind(this);
  this.el.onmousemove = this.moving.bind(this);
  this.el.onmouseleave = this.moveEnd.bind(this);
  this.el.onmouseup = this.moveEnd.bind(this);
  this.el.onblur = this.moveEnd.bind(this);
  this.el.ondragstart = this.moveStart.bind(this);
  this.el.ondrag = this.moving.bind(this);
  this.el.ondragend = this.moveEnd.bind(this);
  this.el.ontouchstart = this.moveStart.bind(this);
  this.el.ontouchmove = this.moving.bind(this);
  this.el.ontouchend = this.moveEnd.bind(this);
  this.el.ontouchcancel = this.moveEnd.bind(this);
};

GO_DRAG.prototype.moveStart = function(e) {
  this.started = true;
  this.startX = e.pageX || e.touches[0].pageX;
  this.startY = e.pageY || e.touches[0].pageY;
  this.distanceX = 0;
  this.distanceY = 0;
  this.lastX = 0;
  this.lastY = 0;
  this.dirs = {};
  this.emit("moveStart", { event: e, x: this.distanceX, y: this.distanceY });
};

GO_DRAG.prototype.moving = function(e) {
  if (!this.started) return;
  this.distanceX = (e.pageX || e.touches[0].pageX) - this.startX;
  this.distanceY = (e.pageY || e.touches[0].pageY) - this.startY;

  this.dirs = {
    up: this.distanceY < this.lastY,
    down: this.distanceY > this.lastY,
    left: this.distanceX < this.lastX,
    right: this.distanceX > this.lastX,
  };

  this.emit("moving", { event: e, axis: this.dirs, x: this.distanceX, y: this.distanceY });
};

GO_DRAG.prototype.moveEnd = function(e) {
  if (!this.started) return;
  this.started = false;
  this.startX = 0;
  this.startY = 0;
  this.lastX = this.distanceX;
  this.lastY = this.distanceY;
  this.emit("moveEnd", { event: e, axis: this.dirs, x: this.distanceX, y: this.distanceY });
};
