import api from "@/api";
import global from "../assets/js/global";
import socket from "../api/socket";
import Connection from "../assets/js/connection.worker";

export default {
  state: () => ({
    contacts: {
      users: [],
      page: 1,
      hasmore: false,
      q: null,
    },
    messages: {
      user: {},
      messages: [],
      page: 1,
      hasmore: false,
      newCount: 0,
      current: {},
      unread: 0,
    },
  }),
  actions: {
    getContacts: function({ commit, state }, { page, limit = 24, query = "", callback, error }) {
      page = page >= 1 ? page : 1;
      query += `&page=${page}&limit=${limit}`;
      const request = api.get(`${process.env.VUE_APP_API_HOST}message/contacts?${query}`);

      request.then((response) => {
        commit("setContacts", { ...response.data, page, limit, query });
        if (Go.is(callback, "Function")) {
          return callback(response);
        }
      });

      request.catch((response) => {
        if (Go.is(callback, "Function")) {
          return callback(response);
        }
      });
    },
    getMessages: function({ commit, state }, { page, limit = 24, query, callback, error }) {
      page = page >= 1 ? page : 1;
      query += `&page=${page}&limit=${limit}`;
      const request = api.get(`${process.env.VUE_APP_API_HOST}message/?${query}`);

      request.then((response) => {
        commit("setMessages", { ...response.data, page, limit, query });
        if (Go.is(callback, "Function")) {
          return callback(response);
        }
      });

      request.catch((response) => {
        if (Go.is(callback, "Function")) {
          return callback(response);
        }
      });
    },
    sendMessage: function({ commit, dispatch }, { formData, callback, reset, filesPreview }) {
      if (!formData) {
        return callback ? callback({ data: { error: true, message: "Missing Data" } }) : { data: { error: true, message: "Missing Data" } };
      }

      formData.append("messageUUID", Go.uuid());

      const sendData = api.post(process.env.VUE_APP_API_HOST + "message/send", formData);

      sendData.then((response) => {
        commit("addMessage", { success: response.data.success, message: response.data.message });
        if (Go.is(callback, "Function")) {
          return callback(response);
        }
      });

      sendData.catch((response) => {
        if (Go.isError(response, 504)) {
          return;
        }

        if (Go.is(callback, "Function")) {
          return callback(response);
        }
      });

      dispatch("createPreviewMessage", { formData, filesPreview });

      if (Go.is(reset, "Function")) {
        reset();
      }
    },
    createPreviewMessage: function({ commit, state }, { formData, filesPreview }) {
      const message = {
        _id: formData.get("messageUUID"),
        from: Go.Vue.$store.state.user.user,
        to: state.messages.user,
        message: formData.get("message"),
        date: Go.dateFormat(new Date(), { lang: "en", format: "yyyy-mm-dd" }) + "T" + Go.timeFormat(new Date(), { lang: "en", format: "hh:ii:ss" }),
        isMe: true,
        files: filesPreview,
        filesDummy: [],
        filesPosters: [],
        messageUUID: formData.get("messageUUID"),
        formData,
        processing: true,
      };

      commit("addMessage", { success: true, message });
    },
    listenMessages: function({ commit, dispatch, state }) {
      socket.io.on("message", ({ message }) => {
        let isChatting = String(message?.from?._id) === String(state?.messages?.user?._id);
        isChatting = isChatting || String(message?.to?._id) === String(state?.messages?.user?._id);

        if (isChatting) {
          commit("addMessage", { success: true, message });
        } else {
          Connection.report();
        }
      });

      socket.io.on("unreadMessages", ({ total }) => {
        state.messages.unread = total;
      });
    },
    resetMessages: function({ commit, state }) {
      state.contacts.users = [];
      state.contacts.page = 1;
      state.contacts.hasmore = false;
      state.contacts.q = null;

      state.messages.user = {};
      state.messages.messages = [];
      state.messages.page = 1;
      state.messages.hasmore = false;
      state.messages.newCount = 0;
      state.messages.current = {};
    },
  },
  mutations: {
    setContacts: function(state, { users, page, limit, query }) {
      const { q } = global.queryToObject(query);
      state.contacts.q = q;
      state.contacts.page = page;
      state.contacts.hasmore = users?.length >= limit;
      if (page > 1) {
        state.contacts.users.push(...users);
      } else {
        state.contacts.users = users;
      }
      // Remove duplicates users names
      state.contacts.users = state.contacts.users.filter((user, index, self) => {
        return index === self.findIndex((t) => t.name === user.name);
      });
    },
    setMessages: function(state, { user, messages, page, limit }) {
      state.messages.page = page;
      state.messages.hasmore = messages?.length >= limit;
      if (page > 1) {
        state.messages.messages.push(...messages);
      } else {
        state.messages.user = user;
        state.messages.messages = messages;
      }
    },
    addMessage: function(state, { success, message }) {
      const isUpdate = state.messages.messages.findIndex((msg) => msg._id === message._id || msg.messageUUID === message.messageUUID) >= 0;

      if (isUpdate) {
        return Go.Vue.$store.commit("updateMessage", { message });
      }

      if (success && message) {
        state.messages.messages.unshift(message);
        state.messages.newCount++;
      }
    },
    updateMessage: function(state, { message }) {
      state.messages.messages = state.messages.messages.map((msg) => {
        if (msg._id === message._id || msg.messageUUID === message.messageUUID) {
          msg = message;
        }
        return msg;
      });
    },
  },
};
