import api from "@/api";
import socket from "@/api/socket";
import store from "@/store";

export default {
  state: () => ({
    comments: [],
    commentBox: null,
    replyComment: null,
    moreComments: false,
    commentsLimit: 24,
    totalComments: 0,
    postId: null,
  }),
  actions: {
    saveComment: async function({ commit, state }, { id, formData, callback }) {
      if (!formData) {
        return callback ? callback({ data: { error: true, message: "Missing Data" } }) : { data: { error: true, message: "Missing Data" } };
      }

      const sedData = api.post(process.env.VUE_APP_API_HOST + "comments/create", formData);
      sedData.then((response) => {
        if (callback) return callback(response);
        return response;
      });

      sedData.catch((response) => {
        if (callback) return callback(response);
        return response;
      });
    },
    getComments: async function({ commit, state }, { query, callback, page }) {
      if (!query) {
        return callback ? callback({ data: { error: true, message: "Missing post ID" } }) : { data: { error: true, message: "Missing post ID" } };
      }

      const sedData = api.get(`${process.env.VUE_APP_API_HOST}comments?${query}`);

      sedData.then((response) => {
        commit("setComments", { ...response.data, page });
        if (callback) return callback(response);
        return response;
      });

      sedData.catch((response) => {
        if (callback) return callback(response);
        return response;
      });
    },
    getCommentReplies: async function({ commit, state }, { commentId }) {
      //
    },
    listenComments: ({ commit, state, dispatch }) => {
      socket.io.on("comment", ({ comment }) => {
        const isMeComment = String(comment.user._id) === String(store.state.user.user.id);
        if (!isMeComment && String(state.postId) === String(comment.post._id) && comment.deep === 0) {
          state.comments.unshift(comment);
        }
        commit("newPostComment", { comment });
      });
    },
  },
  mutations: {
    setComments: function(state, { comments, page, total, postId }) {
      if (page === 1) {
        state.comments = comments;
        state.totalComments = total;
      } else {
        state.comments.push(comments);
      }
      state.moreComments = state.comments.length < total;
      state.postId = postId;
    },
    setReplyComment: function(state, comment) {
      state.replyComment = comment;
    },
    resetComments: function(state) {
      state.comments = [];
      state.commentBox = null;
    },
    addNewComment: function(state, comment) {
      if (comment.reply) {
        state.replyComment.replies.unshift(comment);
      } else {
        state.comments.unshift(comment);
      }
    },
  },
};
