import socketIo from "socket.io-client";

export default {
  io: null,
  start: function({ demo }) {
    this.io = socketIo(process.env.VUE_APP_SOCKET, {
      auth: { [process.env.VUE_APP_TOKEN_NAME]: localStorage.getItem(process.env.VUE_APP_TOKEN_NAME) },
      query: `demo=${demo}&x=y`,
      path: "/api/v1/socket",
      transports: ["websocket"],
    });
  },
  stop: function() {
    if (!this.io) return;
    this.io.disconnect();
    this.io = null;
  },
};
