const GoObject = {};

export default GoObject;

function find(list, f) {
  return list.filter(f)[0];
}

GoObject.deepCopy = (obj, cache) => {
  if (cache === void 0) cache = [];

  // just return if obj is immutable value
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  // if obj is hit, it is in circular structure
  var hit = find(cache, function(c) {
    return c.original === obj;
  });
  if (hit) {
    return hit.copy;
  }

  var copy = Array.isArray(obj) ? [] : {};
  // put the copy into cache at first
  // because we want to refer it in recursive deepCopy
  cache.push({
    original: obj,
    copy: copy,
  });

  Object.keys(obj).forEach(function(key) {
    copy[key] = deepCopy(obj[key], cache);
  });

  return copy;
};

GoObject.objectToQueryString = (obj) => {
  return Object.keys(obj)
    .map((key) => key + "=" + obj[key])
    .join("&");
};

GoObject.objectToQuery = (obj) => {
  return GoObject.objectToQueryString(obj);
};

GoObject.objectToFormData = (obj) => {
  const formData = new FormData();

  Object.keys(obj).forEach((key) => {
    formData.append(key, obj[key]);
  });

  return formData;
};

GoObject.queryStringToObject = (str) => {
  return str
    .split("&")
    .map((item) => item.split("="))
    .reduce((acc, item) => {
      acc[item[0]] = item[1];
      return acc;
    }, {});
};

GoObject.getProperty = function(obj, path) {
  if (Go.is(obj, "String")) {
    obj = path;
    path = obj;
  }

  try {
    return path.split(".").reduce(function(o, x) {
      return o && o[x];
    }, obj);
  } catch (error) {
    console.log(error);
  }
};

GoObject.extends = function(Child, Parent) {
  // check if Parent is a contructor
  if (Go.is(Parent, "Function")) {
    return Object.assign(Child, new Parent(), Parent.prototype);
  }

  // check if Parent is an object
  if (Go.is(Parent, "Object")) {
    return Object.assign(Child, Parent);
  }

  return Child;
};

GoObject.extend = function(Child, Parent) {
  return GoObject.extends(Child, Parent);
};

GoObject.assign = function(Child, Parent) {
  return GoObject.extends(Child, Parent);
};
