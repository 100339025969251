export default {
  state: () => ({
    performance: null,
  }),
  actions: {
    loadScripts: async function({ commit, state }, { url, callback }) {
      const isAppLoader = window.isAppLoader;
      if (isAppLoader) return;

      const getLiks = () => {
        var links = document.getElementsByTagName("link");
        const mlinks = [];
        [].forEach.call(links, function(link) {
          mlinks.push({ href: link.href });
        });
        return mlinks;
      };

      var iframe = document.createElement("iframe");
      iframe.src = "/load.html";
      iframe.style = "display: none";
      iframe.id = "load";
      document.body.appendChild(iframe);
      iframe.onload = () => {
        iframe.contentWindow.postMessage({ links: getLiks() }, "*");
      };
    },
    watchPerformance: async function({ commit, state }) {
      this.dispatch("loadScripts", {});
    },
  },
};
