const Video = {};

export default Video;

Video.formatDuration = function(duration) {
  if (!duration) {
    return duration;
  }

  if (Go.is(duration, "String")) {
    duration = parseInt(duration);
  }

  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor(duration % 60);

  let time = "";

  if (hours > 0) {
    time += `${hours}h :`;
  }

  if (minutes > 0) {
    time += `${minutes}m :`;
  }

  if (seconds > 0) {
    time += `${seconds}s`;
  }

  return time;
};
