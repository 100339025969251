const Profile = (module.exports = Go.vue);

Profile.methods.ImageAdjust = async function(image) {
  this.imageEditor = await this.view({
    title: this.$locale["adjust_image"],
    component: () => import("../../components/widgets/ImageEditor.vue"),
    class: "image-picker ImageEditor default",
    image,
  });
};
