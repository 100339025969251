export default {
  state: () => ({
    version: process.env.VUE_APP_VERSION,
  }),
  actions: {
    appKey: ({ commit, state }) => {
      console.log("log", state);
    },
  },
};
