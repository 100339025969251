<template>
  <div v-bind="app" :style="`--scrw:${$screen.width}px;--scrh:${$screen.height}px;`">
    <router-view v-if="$locale" />
    <ViewsController v-if="$locale" />
    <UpdateAvailable v-if="$locale" />
    <PreloadingLine v-if="$loading || !$locale" />
  </div>
</template>

<script>
import UpdateAvailable from "../components/app/UpdateAvailable.vue";
import PreloadingLine from "../components/widgets/PreloadingLine.vue";
import ViewsController from "../views/ViewsController.vue";
export default {
  components: { UpdateAvailable, PreloadingLine, ViewsController },
  methods: {
    navigating: function() {
      const myBody = document.querySelector("body");
      myBody.classList.remove("onmenu");
    },
    languageChanged: function() {
      this.$store.state.appKey++;
      setTimeout(() => {
        this.ialert.close();
      }, 1000);
    },
  },
  mounted: async function() {
    await this.$store.dispatch("startApp");
    this.$root.$on("UpdateApp", () => {
      this.languageChanged();
    });
  },
  computed: {
    app: function() {
      return {
        id: "app",
        lang: this.$lang,
        key: this.$appKey,
        lockapp: this.$lockapp,
        feed: this.$route.query.feed,
      };
    },
  },
  watch: {
    $route(to, from) {
      this.navigating(to, from);
    },
  },
};
</script>
