export default {
  methods: {
    alert: function(data = {}) {
      if (typeof data === "string") {
        data = { message: data };
      }

      return this.showMessage(data);
    },
  },
};
