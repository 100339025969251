const GoMath = {};

export default GoMath;

GoMath.onlyNumbers = function(str) {
  if (str) {
    str = str.toString();
    return str.replace(/[^0-9.]/g, "");
  }

  return str;
};

GoMath.onlyLetters = function(str) {
  if (str) {
    str = str.toString();
    return str.replace(/[^a-zA-Z]/g, "");
  }

  return str;
};

GoMath.number_format = function(number, decimals, dec_point, thousands_sep) {
  number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
    dec = typeof dec_point === "undefined" ? "." : dec_point,
    s = "",
    toFixedFix = function(n, prec) {
      var k = Math.pow(10, prec);
      return "" + Math.round(n * k) / k;
    };
  s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }
  return s.join(dec);
};

GoMath.toPositive = function(number) {
  if (number < 0) {
    return number * -1;
  }

  return number;
};

GoMath.toNegative = function(number) {
  if (number > 0) {
    return number * -1;
  }

  return number;
};

GoMath.toMoney = function(number) {
  return GoMath.number_format(number, 2, ",", ".");
};

GoMath._math = function(number, operation, value) {
  if (operation === "+") {
    return number + value;
  } else if (operation === "-") {
    return number - value;
  } else if (operation === "*") {
    return number * value;
  } else if (operation === "/") {
    return number / value;
  } else if (operation === "%") {
    return number % value;
  } else {
    return number;
  }
};

GoMath.math = function(number, operation, value) {
  let [strNum, strVal, result] = [GoMath.onlyLetters(number), GoMath.onlyLetters(value), 0];

  number = GoMath.onlyNumbers(number);
  value = GoMath.onlyNumbers(value);
  result = GoMath._math(Number(number), operation, Number(value));

  if (!strNum && !strVal) {
    return Number(result);
  }

  if (Go.lower(strNum) === Go.lower(strVal)) {
    return result + strNum;
  }

  result = result + strNum + strVal;

  if (result) {
    return result.trim();
  }
};

GoMath.getPercent = function(value, total) {
  return (value * 100) / total;
};

GoMath.getPercentValue = function(percent, total) {
  return (percent * total) / 100;
};

GoMath.unitConvert = function(v1 = 10, v2 = 0.13) {
  var x = v1; // Price usd
  var y = v2; // Price xxx

  var z = x / y; // Price xxx/usd

  return z;
};
