export default {
  install(Vue, options) {
    Vue.mixin({
      data: function() {
        return {
          ifacebook: {
            init: function(options) {
              this.loadSDK(document, "script", "facebook-jssdk");
              this.initFacebook();
            },
            login: async function(options) {
              return new Promise(function(resolve, reject) {
                function getInfo(response) {
                  FB.api("/me", { fields: "name,email" }, function(dataUser) {
                    resolve({ ...response, ...dataUser });
                  });
                }
                FB.login(function(response) {
                  response?.authResponse ? getInfo(response) : resolve(response);
                });
              });
            },
            initFacebook: async function(options) {
              window.fbAsyncInit = function() {
                FB.init({
                  appId: "318880383351254",
                  autoLogAppEvents: true,
                  xfbml: true,
                  version: "v11.0",
                });
              };
            },
            loadSDK: async function(d, s, id) {
              let js,
                fjs = d.getElementsByTagName(s)[0];
              if (d.getElementById(id)) {
                return;
              }
              js = d.createElement(s);
              js.id = id;
              js.src = "https://connect.facebook.net/en_US/sdk.js";
              fjs.parentNode.insertBefore(js, fjs);
            },
          },
        };
      },
    });
  },
};
