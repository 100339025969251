export default {
  watch: {
    "$route.params.creator": function(oldCreator, newCreator) {
      this.$store.state.posts.stories = {};
      this.gotToUp();
      this.updateStyleProperties();
    },
    "$store.state.screen.width": function(oldWidth, newWidth) {
      this.updateStyleProperties();
    },
    $route: function(to, from) {
      this.scrollIntoView(`.main-header`);
      this.updateStyleProperties();
    },
    "$route.query.view": function(oldWidth, newWidth) {
      this.scrollIntoView(`.main-header`);
      this.updateStyleProperties();
    },
    "$route.query.admin": function(oldWidth, newWidth) {
      this.scrollIntoView(`.main-header`);
      this.updateStyleProperties();
    },
  },
  methods: {
    updateStyleProperties: function() {
      this.cover = document.querySelector(".creator-cover-content");
      this.footer = document.querySelector(".footer-nav");
      this.feed = document.querySelector(".feed-list");

      if (this.cover) {
        document.body.style.setProperty("--cover-height", this.cover.clientHeight + "px");
        document.body.style.setProperty("--cover-width", this.cover.clientWidth + "px");
      }

      if (this.feed) {
        document.body.style.setProperty("--feed-width", this.feed.clientWidth + "px");
      }

      if (this.footer) {
        document.body.style.setProperty("--footer-height", this.footer.clientHeight + "px");
      }
    },
    goRouteBack: function() {
      if (this.previusRoute) {
        this.$router.push(this.previusRoute);
        return;
      }

      if (this.$isCreator) {
        this.$router.push(`/${this.$user.user}`);
        return;
      }

      this.$router.push(`/`);
    },
    goToNav: function(filter, path) {
      if (this.modal && Go.is(this.modal.close, "Function")) {
        this.modal.close(() => {
          this.$router.push({ path: path, query: filter }).catch(() => {});
        });
        return;
      }

      this.goFilter(filter, path);
    },
  },
  mounted: function() {
    this.updateStyleProperties();
  },
  beforeRouteEnter: function(to, from, next) {
    next((vm) => {
      vm.previusRoute = from;
    });
  },
};
