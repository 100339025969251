export default {
  bind: async function(el, { value = {} }, vnode) {
    let { left, up, down, right } = value;

    const interact = Go.interact(el);

    interact.on("swipedown", (swipe) => {
      if (down && Go.isScrollTop(el)) {
        down(swipe);
      }
    });

    interact.on("swipeup", (swipe) => {
      if (up && Go.isScrollBottom(el)) {
        up(swipe);
      }
    });

    interact.on("swipeleft", (swipe) => {
      if (left && Go.isScrollLeft(el)) {
        left(swipe);
      }
    });

    interact.on("swiperight", (swipe) => {
      if (right && Go.isScrollRight(el)) {
        right(swipe);
      }
    });
  },
};
