import Vue from "vue";
import vSize from "./v-size";
import vGap from "./v-gap";
import vSkip from "./v-skip";
import vFocus from "./v-focus";
import vCalendar from "./v-calendar";
import vCountry from "./v-country";
import vSwipe from "./v-swipe";
import vSticky from "./v-sticky";

Vue.directive("size", vSize);
Vue.directive("gap", vGap);
Vue.directive("skip", vSkip);
Vue.directive("focus", vFocus);
Vue.directive("calendar", vCalendar);
Vue.directive("country", vCountry);
Vue.directive("swipe", vSwipe);
Vue.directive("sticky", vSticky);

export default {
  install: function() {
    //console.log("install");
  },
};
