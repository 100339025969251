import api from "../api";

export default {
  state: () => ({
    buffer: [],
  }),
  actions: {
    log: ({ commit, state }) => {
      console.log("log");
    },
  },
};
