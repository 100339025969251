import axios from "axios";
import map from "./api.map";
import store from "@/store";

axios.interceptors.request.use(function(config) {
  const ulang = localStorage.getItem(process.env.VUE_APP_LANG_KEY);
  const token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
  if (ulang) config.headers.common[process.env.VUE_APP_LANG_KEY] = ulang;
  if (token) config.headers.common[process.env.VUE_APP_TOKEN_NAME] = token;
  config.headers.map = map(config);
  config.headers.visible = store.state.visibility.visible;
  return config;
});

export default function(url, request, options, method) {
  url = FixUrl(url);
  return axios[method](url, request, options);
}

const FixUrl = (url) => {
  // Remove duplicate slashes before the query string
  url = url?.replace(/([^:]\/)\/+/g, "$1");
  return url;
};
