import api from "@/api";
import socket from "../api/socket";
import Notify from "@/assets/js/notifications";

export default {
  state: () => ({
    notifications: [],
    statistics: {},
    lives: [],
  }),
  actions: {
    getNotifications: async function({ commit }, { page = 1, limit = 10, query = "" }) {
      const req = await api.get(`${process.env.VUE_APP_API_HOST}/notifications?page=${page}&limit=${limit}${query}`);
      commit("setNotifications", req.data);
    },
    listenNotifications: function({ commit, state }) {
      socket.io.on("notification", (data) => {
        commit("incomingNotification", data);
        commit("setUserNotReadNotifications", data);
        if (data.notification && data.notification.message) {
          new Notify({ notification: data.notification, payload: data.payload, state });
        }
      });
    },
    reportReadNotifications: async function({ state, commit }) {
      await Go.sleep(1000);
      const query = `timestamp=${Go.timestamp()}`;
      const rep = await api.post(`${process.env.VUE_APP_API_HOST}notifications/read?${query}`);
    },
  },
  mutations: {
    setNotifications: async function(state, { notifications, statistics }) {
      if (statistics.page === 1) {
        state.notifications = notifications;
        state.statistics = statistics;
      } else if (statistics.page > 1) {
        state.notifications.push(...notifications);
        state.statistics.page = statistics.page;
      }
      state.statistics.hasMore = state.statistics.total > statistics.page * statistics.limit;
    },
    incomingNotification: function(state, data) {
      this.dispatch("updateUser", data);
    },
    closeAllLivesNotifications: function(state) {
      state.lives = [];
    },
    closeThisLiveNotification: function(state, _id) {
      if (!_id) return;
      state.lives = state.lives.filter((live) => String(live._id) !== String(_id));
    },
  },
};
