export default {
  methods: {
    mediaView: function(media) {
      this.addModal({
        media: { ...media },
        component: () => import("@/components/widgets/MediaView.vue"),
        class: "modalMediaView",
        settings: {},
        closeOnSwipe: "down",
      });
    },
    base64ToFile: function(base64, filename) {
      var arr = base64.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    getMediaPoster: function(media, post, index) {
      if (!post.mediaPoster || !post.mediaPoster.length) {
        return post.media[0].thumb;
      }

      let mediaPoster = post.mediaPoster[index];
      if (mediaPoster) {
        return mediaPoster.src;
      }

      mediaPoster = post.mediaPoster.find((_media) => {
        const isPoster = _media.name === media.name || _media.name.includes(`poster_${media.name}`);
        return isPoster;
      });

      if (mediaPoster) {
        return mediaPoster.src;
      }
    },
    MediaView: function(media) {
      this.view({
        media: media,
        component: () => import("@/components/widgets/MediaView.vue"),
        class: "MediaView clean",
        closeOutside: true,
        closeOnEsc: true,
        closeOnSwipe: "down",
      });
    },
    Media: function(media) {
      this.view({
        media: media,
        component: () => import("@/components/media/MediaView.vue"),
        class: "MediaView clean media-visor",
        closeOutside: true,
        closeOnEsc: true,
        closeOnSwipe: "down",
        header: false,
      });
    },
    getMediaDurationInfo: function(media = {}, duration = null) {
      if (media.info && media.info.format) {
        duration = media.info.format.duration;
      }

      if (!duration) {
        return "";
      }

      let hours = Math.floor(duration / 3600);
      let minutes = Math.floor((duration - hours * 3600) / 60);
      let seconds = duration - hours * 3600 - minutes * 60;

      return `<span margin-left>
                <span>${hours ? Go.number_format(hours) + "h " : ""}</span>
                <span>${minutes ? Go.number_format(minutes) + "m " : ""}</span>
                <span>${Go.number_format(seconds)}s</span>
              </span>`;
    },
  },
};
