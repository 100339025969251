import { isSession } from "@/assets/js/session";
import components from "@/assets/js/components";

export default {
  methods: {
    requestUserLogin: function({ post } = {}) {
      if (!Go.is(post, "Object")) {
        return this.UserAdminMenu();
      }

      if (!this.$user || !this.$user.user) {
        this.$store.dispatch("payload", { after: "like", post: post._id, creator: post.user.user });
        this.UserAdminMenu();
      }
    },
    goToComments: function(post) {
      if (!this.$user || !this.$user.user) {
        this.$store.dispatch("payload", { after: "comments", post: post._id, creator: post.user.user });
        this.UserAdminMenu();
        return;
      }
      this.setQuery({ comments: post._id });
    },
    goInsentive: function(modal) {
      this.post.modal = modal;
      this.$store.state.incentive = this.post;
    },
    userMessages: function(data = {}) {
      const user = data.user || {};

      if (!this.$user || !this.$user.user) {
        this.$store.dispatch("payload", { after: "messages", messages: user._id });
        this.$router.push(`/login`).catch(() => {});
        return;
      }

      this.setQuery({ messages: user._id || "wall" });
    },
    goToCredits: function() {
      if (!this.$user || !this.$user.user) {
        this.$store.dispatch("payload", { after: "credits", afterPath: this.$links["my-credits"] });
        this.$router.push(`/login`).catch(() => {});
        return;
      }
      this.$router.push(this.$links["my-credits"]).catch(() => {});
    },
    goToUserFeed: function(user) {
      if (!this.isCreator(user)) {
        this.$router.push(this.$links["my-profile"]).catch(() => {});
        return;
      }

      this.$router.push(`/${user.user}`).catch(() => {});
    },
    isCreator: function(user) {
      return user && user["roles"]?.includes("creator");
    },
    UserPreview: function(user) {
      this.Modal({
        title: user.name,
        class: "UserPreview default clean menu",
        component: () => import("../../components/user/UserPreview.vue"),
        user: user,
        animation: "bottomIn",
        closeOnSwipe: "down",
        settings: {
          header: false,
          closeOutside: true,
          //closeOnClick: true,
        },
      });
    },
    UserAdminMenu: function() {
      let [title, user, components, use] = ["", this.$user, {}, "UserAdminMenu"];

      components = {
        UserAdminMenu: () => import("@/components/user/UserAdminMenu.vue"),
        UserLogin: () => import("@/components/user/UserAdminMenuPublic.vue"),
      };

      if (!isSession()) {
        title = this.$locale["app_name"];
        use = "UserLogin";
      } else {
        title = user.name;
      }

      const alreadyOpen = document.querySelector(`.UserAdminMenu.default.clean`);

      if (alreadyOpen) {
        return;
      }

      this.Modal({
        title: title,
        class: "UserAdminMenu default clean menu",
        component: components[use],
        animation: "bottomIn",
        header: true,
        closeOutside: true,
        closeOnSwipe: "down",
        animate: {
          duration: 250,
          from: {
            opacity: 0,
            transform: "translate3d(0, 100%, 0)",
          },
          to: {
            opacity: 1,
            transform: "translate3d(0, 0, 0)",
          },
        },
      });
    },
    openReels: function() {
      this.view({
        title: this.$locale["reels"],
        class: "Reels default clean",
        component: () => import("@/components/reels/Reels.vue"),
        animation: "rightIn",
        header: false,
        closeOutside: true,
        animate: {
          duration: 250,
          from: {
            opacity: 0,
          },
          to: {
            opacity: 1,
          },
        },
      });
    },
    openSupportView: async function() {
      this.view({
        title: this.$locale["help_label"],
        component: () => import("@/components/support/SupportMenu.vue"),
        class: "TopViewMenu",
        closeOnSwipe: "up",
        closeOutside: true,
        animate: {
          duration: 250,
          from: {
            opacity: 0,
            transform: "translate3d(0, -50%, 0)",
          },
          to: {
            opacity: 1,
            transform: "translate3d(0, 0, 0)",
          },
        },
      });
    },
    menuComponent: async function(data = {}, options = {}) {
      if (typeof data === "string") {
        data = { component: data };
      }

      Object.assign(data, options);

      this.view({
        ...data,
        title: data.title || this.$locale["menu_label"],
        component: components[data.component],
        class: "cMenu CreditsCardMenu" + (data.class ? " " + data.class : ""),
        closeOutside: true,
        animate: {
          duration: 250,
          from: {
            opacity: 0,
            transform: "translate3d(0, -50%, 0)",
          },
          to: {
            opacity: 1,
            transform: "translate3d(0, 0, 0)",
          },
        },
      });
    },
    languageSelector: function() {
      this.Modal({
        title: this.$locale["select_language"],
        component: () => import("@/components/widgets/LanguageSelector.vue"),
        animation: "topIn",
        class: "ModalLanguageSelector",
        closeOutside: true,
        closeOnSwipe: "up",
        animate: {
          duration: 250,
          from: {
            opacity: 0,
            transform: "translateY(-180%)",
          },
          to: {
            opacity: 1,
            transform: "translateX(0)",
          },
        },
      });
    },
    myCreditsCard: function(data = {}) {
      this.view({
        header: false,
        class: "clean menu",
        animation: "topIn",
        closeOutside: true,
        component: () => import("@/components/credits/CreditsCard.vue"),
        ...data,
      });
    },
  },
};
