const es1 = require("./es1");

module.exports = {
  ...es1,
  app_name: "My Fans 1A",
  app_key_name: "myfans1a",
  hi: "Hola",
  all: "Todos",
  dollars: "Dólares",
  billing: "Facturación",
  usd: "USD",
  verifications: "Verificaciones",
  verify_identity: "Verifica tu identidad",
  verify_identity_title: "Verifica tu identidad y disfruta de la experiencia de usuario completa",
  verify_identity_advatage_1: "Personaliza tu perfil",
  verify_identity_advatage_1_desc: "Personaliza tu perfil para mostrar tu nombre real y tu foto",
  verify_identity_advatage_2: "Obten créditos gratis",
  get_up_to: "Obtén hasta",
  verify_identity_go: "Verificar mi identidad",
  complete_profile_info_title: "Completa la información de tu perfil",
  accept_user_terms: "Acepto los términos de uso",
  id_card_label: "Cédula de Identidad",
  passport_label: "Pasaporte",
  drive_license_label: "Licencia de conducir",
  verify_result_title: "Resultado de la verificación",
  verify_progress_text: "Su verificacion de identidad se esta procesando, por favor espere ...",
  verify_progress_time: "Este proceso puede tardar de 24 a 72 horas en completarse",
  verifications_label: "Verificaciones",
  verify_reject: "Rechazar",
  verify_accept: "Aceptar",
  idcard: "Cédula de Identidad",
  passport: "Pasaporte",
  drivelic: "Licencia de conducir",
  view_more: "Ver más",
  notifications: "Notificaciones",
  credits: "Créditos",
  free: "Gratis",
  verifime_now: "Verificarme ahora",
  admin_label: "Administrador",
  creator_label: "Creador",
  user_label: "Usuario",
  accout_already_verfified_legend: "Su cuenta ya esta verificada",
  enjoy_full_user_experience: "Disfruta de la experiencia de usuario completa",
  enjoy: "Disfruta",
  content_review: "Revisión del contenido",
  content_review_info: "Antes de que su contenido sea publicado, debe ser revisado por un administrador. Esto puede tardar de 24 hasta 72 horas.",
  avatar: "Avatar",
  cover: "Portada",
  content: "Contenido",
  status: "Estado",
  uploaded: "Subido",
  explicit_content_policy: "Política de contenido explícito",
  explicit_content_deny: "No se permite lo siguiente:",
  explicit_content_deny_1:
    "Contenido que denigre a una persona física o a un grupo de personas físicas, o que fomente el odio o la discriminación hacia ellos debido a su origen étnico o raza, religión, discapacidad, edad, nacionalidad, condición de veterano de guerra, orientación sexual, género, identidad de género o alguna otra característica que esté asociada con la marginación o la discriminación sistémicas",
  explicit_content_deny_2: "Contenido que acose, hostigue o intimide a una persona física o un grupo de personas físicas",
  explicit_content_deny_3:
    "Contenido que amenace la integridad física o mental propia o de otras personas, o bien que abogue por prácticas que la dañen",
  explicit_content_deny_4: "Contenido que pretenda explotar a otras personas",
  explicit_content_deny_5: "Contenido que promueva la violencia o la agresión",
  explicit_content_sexual_deny_1: "Contenido que pueda interpretarse como la promoción de un acto sexual a cambio de una compensación",
  explicit_content_sexual_deny_2: "Contenido que promocione la explotación sexual de menores",
  i_inderstand: "Entiendo",
  by_click_terms: "Al hacer clic en Aceptar, acepto los términos de uso",
  file_for: "Archivo para",
  media_for: "Medio para",
  accept_user_terms_desc: "Al hacer clic en Aceptar, acepto los términos de uso",
  users: "Usuarios",
  documents: "Documentos",
  profile: "Perfil",
  picture: "Foto",
  statistics_label: "Estadísticas",
  others_label: "Otros",
  posts_label: "Posts",
  stories_label: "Historias",
  address_company: "18501 Pines Blvd Suite #300 Pembroke Pines, FL 33029",
  pending: "Pendiente",
  reject: "Rechazar",
  rejected: "Rechazado",
  accept: "Aceptar",
  cancel: "Cancelar",
  language: "Idioma",
  check_content_submit_1:
    "I certify under penalty of perjury that I have verified the identity and age of all persons depicted in the content to ensure that all persons depicted are adults, and I can provide supporting documents on request.",
  check_content_submit_2:
    "I certify under penalty of perjury that I have obtained and am keeping on record written consent from all persons depicted in the content to be uploaded specific to the following: (i) consent to be depicted in the content; (ii) consent to allow for the public distribution of the content and to upload the content to the Website; and (iii) if the content will be made available for downloading by other users, consent to have the content downloaded.",
  check_content_submit_3:
    "I certify that this submission otherwise complies with the <a bold link='/terms' target='_blank'>Terms-of-Service Agreement</a> and the Creator Agreement.",
  i_certify: "Certifico",
  files: "Archivos",
  file: "Archivo",
  not_files_found: "No se encontraron archivos",
  file_name: "Nombre del archivo",
  video_icon_1a: `https://myfans1a.s3.amazonaws.com/miscellaneous/icon-video-50-01.png`,
  uploading: "Subiendo",
  invalid_file_type: "Tipo de archivo inválido",
  delete_file: "Eliminar archivo",
  delete: "Eliminar",
  delete_file_confirm: "¿Estás seguro de eliminar este archivo?",
  deleting: "Eliminando",
  terms_and_conditions: "Términos y condiciones",
  privacy_policy: "Política de privacidad",
  billing_support: "Soporte de facturación",
  contact_us: "Contáctenos",
  billing_inquiries_message:
    "Para consultas de facturación o para cancelar su membresía, visite <a target='_blank' href='http://cs.segpay.com/'>Segpay.com</a>, Nuestro agente de ventas autorizado.",
  contact_for_any_inquiries: "Para cualquier consulta, contáctenos",
  feature_is_comming: "Función en proceso de desarrollo",
  feature_is_comming_desc:
    "Esta función está en proceso de desarrollo, nuestra plataforma se actualiza contantemente para brindarte la mejor experiencia de usuario, por favor prueba mas delante.",
  development: "Desarrollo",
  continue: "Continuar",
  creators_content_is_coming: "Contenido de los creadores está en proceso de desarrollo",
  fans_service_is_coming: "Nuestro servicio para Fans estará disponible pronto",
  with: "Con",
  pay_methods: "Métodos de pago",
  check_out: "Pagar",
  total_users: "Total de usuarios",
  total_fans: "Fans totales",
  total_creators: "Creadores totales",
  from: "De",
  to: "A",
  period: "Período",
  posts: "Posts",
  delete_post: "Eliminar publicación",
  delete_post_confirm: "¿Estás seguro de eliminar esta publicación?",
  read_more: "Leer más",
  manage_your_files: "Administra tus archivos",
  my_content: "Mi contenido",
  all_your_notifications: "Todas tus notificaciones",
  no_has_notifications: "No tienes notificaciones",
  no_has_subscribers: "No tienes suscriptores",
  this_post_was_deleted: "Esta publicación fue eliminada",
  public: "Público",
  subscriber: "Suscriptor",
  purchase: "Compra",
  price: "Precio",
  video_poster_title: "Poster de video",
  capture: "Captura",
  last_seen: "Última vez visto",
  online: "En línea",
  creating_your_post: "Creando tu publicación",
  close: "Cerrar",
  close_all: "Cerrar todo",
  select_file: "Selecciona un archivo",
  my_subscribers: "Mis suscriptores",
  all_your_subscribers: "Todos tus suscriptores",
  sending_post: "Enviando publicación",
  processing: "Procesando",
  new: "Nuevo",
  approved: "Aprobado",
  rejected: "Rechazado",
  pending: "Pendiente",
  plus: "Plus",
  my_feed: "Mi feed",
  edit_post: "Editar publicación",
  edit_story: "Editar historia",
  create_post: "Crear publicación",
  create_story: "Crear historia",
  premium: "Premium",
  landing_page: "Página de inicio",
  slider: "Slider",
  top_creators: "Creadores destacados",
  stories: "Historias",
  update: "Actualizar",
  updating: "Actualizando",
  success: "Éxito",
  position: "Posición",
  user_platform_active_message: "Usuario activo en la plataforma",
  bought: "Comprado",
  saved: "Guardado",
  all_content: "Todo el contenido",
  finish: "Finalizar",
  statistics_label: "Estadísticas",
  subscription: "Suscripción",
  subscribe: "Suscribirse",
  monthly: "Mensual",
  month: "Mes",
  display_name: "Nombre para mostrar",
  technical_support: "Soporte técnico",
  office_hours: "Horario de oficina",
  monday: "Lunes",
  tuesday: "Martes",
  wednesday: "Miércoles",
  thursday: "Jueves",
  friday: "Viernes",
  saturday: "Sábado",
  sunday: "Domingo",
  closed: "Cerrado",
  explore_all_posts: "Explorar publicaciones",
  message: "Mensaje",
  send_flowers: "Enviar flores",
  flowers: "Flores",
  gift: "Regalo",
  tip: "Propina",
  my_stories: "Mis historias",
  meet_our_top_creators: "Conoce a nuestros creadores destacados",
  select_language: "Selecciona un idioma",
  home: {
    path: "/",
    title: "Inicio",
    description: "Creamos y desarrollamos top models",
  },
  about: {
    path: "/",
    title: "Sobre nosotros",
    description: "Sobre nosotros",
  },
  gallery: {
    path: "/gallery",
    title: "Nuestra galería",
    description: "Nuestra galería",
  },
  blog: {
    path: "/blog",
    title: "Nuestro blog",
    description: "Nuestro blog",
  },
  contact: {
    path: "/contact",
    title: "Contáctanos",
    description: "Contáctanos",
  },
  help: {
    path: "/help",
    title: "Preguntas frecuentes",
    description: "Preguntas frecuentes",
  },
  signup: {
    path: "/signup",
    title: "Regístrate",
    description: "Regístrate",
  },
  error: {
    path: "/error",
    title: "Se ha presentado un error",
    reload_btn: "Vover a cargar",
    "404": {
      title: "Los sentimos",
      description: "Página no encontrada",
      reason: "El enlace que siguió puede estar roto o la página puede haber sido eliminada.",
    },
    "500": {
      title: "Los sentimos",
      description: "Error en servidor",
      reason: "Se ha encontrado un error en el servidor de la aplicación.",
    },
  },
  admin: {
    path: "/admin",
    title: "Administración",
    ratings: {
      title: "Valoraciones",
      header: {
        btn_all: "Todos",
        btn_approved: "Aprobados",
        btn_disapproved: "Desaprobados",
        btn_unchecked: "Nuevos",
      },
    },
  },
  income: {
    sort: {
      old: "Más antiguas",
      recent: "Más recientes",
      max: "Mayor valor",
      min: "Menor valor",
    },
  },
  post: {
    "admin-review-confirm": "El estado de la publicación cambiará a",
  },
  words: {
    to: "a",
    new: "Nuevos",
    newa: "Nuevas",
    admitted: "Admitidos",
    approve: "Aprobar",
    approved: "Aprobados",
    disapprove: "Desaprobar",
    disapproved: "Desaprobados",
    noadmitted: "No admitidos",
    noselected: "No seleccionados",
    selected: "Seleccionados",
    waiting: "En espera",
    pending: "Pendiente",
    hired: "Contratadas",
    deserter: "Desertoras",
    night: "Noche",
    late: "Tarde",
    morning: "Mañana",
    open: "Abierto",
    phone: "Teléfono",
    whatsapp: "Whatsapp",
    email: "Email",
    all: "Todos",
    day: "Día",
    month: "Mes",
    months: "Meses",
    year: "Año",
    medellin: "Medellín",
    pereira: "Pereira",
    cucuta: "Cúcuta",
    bogota: "Bogotá",
    others: "Otros",
    collect: "Cobrar",
    tips: "Propinas",
    tip: "Propina",
    flower: "Flores",
    flowers: "Flores",
    gift: "Regalo",
    gifts: "Regalos",
    message: "Mensaje",
    messages: "Mensajes",
    live: "En vivo",
    "order by": "Ordenar por",
    reply: "Responder",
    send: "Enviar",
    likes: "Me gusta",
    like: "Me gusta",
    comments: "Comentarios",
    accept: "Aceptar",
    cancel: "Cancelar",
    confirm: "Confirmar",
    statistics: "Estadísticas",
    "active-fans": "Fans activos",
    "expired-fans": "Fans caducados",
    last: "Último",
    subscriptions: "Suscripciones",
    earnings: "Ganancias",
    total: "Total",
    users: "Usuarios",
    posts: "Publicaciones",
    general: "General",
    sales: "Ventas",
    content: "Contenido",
    credits: "Créditos",
    dollars: "Dólares",
    creators: "Creadores",
    fans: "Fans",
    subscribers: "Suscriptores",
    connections: "Conexiones",
    "connection-time": "Tiempo de conexión",
    "sales-bags": "Bolsas de créditos",
    bags: "Bolsas",
    of: "De",
    "credit-bag": "Bolsa de créditos",
    stories: "Historias",
    report_post: "Reportar publicación",
    admin: "Administración",
    complaints: "Quejas",
    post: "Publicación",
    creator: "Creador",
    become_creator: "Convertirse en creador",
    become_creator_title: "Aplicar a la plataforma de fans más exclusiva del mundo",
    setup_your_own_domain: "Configurar su propio dominio",
    setup_your_own_domain_desc: "Configurar su propio dominio para exclusivamente su plataforma de fans",
    your_content_is_safe: "Su contenido está seguro",
    your_content_is_safe_desc: "Su contenido está seguro y protegido por nuestra plataforma de fans",
    setup_your_subscription: "Configurar sus suscripciones",
    setup_your_subscription_desc: "Configurar sus suscripciones para obtener más beneficios",
    apply_now: "Aplicar ahora",
    step: "Paso",
    complete_profile: "Completar perfil",
    continue: "Continuar",
    come_back: "Volver",
    go_back: "Volver",
    user_name: "Nombre de usuario",
    full_name: "Nombre completo",
    about_you: "Sobre ti",
    location: "Ubicación",
    date_of_birth: "Fecha de nacimiento",
    accept_creator_terms: "Acepto los términos de uso de la plataforma de creadores",
    accept_creator_terms_desc: 'Al hacer clic en "Continuar", acepto los términos de uso de la plataforma de creadores',
    plaease_complete: "Por favor, complete",
    verify_identity: "Verificar identidad",
    verify_document_front:
      "Una foto de alta calidad de su documento oficial de identidad (pasaporte o permiso de conducir) por sí sola parte frontal",
    verify_document_back: "Una foto de alta calidad de su documento oficial de identidad (pasaporte o permiso de conducir) por sí sola parte reverso",
    verify_face_desc:
      "Una foto de ese mismo documento de identidad junto a tu cara (los selfies con el teléfono inteligente, tomarla con la cámara trasera)",
    creator_become_docs_confirm: "Para convertirse en creador, debes completar tu perfil y verificar tu identidad",
    verify_upload_desc: "Por favor sube 3 fotos",
    verify_privacy_files: "Los arhcivos de identificación nunca se comparten con terceros",
    document_id_front: "Documento de identidad frontal",
    document_id_back: "Documento de identidad reverso",
    document_id_face: "Documento de identidad junto a tu cara",
    payouts: "Pagos",
    story: "Historia",
    adult_content: "Contenido para adultos",
    age_advice: "Al utilizar el sitio web usted reconoce que es mayor de 18 años.",
    and: "y",
    create_story: "Crear historia",
    edit_story: "Editar historia",
    terms_agree: "Acepta los términos de uso de la plataforma",
    story_send_label: "Enviar historia",
    see: "Ver",
    replies: "Respuestas",
    more: "Más",
    liked_to: "Le gusta a",
    select_file: "Seleccionar archivo",
    doc_cc: "Documento",
    passport: "Pasaporte",
    profile: "Perfil",
    my_profile: "Mi perfil",
    home: "Inicio",
    notifications: "Notificaciones",
    ourGallery: {
      title: "Observa la",
      subTitle: "GALERÍA",
    },
    roles: {
      super: "Super Administrador",
      admin: "Administrador",
      coord: "Coordinador",
      user: "Usuario",
    },
    header: ["Es un estilo de vida", "Fábrica de ganadoras", "La empresa líder"],
    title: "Models 1A",
    legal: "Legal",
  },
  updates: {
    title: "Actualización Disponible",
    message:
      "El contenido de esta aplicación ha sido actualizado, por favor, haga clic en actualizar ó manualmente actualice el navegador, para aplicar los cambios",
    button: "Actualizar",
  },
  faq: {
    title: "Faqs",
    faqs_users: {
      title: "Users Faq",
      faqs: [
        {
          question: "How do I follow a Creator?",
          answer: "<p>On the creator's page or in search click the Follow button.</p><p>or search for {CreatorUserName}</p>",
        },
        {
          question: "How do I cancel my Subscription?",
          answer: "<p>Go to your accounts (Following) page</p><p>(www.myfans1a.com/favorites).</p>",
        },
      ],
    },
    faqs_creators: {
      title: "Creators Faq",
      faqs: [
        { question: "How do I post?", answer: "Go to your profile page: https://www.myfans1a.com/{YourUsername}." },
        { question: "How much do I make?", answer: "You are paid 80% of what your fans pay (subscriptions and tips)." },
        { question: "How often do I get paid?", answer: "You are paid weekly." },
      ],
    },
  },
  faqs: [
    { question: "¿Qué es MyFans1A?", answer: "Es una red social que conecta creadores y fans de todas partes del mundo." },
    {
      question: "¿Cómo funciona MyFans1A?",
      answer:
        "Al convertirte en creador podrás comenzar a monetizar tu contenido por medio de una subscripción mensual, saludos personalizados, propinas, mensajería y nuestra herramienta principal MyFans1A Plus en donde podrás compartir tu contenido de mayor valor.",
    },
    {
      question: "¿Qué diferencia a MyFans1A de OnlyFans?",
      answer:
        "MyFans1A se caracteriza principalmente por tener una vista preliminar de creadores exclusivos en su página de inicio, generando mayor exhibición, tráfico de fans y mejores probabilidades de venta. Nuestra experiencia y tecnología nos permite ofrecerte tráfico pago y orgánico, utilizando un método fusión que actualmente ninguna de las plataformas ofrece.",
    },
    {
      question: "¿Cómo puedo generar ingresos en MyFans1A?",
      answer:
        "<ul list-padding-bottom><li>Ser mayor de 18 años</li><li>Crear una cuenta de creador</li><li>Subir contenido y configurar el precio</li><li>Generar mayor interacción con tus fans</li><li>Mantener tu perfil actualizado y activo creando contenido regularmente</li></ul>",
    },
    {
      question: "¿Qué requisitos necesito para crear una cuenta de creador en MyFans1A?",
      answer:
        "<ul list-padding-bottom><li>Ser mayor de 18 años.</li><li>Completar tu perfil (Definir nombre de usuario, nombre completo, breve descripción, pais, fecha de nacimiento).</li><li>Aceptar los términos y condiciones de creador.</li><li>Verifica tu identidad (Documento de identidad original vigente)</li><li>A tu bandeja de mensajes de MyFans1A llegará la aprobación de tu cuenta.</li></ul>",
    },
    {
      question: "¿Qué porcentaje puedo ganar y con qué frecuencia puedo recibir mis pagos?",
      answer: "Un creador puede ganar hasta el 80% de los ingresos generados y los pagos se efectúan cada 15 días.",
    },
    {
      question: "¿Dónde puedo generar más ingresos? ¿En la suscripción mensual o en la venta de contenido Plus1A?",
      answer:
        "La suscripción está limitada a un monto fijo mensual, mientras que en el contenido Plus1A puedes fijar un valor más alto por cada publicación. Sin embargo, tener un amplio contenido premium hará que tus fans se motiven a adquirir contenido Plus1A de mayor valor.",
    },
    {
      question: "¿Como Fan puedo suscribirme y acceder al contenido Premium con la compra de créditos?",
      answer: "No, la suscripción es una transacción independiente.",
    },
    {
      question: "¿Un Fan puede tener acceso al contenido Plus1A si compra una suscripción?",
      answer: "No, para tener acceso al contenido Plus1A, se debe realizar una compra de créditos.",
    },
    {
      question: "¿Mis créditos vencen en algún momento?",
      answer: "No, los créditos nunca vencen.",
    },
    {
      question: "¿Qué información se va a ver reflejada en mi estado de cuenta bancario?",
      answer: "Se verá reflejado Segpay.com *onemedia en tu estado de cuenta bancario al momento de realizar una compra.",
    },
    {
      question: "¿Qué métodos de pago ofrecen para los creadores?",
      answer: `<ul>
        <li>
          1. Ofrecemos un método de pago conveniente mediante nuestro aliado
          comercial https://segcards.com/ donde podrás abrir una cuenta, recibir una
          tarjeta visa a tu nombre y tus ganancias serán cargadas de manera segura a
          tu cuenta de segpay. Luego podrás realizar pagos o retiros en cualquier
          establecimiento donde la tarjeta visa sea aceptada.
        </li>
        <li>2. Transferencia a tu cuenta de banco en la moneda local.</li>
        <li>3. Transferencia vía Paxum.</li>
      </ul>`,
    },
    {
      question: "¿Tienen programa de referidos?",
      answer: "Si, por cada referido obtienes el 5% del total de sus ventas.",
    },
    {
      question: "¿Cómo puedo cancelar mi suscripción?",
      answer: "Enviando un correo a support@myfans1a.com o a través de la opción de cancelar suscripción en la plataforma.",
    },
    {
      question: "¿Cómo puedo eliminar mi cuenta?",
      answer:
        "Enviando un correo a support@myfans1a.com realizando la solicitud. Sin embargo, los suscriptores activos tendrán acceso al contenido hasta que venza la suscripción.",
    },
    {
      question: "¿Cómo puedo contactarlos?",
      answer: "<a link='/support'>Haz clic en este enlace para ver nuestros datos de contacto y horarios de atención</a>",
    },
  ],
  report: {
    why: "¿Por qué reportar esta publicación?",
    fake_info: "La información es falsa",
    violent_content: "Contenido violento",
    fraud: "Fraude",
    spam: "Spam",
    hate_speech: "Comentarios o expresiones de odio",
    related_a_minor: "Contenido relacionado con un menor",
    other: "Otro",
    has_response: "Tiene respuesta",
    not_has_response: "Sin respuesta",
    add_answer: "Añadir una respuesta...",
    end: "Fin",
  },
  landing: {
    "creators-top-title": "CONOCE NUESTRAS MEJORES CREADORAS",
    "model-label": "Modelo",
    "model-exclusive-label": "Modelo Exclusiva",
  },
  support: {
    title: "Support",
    phone_number: "17868255987",
    email: "support@myfans1a.com",
    creator_support: {
      title: "Creator support",
      info:
        '<div><p><strong>Contact us:</strong></p><ul><li>1-(000)-000-000</li><li><a href="mailto:support@myfans1a.com">support@myfans1a.com</a></li></ul></div>',
    },
    user_support: {
      title: "User support",
      info:
        '<div><p><strong>Contact us:</strong></p><ul><li>1-(000)-000-000</li><li><a href="mailto:support@myfans1a.com">support@myfans1a.com</a></li></ul></div>',
    },
    technical_support: {
      title: "Technical support",
      info:
        '<div><p><strong>Contact us:</strong></p><ul><li>1-(000)-000-000</li><li><a href="mailto:support@myfans1a.com">support@myfans1a.com</a></li></ul></div>',
    },
  },
  statistics: {
    expired: "Vencidas",
    cancelled: "Canceladas",
    "unlock-content": "Desbloqueo de contenido",
    "total-earnings": "Total ganancias",
    qty: "Cantidad",
    credits: "Créditos",
    subscriptions: "Suscripciones",
    users: "Usuarios",
    "by-country": "Por país",
    "by-age": "Por edad",
    "by-con": "Hora de conexión",
    "total-fans": "Total Usuarios Fans",
    "total-creators": "Total Usuarios Creadores",
    "total-sales-credits": "Total Ventas en Créditos",
    "total-sales-dollars": "Total Ventas en Dólares",
  },
  periods: {
    custom: "Personalizado",
    "last-month": "Último mes",
    "last-3-months": "Últimos 3 meses",
    "last-6-months": "Últimos 6 meses",
    "last-12-months": "Últimos 12 meses",
  },
};
