import Go from "../plugins/go.js/go.js";
import Vue from "vue";
import App from "./App.vue";
import router from "../router";
import store from "../store";
import validate from "../assets/js/validate";
import global from "../assets/js/global";
import video from "../assets/js/video";
import image from "../assets/js/image";
import Swal from "sweetalert2";
import axios from "axios";
import api from "../api";
import ialert from "../plugins/ialert";
import iconic from "../plugins/iconic";
import ifacebook from "../plugins/ifacebook";
import istate from "../plugins/istate/istate";
import igoogle from "../plugins/igoogle";
import oauth from "../plugins/oauth";
import scroller from "../assets/js/scroller";
import { globals } from "../plugins/globals";
import VueMeta from "vue-meta";
import Chartkick from "vue-chartkick";
import VueVirtualScroller from "vue-virtual-scroller";
import directives from "../plugins/directives";
import socket from "../api/socket";
import "./app";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import "@/assets/styles/main.scss";
import "./registerServiceWorker";
import "../assets/js/prevent";
import "../assets/js/gtag";
import "../assets/js/shortcuts";
import "../assets/js/init";

const isAppLoader = window.isAppLoader;

if (isAppLoader) {
  Vue.config.silent = true;
} else {
  Vue.config.productionTip = false;

  Vue.prototype.$global = global;
  Vue.prototype.$store = store;
  Vue.prototype.$validate = validate;
  Vue.prototype.$swal = Swal;
  Vue.prototype.$api = api;
  Vue.prototype.$http = api;
  Vue.prototype.$axios = axios;
  Vue.prototype.$video = video;
  Vue.prototype.$image = image;
  Vue.prototype.$scroller = scroller;
  Vue.prototype.$socket = socket;

  Vue.use(directives);
  Vue.use(istate);
  Vue.use(ialert);
  Vue.use(iconic);
  Vue.use(ifacebook);
  Vue.use(igoogle);
  Vue.use(oauth);
  Vue.use(VueMeta);
  Vue.use(Chartkick);
  Vue.use(VueVirtualScroller);
  Vue.mixin(globals);

  Vue.filter("reverse", function(value) {
    return value.slice().reverse();
  });

  Go.Vue = new Vue({ router, store, render: (h) => h(App) }).$mount("#app");
}
