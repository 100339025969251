const Store = function(id) {
  this.id = id;
  this.data = {};
  this.subscribers = {};
};

const ParentStore = new Store("parent");

const StoreMiddleware = function(store) {
  if (ParentStore.get(store)) {
    return ParentStore.get(store);
  }

  return ParentStore.set(store, new Store(store));
};

export default {
  store: (id) => StoreMiddleware(id),
  Store: (id) => StoreMiddleware(id),
};

Store.prototype.get = function(key) {
  return this.data[key];
};

Store.prototype.set = function(key, value) {
  this.data[key] = value;
  this.notify(key);
  return this.data[key];
};

Store.prototype.subscribe = function(key, cb) {
  if (!this.subscribers[key]) {
    this.subscribers[key] = [];
  }

  this.subscribers[key].push(cb);
};

Store.prototype.notify = function(key) {
  if (this.subscribers[key]) {
    this.subscribers[key].forEach((cb) => cb(this.data[key]));
  }
};

Store.prototype.unsubscribe = function(key, cb) {
  if (this.subscribers[key]) {
    this.subscribers[key] = this.subscribers[key].filter((callback) => callback !== cb);
  }
};

Store.prototype.clear = function() {
  this.data = {};
  this.subscribers = {};
};
