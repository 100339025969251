const buckets = ["myfans1a.s3.amazonaws.com", "s3.amazonaws.com/myfans1a"];

const Cloudfront = {
  newMedia: function(m, domain) {
    return {
      ...m,
      src: m?.src && Cloudfront.replaceDomains(m.src, domain),
      thumb: m?.thumb && Cloudfront.replaceDomains(m.thumb, domain),
      poster: m?.poster && Cloudfront.replaceDomains(m.poster, domain),
      location: m?.location && Cloudfront.replaceDomains(m.location, domain),
    };
  },
  cloudfront: function(media, domain) {
    if (Go.is(media, "Array")) {
      return media.map((m) => Cloudfront.newMedia(m, domain));
    }

    if (Go.is(media, "Object")) {
      return Cloudfront.newMedia(media, domain);
    }

    if (Go.is(media, "String")) {
      return Cloudfront.replaceDomains(media, domain);
    }

    return media;
  },
  replaceDomains: function(string, domain) {
    if (!string) return;

    for (let i = 0; i < buckets.length; i++) {
      let replaceRegex = new RegExp(`${buckets[i]}`, "g");
      string = string.replace(replaceRegex, `${domain}`);
    }

    return string;
  },
};

export default Cloudfront;
