export default {
  data: function() {
    return {
      MAX_MESSAGES_LENGTH: 1024,
    };
  },
  methods: {
    base64_encode: function(data) {
      return btoa(data);
    },
    base64_decode: function(data) {
      return atob(data);
    },
    payload: function(data) {
      this.$store.commit("payload", data);
    },
    encode_message: function(string) {
      return btoa(string);
    },
  },
};
