const client_id = "669122157693-8cd0aj2p3agh45s8eafe2vkr7vimc0ka.apps.googleusercontent.com";
export default {
  install(Vue, options) {
    Vue.mixin({
      data: function() {
        return {
          igoogle: {
            config: {
              client_id: client_id,
              scope: "email profile openid",
              response_type: "id_token permission",
              fetch_basic_profile: true,
            },
            init: function(options) {
              this.loadSDK(document, "script", "google-platform");
            },
            login: async function(options) {
              const _this = this;
              return new Promise(function(resolve, reject) {
                function getInfo(response) {
                  const data = new gapi.auth2.BasicProfile(response);
                  const profile = {
                    id: data.getId(),
                    name: data.getName(),
                    given_name: data.getGivenName(),
                    family_name: data.getFamilyName(),
                    email: data.getEmail(),
                    picture: data.getImageUrl(),
                  };
                  resolve({ ...response, ...profile });
                }
                gapi.load("auth2", function() {
                  gapi.auth2.authorize(_this.config, function(response) {
                    const { error, details } = response;
                    if (error) {
                      resolve(response);
                    } else {
                      getInfo(response);
                    }
                  });
                });
              });
            },
            loadSDK: async function(d, s, id) {
              let js,
                fjs = d.getElementsByTagName(s)[0];
              if (d.getElementById(id)) {
                return;
              }
              js = d.createElement(s);
              js.id = id;
              js.async = true;
              js.defer = true;
              js.src = "https://apis.google.com/js/platform.js";
              fjs.parentNode.insertBefore(js, fjs);
            },
          },
        };
      },
    });
  },
};
