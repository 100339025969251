const GFunction = {};

export default GFunction;

GFunction.sleep = function(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

GFunction.awaitFor = function(fn, interval = 100, timeout = 10000) {
  return new Promise((resolve, reject) => {
    let timer = 0;
    let intervalTimer = setInterval(() => {
      if (fn()) {
        clearInterval(intervalTimer);
        resolve();
      }
      timer += interval;
      if (timer >= timeout) {
        clearInterval(intervalTimer);
        reject();
      }
    }, interval);
  });
};

GFunction.awaitExec = function(fn, timeout = 10000) {
  console.log("exec");
  return new Promise((resolve, reject) => {
    this.exec = setTimeout(() => {
      if (Go.is(fn, "Function")) {
        fn();
      }
      resolve();
    }, timeout);
  });
};
