const Go = {};

export default Go;

Go.init = (() => {
  document.addEventListener("touchstart", function(e) {
    // FastClick.attach(document.body);
  });
})();

Go.start = function() {
  Go.component("go-icon", () => import("./components/go-icon"));
  Go.component("go-progress", () => import("./components/go-progress"));
  Go.component("go-emoji", () => import("./components/go-emoji"));
  Go.component("go-tag", () => import("./components/go-tag"));
};
