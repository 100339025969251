// Extension of es language file

module.exports = {
  yes: "Sí",
  no: "No",
  share: "Compartir",
  more: "Más",
  copy: "Copiar",
  copy_link: "Copiar enlace",
  success: "Éxito",
  error: "Error",
  "100_premium": "100% Premium",
  exclusive_content: "Contenido exclusivo",
  become_and_connect: "Conviértete en creador y conecta directamente con tus miembros MyFans1A",
  pick_up_your_domain: "Elige tu propio dominio",
  setup_your_subscription: "Configura suscripciones mensuales y mucho más",
  become_a_creator: "Conviértete en creador",
  turn_your_followers: "Convierte a tus seguidores",
  into_paid_members: "en miembros pagados",
  view_more_creators: "Ver más creadores",
  profile_updated: "Tu perfil ha sido actualizado",
  social_networks: "Redes sociales",
  full_name: "Nombre completo",
  required: "Requerido",
  country: "País",
  profile_url: "URL de perfil",
  email: "Correo electrónico",
  phone: "Teléfono",
  birthday: "Cumpleaños",
  description: "Descripción",
  experienceAsCreator: "¿Tienes experiencia como creador de contenido?",
  areYouCurrentlyWorking: "¿Actualmente trabajas como creador de contenido? Si es así, ¿cuál es el nombre de la plataforma?",
  name_of_platform: "Nombre de la plataforma",
  want_add_commenst: "¿ Quieres agregar comentarios a tu solicitud ?",
  comments: "Comentarios",
  about_us: "Sobre nosotros",
  how_u_know_us: "Como te enteraste de la agencia ?",
  complete_profile: "Completa tu perfil",
  complete_data: "Completa los datos",
  select_document_type: "Selecciona el tipo de documento",
  creators: "Creadores",
  info: "Información",
  user_name: "Nombre de usuario",
  manage: "Administrar",
  personal: "Personal",
  my_subscriptions: "Mis suscripciones",
  my_credits: "Mis créditos",
  login: "Iniciar sesión",
  help_support: "Ayuda y soporte",
  help_support_dec: "Si necesita ayuda, contacta con nuestro equipo de soporte",
  login_benefits: "Inicia sesión y conecta directamente con tus creadores ó seguidores favoritos",
  signup_benefits: "Conviértete en creador y conecta directamente con tus seguidores favoritos",
  signup_label: "Regístrate",
  help_label: "Ayuda",
  favorites: "Favoritos",
  messages: "Mensajes",
  settings: "Configuración",
  contact_our_support: "Contacta con nuestro equipo de soporte",
  my_transactions: "Mis transacciones",
  developer: "Desarrollador",
  my_notifications: "Mis notificaciones",
  is_user_platform_exclusive: "¿Es un usuario exclusivo de la plataforma?",
  montly_subscriptions_desc: "Suscripcion mensual precio en dolares",
  message_length_limit_excceeded: "El límite de caracteres ha sido excedido",
  characters_max: "caracteres máximo",
  credits: "Créditos",
  credits_history: "Historial de créditos",
  menu_label: "Menú",
  credits_reload: "Recargar créditos",
  miscellanous: "Misceláneo",
  account_info: "Información de la cuenta",
  preferred_language: "Idioma preferido",
  max: "Máximo",
  subscribe_to: "Suscribirse a",
  for: "por",
  monthly: "mensual",
  new_post: "Nuevo post",
  new_post_desc: "Crea un nuevo post",
  new_story: "Nueva historia",
  new_story_desc: "Crea una nueva historia",
  images: "Imágenes",
  videos: "Videos",
  become_premium: "Conviértete en Premium",
  subscribe_to_watch: "Suscríbete para ver todo el contenido premium",
  create_post: "Crear post",
  create_story: "Crear historia",
  save_post: "Guardar post",
  save_story: "Guardar historia",
  principal_slider: "Slider principal",
  position_on_top_creators: "Posición en el top de creadores",
  image_top_creators: "Imagen en el top de creadores",
  image_on_stories: "Imagen en historias",
  wiki: "Wiki",
  reload_credits: "Recargar créditos",
  buy_credits: "Comprar créditos",
  payout: "Pago",
  extra: "Extra",
  pay_label: "Paga",
  obtain_label: "Obtiene",
  credits_package: "Paquete de créditos",
  credits_package_selected: "Paquete de créditos seleccionado",
  unique_payment: "Pago único",
  total: "Total",
  reload_by: "Recargar por",
  credits_hisotry: "Historial de créditos",
  no_subscriptions_message: "No tienes suscripciones",
  accept_terms: "Acepto los términos y condiciones",
  user: "Usuario",
  delete_user: "Eliminar usuario",
  delete_user_confirm: "¿Estás seguro de que quieres eliminar este usuario?",
  delete: "Eliminar",
  deleted: "Eliminado",
  select_period: "Selecciona el periodo",
  date_from: "Fecha desde",
  date_to: "Fecha hasta",
  apply: "Aplicar",
  start_date: "Fecha de inicio",
  end_date: "Fecha de fin",
  select_start_date: "Selecciona la fecha de inicio",
  select_end_date: "Selecciona la fecha de fin",
  total_posts: "Total de publicaciones",
  total_subscriptions: "Total de suscripciones",
  sales: "Ventas",
  total_content: "Total de contenido",
  total_gifts: "Total de regalos",
  total_flowers: "Total de flores",
  total_tips: "Total de propinas",
  total_credits: "Total de créditos",
  shortcuts: "Atajos",
  credits_packages: "Paquetes de créditos",
  of: "de",
  subscriptions: "Suscripciones",
  fans: "Fans",
  connection_time: "Tiempo de conexión",
  time: "Tiempo",
  last_month: "Último mes",
  last_3_months: "Últimos 3 meses",
  last_6_months: "Últimos 6 meses",
  last_year: "Último año",
  all_time: "Todo el tiempo",
  appearance_on_landing: "Aparición en la página de inicio",
  appearance_on_landing_desc: "Controla los aspectos del perfil en la página de inicio",
  user_profile: "Perfil de usuario",
  user_profile_desc: "Controla algunos aspectos del perfil de usuario",
  filters: "Filtros",
  activated: "Activado",
  subscription_expired: "Suscripción vencida",
  subscription_date: "Fecha de suscripción",
  subscription_status: "Estado de la suscripción",
  subscription_period: "Periodo de suscripción",
  expired: "Caducado",
  active: "Activo",
  cancel_subscription: "Cancelar suscripción",
  renew_subscription: "Renovar suscripción",
  subscribe: "Suscribirse",
  subscribeme: "Suscríbirme",
  cancel_subscription_confirm: "¿Estás seguro de que quieres cancelar la suscripción?",
  home_label: "Inicio",
  come_back: "Volver",
  save_profile: "Guardar perfil",
  my_credits_history: "Mi historial de créditos",
  no_credits_history_message: "No tienes historial de créditos",
  laguage: "Idioma",
  post_info: "Información del contenido",
  post_info_title: "Este paquete contiene:",
  date_post: "Fecha de publicación",
  close_session: "Cerrar sesión",
  close_session_confirm: "¿Estás seguro de que quieres cerrar la sesión?",
  close_session_desc: "Si cierras la sesión, no podrás volver a acceder a tu cuenta hasta que no vuelvas a iniciar sesión",
  creator: "Creador",
  creator_statistics: "Estadísticas del creador",
  no_ubscribers_message: "No tienes suscriptores",
  send_message: "Enviar mensaje",
  subscribe_from: "Suscrito desde",
  last_activation: "Última activación",
  last_renewal: "Última renovación",
  app_instagram: "https://www.instagram.com/myfans1a/",
  total_earnings: "Total de ganancias",
  subscriptions_statistics: "Estadísticas de suscripciones",
  order_by: "Ordenar por",
  date_asc: "Fecha ascendente",
  date_asc_desc: "Ordenar por fecha ascendente",
  date_desc: "Fecha descendente",
  date_desc_desc: "Ordenar por fecha descendente",
  my_unlock: "Mi contenido",
  my_gift: "Mis regalos",
  my_flower: "Mis flores",
  my_tip: "Mis propinas",
  nothing_found: "No se ha encontrado nada",
  name_label: "Nombre",
  age: "Edad",
  image: "Imagen",
  video: "Video",
  live_streaming: "Streaming en vivo",
  video_cover: "Video de portada",
  video_cover_desc: "El video de portada es el video que se muestra en el perfil del creador",
  loading: "Cargando",
  watch_video: "Ver video",
  faq_label: "FAQ",
  commission: "Comisión",
  select_country: "Seleccionar país",
  select_year: "Seleccionar año",
  sales_unlock: "Ventas de contenido plus",
  sales_gift: "Ventas de regalos",
  sales_flower: "Ventas de flores",
  sales_tip: "Ventas de propinas",
  unlock_content: "Contenido plus",
  unlock: "Desbloquear",
  unlock_content_message: "Desbloquea este contenido por tan solo",
  go_to_post: "Ir al contenido",
  explore_creators: "Explorar creadores",
  my_favorites_creators: "Mis creadores favoritos",
  susbcriptions_by_creator: "Suscripciones por creador",
  duration: "Duración",
  hours: "Horas",
  story: "Historia",
  gallery_label: "Galería",
  content_removal_label: "Eliminación de Contenido",
  our_gallery: "Nuestra galería",
  upload_images: "Subir imágenes",
  create_category: "Crear categoría",
  upload_images: "Subir imágenes",
  save_category: "Guardar categoría",
  image: "Imagen",
  creating_category: "Creando categoría",
  category_name: "Nombre de la categoría",
  delete_category: "Eliminar categoría",
  edit_category: "Editar categoría",
  select_a_category: "Selecciona una categoría",
  category: "Categoría",
  uploaded: "Subido",
  delete_file: "Eliminar archivo",
  delete_confirm: "¿Estás seguro de que quieres eliminar esto?",
  delete_file_confirm: "¿Estás seguro de que quieres eliminar este archivo?",
  deleting: "Eliminando",
  all_galleries: "Todas las galerías",
  remove_filter: "Eliminar filtro",
  adjust_cover: "Ajustar portada",
  save: "Guardar",
  adjust_image: "Ajustar imagen",
  footer_message: `<div> <span>My Fans 1A, aloja contenido para adultos,</span> <span> entra solo si reconoce que tiene 18 o más años de edad y está de acuerdo con nuestros&nbsp;</span> <b link="/terms">Términos y condiciones</b> <span>&nbsp;y nuestra&nbsp;</span> <b link="/privacy">Política de privacidad</b> <span>&nbsp;de datos</span> </div>`,
  view_post: "Ver contenido",
  explore: "Explorar",
  creators_verification: "Verificación de creadores",
  complaints: "Quejas",
  contain: "Contenido",
  watch: "Ver",
  documents: "Documentos",
  how_know_us: "¿Cómo nos conociste?",
  comment: "Comentario",
  no_comment: "Sin comentarios",
  price_credits: "Precio en créditos",
  price_dollars: "Precio en dólares",
  show_on_landing: "Mostrar en la página de inicio",
  share_post: "Compartir contenido",
  see_more: "Ver más",
  get_credits: "Obtener créditos",
  for_unlock_exclusive_content: "Para desbloquear contenido exclusivo",
  buy: "Comprar",
  delete_account: "Eliminar cuenta",
  account_settings: "Configuración de la cuenta",
  account_delete_confirm: `<div>¿Estás seguro de que quieres eliminar tu cuenta?</div><div>Por favor escriba la palabra <i dcolor>eliminar</i> y luego haga clic en aceptar</div>`,
  type_here: "Escribe aquí",
  deleting_account: "Eliminando cuenta",
  total_sales: "Total de ventas",
  total_subscriptions_sales: "Total de ventas de suscripciones",
  total_credits_sales: "Total de ventas de créditos",
  my_earnings: "Mis ganancias",
  please_type_keyword: "Por favor escribe una palabra clave",
  reports: "Reportes",
  total_sales_dollars: "Total de ventas en dólares",
  total_sales_credits: "Total de ventas en créditos",
  general_sales_resume: "Resumen de ventas generales",
  info_and_manage_users: "Información y administración de usuarios",
  total_sales_by_subscriptions: "Total de ventas por suscripciones",
  total_sales_by_credits: "Total de ventas por créditos",
  past_month: "Mes pasado",
  phone_is_required: "El teléfono es requerido",
  is_required: "es requerido",
  connect_with: "Conectar con",
  send_a_gift: "Enviar un regalo",
  send_gift: "Enviar regalo",
  optional_message: "Mensaje opcional",
  sending_gift: "Enviando regalo",
  sending_gift_to: "Enviando regalo a",
  creator_earnings: "Ganancias del creador",
  set_commission_percent: "Establecer porcentaje de comisión",
  subscribe_advantages: ["Acceso a contenido exclusivo", "Envío de mensajes directos", "Cancela la suscripción en cualquier momento"],
  account: "Cuenta",
  total_sales_net: "Total de ventas netas",
  net: "Neto",
  instagram_is_required: "Instagram es requerido",
  enjoy_all_creator_content: "Disfruta de todo el contenido Premium de $creator",
  language_button: "Idioma",
  file_size_is_too_big: "El tamaño del archivo es demasiado grande",
  max_size: "Tamaño máximo",
  the_file: "El archivo",
  post_allow_formats: "El contenido solo permite los siguientes formatos",
  is_not_supported: "no es compatible",
  is_too_large: "es demasiado grande",
  subscriptions_cancelled: "Suscripciones canceladas",
  subscriptions_cancelled_desc: "Las suscripciones canceladas son las suscripciones que el usuario canceló",
  message_empty: "El mensaje está vacío",
  unlock_content_sure_by: "¿Estás seguro de que quieres desbloquear este contenido por?",
  for_unlock: "Para desbloquear",
  need_update_username_desc: `Por favor actualiza tu nombre de usuario para poder continuar.`,
  username_features: "El nombre de usuario debe tener entre 6 y 20 caracteres y solo puede contener letras y números",
  confirm: "Confirmar",
  sure_update_username: "¿Estás seguro de que quieres actualizar tu nombre de usuario?",
  report_label: "Reporte",
  general: "General",
  date_review: "Fecha de revisión",
  sending_message: "Enviando mensaje",
  payments: "Pagos",
  payments_desc: "Los pagos son las transacciones que se realizan en la plataforma",
  date: "Fecha",
  purchaseid: "ID de compra",
  mount: "Monto",
  tranid: "Transacción ID",
  total_pays: "Total de pagos",
  total_mount: "Total de montos",
  type: "Tipo",
};
