export const components = {
  MyView: () => import("../../views/View.vue"),
  Title: () => import("../../components/widgets/Title.vue"),
  ViewHeader: () => import("../../components/view/ViewHeader.vue"),
  ViewContent: () => import("../../components/view/ViewContent.vue"),
  Modal: () => import("../../components/widgets/Modal.vue"),
  ModalItem: () => import("../../components/widgets/ModalItem.vue"),
  ListItem: () => import("../../components/widgets/ListItem.vue"),
  ListItemCheck: () => import("../../components/widgets/ListItemCheck.vue"),
  Collapse: () => import("../../components/widgets/Collapse.vue"),
  DateRangePicker: () => import("vue2-daterange-picker"),
  DateRangeFilter: () => import("../../components/widgets/DateRangeFilter.vue"),
  TextareaRich: () => import("../../components/widgets/TextareaRich.vue"),
  Confirm: () => import("../../components/widgets/Confirm.vue"),
  LoadMore: () => import("../../components/widgets/LoadMore.vue"),
  LoadMoreButton: () => import("../../components/widgets/LoadMoreButton.vue"),
  GradientButton: () => import("../../components/widgets/GradientButton.vue"),
  UserAvatar: () => import("../../components/widgets/UserAvatar.vue"),
  BubbleNumber: () => import("../../components/widgets/BubbleNumber.vue"),
  MessageBubble: () => import("../../components/widgets/MessageBubble.vue"),
  Tabs: () => import("../../components/widgets/Tabs.vue"),
  TitleBar: () => import("../../components/widgets/TitleBar.vue"),
  Recycler: () => import("../../components/widgets/Recycler.vue"),
  LanguageButton: () => import("../../components/widgets/LanguageButton.vue"),
  Spacer: () => import("../../components/widgets/Spacer.vue"),
  SingleCard: () => import("../../components/widgets/SingleCard.vue"),
  FeedPost: () => import("../../components/feed/FeedPost.vue"),
  FeedTitle: () => import("../../components/feed/FeedTitle.vue"),
  MyRoleLabel: () => import("../../components/widgets/MyRoleLabel.vue"),
  LandingFooter: () => import("../../components/landing/LandingFooter.vue"),
  CreatorFileCard: () => import("../../components/creator/CreatorFileCard.vue"),
  CircleProgress: () => import("../../components/widgets/CircleProgress.vue"),
  Loader1A: () => import("../../components/widgets/Loader1A.vue"),
  CircleButton: () => import("../../components/widgets/CircleButton.vue"),
  ImageFileInput: () => import("../../components/widgets/ImageFileInput.vue"),
  ArrowsControl: () => import("../../components/widgets/ArrowsControl.vue"),
  Space: () => import("../../components/widgets/Space.vue"),
  CardMessage: () => import("../../components/widgets/CardMessage.vue"),
  AlertMessage: () => import("../../components/widgets/AlertMessage.vue"),
  InputLabel: () => import("../../components/widgets/InputLabel.vue"),
  InputSlider: () => import("../../components/widgets/InputSlider.vue"),
  ToggleValues: () => import("../../components/widgets/ToggleValues.vue"),
  InputFormat: () => import("../../components/widgets/InputFormat.vue"),
  ToggleSwitchLight: () => import("../../components/inputs/ToggleSwitchLight.vue"),
  VueSlickCarousel: () => import("vue-slick-carousel"),
  LargeButton: () => import("../../components/widgets/LargeButton.vue"),
  IntrinsicSVG: () => import("../../components/widgets/IntrinsicSVG.vue"),
  PrimaryButton: () => import("../../components/buttons/PrimaryButton.vue"),
  PreloadingLine: () => import("../../components/widgets/PreloadingLine.vue"),
  Carrousel: () => import("../../components/widgets/Carrousel.vue"),
  FeatureSoon: () => import("../../components/widgets/FeatureSoon.vue"),
  VideoDuration: () => import("../../components/widgets/VideoDuration.vue"),
  ProgressBar: () => import("../../components/widgets/ProgressBar.vue"),
  Input: () => import("../../components/widgets/Input.vue"),
  InputPrefix: () => import("../../components/inputs/InputPrefix.vue"),
  Icon: () => import("../iconic/iconic.vue"),
  UserBecomeCreatorBtn: () => import("../../components/user/UserBecomeCreatorBtn.vue"),
};
