export default {
  state: () => ({
    stories: [],
    statistics: {},
    creator: {},
    current: {},
    currentIndex: 0,
  }),
  mutations: {
    setStories: async function(state, { stories, creator, statistics }) {
      if (statistics.page === 1) {
        state.stories = stories;
        state.creator = creator;
        state.statistics = statistics;
      } else if (statistics.page > 1) {
        state.stories.push(...stories);
        state.statistics.page = statistics.page;
      }
      state.statistics.hasMore = state.statistics.total > statistics.page * statistics.limit;
    },
    setCurrentStories: async function(state, current) {
      state.current = current;
    },
    setCurrentIndexStories: async function(state, current) {
      state.currentIndex = current;
    },
  },
};
