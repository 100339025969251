import socket from "../api/socket";

export default {
  state: () => ({
    percent: 0,
    message: "",
  }),
  actions: {
    listenProgress: ({ commit, state }) => {
      socket.io.on("progress", ({ progress, percent, message }) => {
        percent = Number(percent || progress);

        if (percent >= state.percent) {
          state.percent = percent;
        }

        state.message = message;
      });
    },
    resetProgress: ({ commit, state }) => {
      state.percent = 0;
      state.message = "";
    },
    setProgress: ({ commit, state }, { percent, message }) => {
      state.percent = percent;
      state.message = message;
    },
  },
};
