export default {
  state: () => ({
    visible: true,
  }),
  actions: {
    watchVisibility: async function({ commit, state }) {
      this.dispatch("watchPerformance");
      // Set the name of the hidden property and the change event for visibility
      var hidden, visibilityChange;
      if (typeof document.hidden !== "undefined") {
        // Opera 12.10 and Firefox 18 and later support
        hidden = "hidden";
        visibilityChange = "visibilitychange";
      } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
      } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
      }

      // If the page is hidden || if the page is shown, state.visible;
      function handleVisibilityChange() {
        if (document[hidden]) {
          state.visible = false;
        } else {
          state.visible = true;
        }
      }

      // Warn if the browser doesn't support addEventListener or the Page Visibility API
      if (typeof document.addEventListener === "undefined" || hidden === undefined) {
        console.log("Requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.");
      } else {
        // Handle page visibility change
        document.addEventListener(visibilityChange, handleVisibilityChange, false);
      }
    },
  },
  getters: {
    visible: (state) => state.visible,
  },
};
