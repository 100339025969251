export default {
  state: () => ({
    timeStamp: new Date().getTime(),
  }),
  mutations: {
    payload: (state, payload) => {
      let currentPayload = localStorage.getItem("payload");
      if (currentPayload) {
        currentPayload = JSON.parse(currentPayload);
        currentPayload = { ...currentPayload, ...payload };
      } else {
        currentPayload = payload;
      }
      localStorage.setItem("payload", JSON.stringify(currentPayload));
      Object.assign(state, currentPayload);
    },
  },
  actions: {
    payload: async function({ commit }, data) {
      if (!data) return;
      commit("payload", data);
    },
  },
};
