import api from "@/api";
import socket from "../api/socket";
import router from "../router";
const { postsMiddleware } = require("./posts.middleware.js");

export default {
  state: () => ({
    post: null,
    postReview: null,
    postReport: null,
    postProgress: { message: "", percent: 0 },
    postContext: null,
    posts: [],
    statistics: {},
    iSubscriptor: false,
    profile: null,
    stories: {},
    allStoriesLoaded: false,
    style: {
      height: "480px",
      heightMobile: "420px",
    },
  }),
  mutations: {
    postReviewed: function({ state }, { post, status }) {
      state.posts = state.posts.map((item) => {
        if (item._id === post._id) {
          item.adminStatus = status;
        }
        return item;
      });
    },
    setPostProgress: (state, { percent, message }) => {
      state.postProgress.message = message;
      state.postProgress.percent = percent;
    },
    userPostsActivity: (state, user) => {
      const { _id, online, lastConnection } = user;
      state.posts = state.posts.map((item) => {
        if (item.user._id === _id) {
          item.user.online = online;
          item.user.lastConnection = lastConnection;
        }
        return item;
      });
    },
    newPostEnded: (state, { post }) => {
      router.push(`/post/${post._id}`).catch(() => {});
    },
    newPostComment: (state, { comment }) => {
      // increate comments count on post
      state.posts = state.posts.map((item) => {
        if (item._id === comment.post._id) {
          item.comments++;
        }
        return item;
      });
    },
  },
  actions: {
    savePost: async function({ commit }, data) {
      const { id, creator } = data;
      if (!id && data.callback) return data.callback("Missing post ID");
      const sedData = api.post(process.env.VUE_APP_API_HOST + "user/post/save", { id, creator });
      sedData.then((response) => {
        if (data.callback) data.callback(response);
      });
      sedData.catch((response) => {
        if (data.callback) data.callback(response);
      });
    },
    likePost: async function({ commit }, data) {
      const { id, creator } = data;
      if (!id && data.callback) return data.callback("Missing post ID");
      const sedData = api.post(process.env.VUE_APP_API_HOST + "user/post/like", { id, creator });
      sedData.then((response) => {
        if (data.callback) data.callback(response);
      });
      sedData.catch((response) => {
        if (data.callback) data.callback(response);
      });
    },
    sendReview: async function({ commit }, { id, formData, callback }) {
      if (!formData) {
        return callback ? callback({ data: { error: true, message: "Missing Data" } }) : { data: { error: true, message: "Missing Data" } };
      }

      const sedData = api.post(process.env.VUE_APP_API_HOST + "admin/post-review", formData);
      sedData.then((response) => {
        if (callback) return callback(response);
        return response;
      });

      sedData.catch((response) => {
        if (callback) return callback(response);
        return response;
      });
    },
    listenPostProgress: ({ commit, state }) => {
      socket.io.on("postProgress", (data) => {
        commit("setPostProgress", data);
      });

      socket.io.on("postProgressEnd", (data) => {
        commit("lockapp", false);
        commit("setPostProgress", data);
        commit("newPostEnded", data);
      });

      socket.io.on("postProgressError", (data) => {
        commit("lockapp", false);
        commit("setPostProgress", data);
      });
    },
    getPosts: async function({ commit, dispatch, state }, { query }) {
      query += `&allStoriesLoaded=${state.allStoriesLoaded}`;

      const response = await api.get(`${process.env.VUE_APP_API_HOST}posts?` + query);

      state.statistics = response.data.statistics;
      state.iSubscriptor = response.data.iSubscriptor;
      state.profile = response.data.profile;

      let posts = postsMiddleware(response.data.posts, state.statistics);

      if (response.data.statistics.page > 1) {
        state.posts.push(...posts);
      } else {
        state.posts = posts;
      }

      return response;
    },
  },
  getters: {
    postReview: (state) => state.postReview,
  },
};
