const Api = (module.exports = Go.vue);

Api.computed.api = function() {
  return {
    get: (host, data, opt) => this.$api.get(`${process.env.VUE_APP_API_HOST}/${host}`, data, opt),
    post: (host, data, opt) => this.$api.post(`${process.env.VUE_APP_API_HOST}/${host}`, data, opt),
  };
};

Api.methods.router = function(route) {
  this.$router.push(route).catch(() => {});
};
