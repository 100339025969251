import { ifAuthenticated, ifNotAuthenticated } from "../assets/js/session";
const routes = [
  {
    path: "/dashboard",
    component: () => import("./../components/admin/Admin.vue"),
    beforeEnter: ifAuthenticated,
    meta: { requireRoles: ["super"], deniedTo: "/", approvedTo: "/admin", meta: { sitemap: { ignoreRoute: true } } },
    children: [
      {
        path: "/admin/products",
        component: () => import("./../components/admin/AdminProducts.vue"),
      },
    ],
  },
];

export default routes;
