import api from "@/api";

export default {
  state: () => ({
    subscribers: {
      items: [],
      page: 1,
      total: 0,
      hasMore: false,
    },
  }),
  actions: {
    getUserSubscribers: async function({ commit }, { page = 1, limit = 10, query = "" }) {
      const req = await api.get(`${process.env.VUE_APP_API_HOST}/creator/subscribers?page=${page}&limit=${limit}${query}`);
      commit("setUserSubscribers", req.data);
    },
  },
  mutations: {
    setUserSubscribers: function(state, { items, page, total, limit }) {
      if (page <= 1) {
        state.subscribers.items = items;
        state.subscribers.total = total;
        state.subscribers.page = page;
      } else {
        state.subscribers.items.push(...items);
        state.subscribers.page = page;
      }
      state.subscribers.hasMore = state.subscribers.total > page * limit;
    },
  },
};
