const Interact = {};

export default Interact;

Interact.interact = function(el, options) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  Go.extend(el, Go.events);
  Go.extend(el, Go.gestures);

  return el;
};
