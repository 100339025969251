const Dom = {};

export default Dom;

Dom.removeAllClassOnDom = function(className) {
  let elements = document.querySelectorAll(`.${className}`);
  elements.forEach((el) => {
    el.classList.remove(className);
  });
};

Dom.removeAllClassOnElement = function(el, className) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  let elements = el.querySelectorAll(`.${className}`);
  elements.forEach((el) => {
    el.classList.remove(className);
  });
};

Dom.blurAll = function() {
  var tmp = document.createElement("input");
  document.body.appendChild(tmp);
  tmp.focus();
  document.body.removeChild(tmp);
};
