const Element = {};

export default Element;

Element.addClass = function(el, className) {
  if (Go.is(el, "String")) {
    el = document.querySelectorAll(el);
  }

  if (Go.is(el, "Array") || Go.is(el, "NodeList")) {
    el.forEach((_el) => {
      Element.addClass(_el, className);
    });
    return;
  }

  if (Go.is(className, "String") && className.includes(" ")) {
    className.split(" ").forEach((className) => {
      className && Element.addClass(el, className);
    });
    return;
  }

  if (Go.is(className, "Array")) {
    className.forEach((className) => {
      className && Element.addClass(el, className);
    });
    return;
  }

  if (el) {
    el.classList.add(className);
  }
};

Element.removeClass = function(el, className) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  if (el) {
    el.classList.remove(className);
  }
};

Element.hasClass = function(el, className) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  if (el) {
    return el.classList.contains(className);
  }

  return false;
};

Element.toggleClass = function(el, className) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  if (el) {
    el.classList.toggle(className);
  }
};

Element.toggleClasses = function(el, classes) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  if (el) {
    classes.forEach((className) => {
      el.classList.toggle(className);
    });
  }
};

Element.isEmpty = function(el) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  if (el) {
    return el.innerHTML === "";
  }

  return false;
};

Element.awaitUntilElement = function(el, config = {}) {
  const { retry = 50 } = config;
  return new Promise(async (resolve, reject) => {
    let [foundElement, store] = [null, Go.store(`awaitUntilElement-${el}`)];
    let nretry = store.get("nretry") || 0;

    if (!el || nretry > retry) {
      store.clear();
      return reject(`Element.awaitUntilElement: Element (${el}) not found, timeout`);
    }

    foundElement = document.querySelector(el);

    if (!foundElement) {
      nretry++;
      store.set("nretry", nretry);
      await Go.sleep(100);
      return resolve(Element.awaitUntilElement(...arguments));
    }

    store.clear();

    return resolve(foundElement);
  });
};

Element.waitForElement = function(el, config = {}) {
  return Element.awaitUntilElement(el, config);
};

Element.awaitElement = function(el, config = {}) {
  return Element.awaitUntilElement(el, config);
};

Element.parseAllAttributes = function(el) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  if (!el) {
    return {};
  }

  const attrs = {};
  const attributes = el.attributes;

  for (let i = 0; i < attributes.length; i++) {
    const { name, value } = attributes[i];
    attrs[name] = value;
  }

  return attrs;
};

Element.uniqueClass = function(className, el, scope) {
  if (!Go.is(scope, "HTMLElement")) {
    scope = document.querySelector(scope);
  }

  if (!scope) {
    scope = document.body;
  }

  const elements = scope.querySelectorAll(`.${className}`);

  elements && elements.forEach((_el) => _el.classList.remove(className));

  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  Element.addClass(el, className);
};

Element.scrollIntoView = function(el) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  if (el) {
    el.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};

Element.select = function(el) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  if (!el) return null;

  Go.extends(el, Go.events);

  return el;
};

Element.isScrollTop = function(el) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  if (!el) return false;

  return el.scrollTop === 0;
};

Element.isScrollBottom = function(el) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  if (!el) return false;

  return el.scrollTop + el.clientHeight === el.scrollHeight;
};

Element.isScrollLeft = function(el) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  if (!el) return false;

  return el.scrollLeft === 0;
};

Element.isScrollRight = function(el) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  if (!el) return false;

  return el.scrollLeft + el.clientWidth === el.scrollWidth;
};

Element.scrollBottom = function(el) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  if (!el) return;

  el.scrollTop = el.scrollHeight;
};

Element.scrollTop = function(el) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  if (!el) return;

  el.scrollTop = 0;
};

Element.scrollLeft = function(el) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  if (!el) return;

  el.scrollLeft = 0;
};

Element.scrollRight = function(el) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  if (!el) return;

  el.scrollLeft = el.scrollWidth;
};

Element.scroll = function(el, config = {}) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  if (!el) return;

  const { top, left, behavior = "smooth" } = config;

  el.scrollTo({ top, left, behavior });
};

Element.scrollBy = function(el, config = {}) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  if (!el) return;

  const { top, left, behavior = "smooth" } = config;

  el.scrollBy({ top, left, behavior });
};

Element.scrollIntoView = function(el, config = {}) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  if (!el) return;

  const { behavior = "smooth", block = "start", inline = "nearest" } = config;

  el.scrollIntoView({ behavior, block, inline });
};

Element.scrollIntoViewIfNeeded = function(el, config = {}) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  if (!el) return;

  const { behavior = "smooth", block = "start", inline = "nearest" } = config;

  el.scrollIntoViewIfNeeded({ behavior, block, inline });
};

Element.scrollIntoViewCenter = function(el, config = {}) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  if (!el) return;

  const { behavior = "smooth", block = "center", inline = "center" } = config;

  el.scrollIntoView({ behavior, block, inline });
};

Element.toggleAttribute = function(el, attr, value) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  if (!el) return;

  if (el.hasAttribute(attr)) {
    el.removeAttribute(attr);
  } else {
    el.setAttribute(attr, value);
  }
};

Element.toggleClass = function(el, className) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  if (!el) return;

  el.classList.toggle(className);
};

Element.toggleStyle = function(el, style, value) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  if (!el) return;

  if (el.style[style]) {
    el.style[style] = "";
  } else {
    el.style[style] = value;
  }
};

Element.toggleAttributeValues = function(el, attr, values) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  if (!el) return;

  const index = values.indexOf(el.getAttribute(attr));

  if (index === -1) {
    el.setAttribute(attr, values[0]);
  } else {
    el.setAttribute(attr, values[index + 1] || values[0]);
  }
};

Element.isScrollBarVisible = function(el) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  if (!el) return false;

  return el.scrollHeight > el.clientHeight;
};

Element.infoElement = function(el) {
  if (!Go.is(el, "HTMLElement")) {
    el = document.querySelector(el);
  }

  if (!el) return;

  const rect = el.getBoundingClientRect();

  const screenLeft = rect.left + window.scrollX;
  const screenTop = rect.top + window.scrollY;
  const screenBottom = rect.bottom + window.scrollY;
  const screenRight = rect.right + window.scrollX;

  const info = {
    tag: el.tagName.toLowerCase(),
    height: el.offsetHeight,
    width: el.offsetWidth,
    left: el.offsetLeft,
    top: el.offsetTop,
    screenLeft,
    screenTop,
  };

  return info;
};

Element.info = function() {
  return Element.infoElement(...arguments);
};
