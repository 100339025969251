if (process.env.NODE_ENV === "production") {
  document.addEventListener("contextmenu", (event) => event.preventDefault());
  document.addEventListener(
    "keydown",
    (e) => {
      if (e.keyCode == 83 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
        e.preventDefault();
      }
    },
    false,
  );
}
