const Creator = {
  state: () => ({
    current: null,
    CreatorMenuMobile: false,
    apply: {
      step1: false,
      step2: false,
      step3: false,
      step4: false,
    },
  }),
  mutations: {
    setApplyStep: async function(state, { step, value }) {
      state.apply[step] = value;
    },
    CreatorMenuMobile: function(state, CreatorMenuMobile) {
      state.CreatorMenuMobile = CreatorMenuMobile;
    },
    setCurrentCreator: function(state, creator) {
      state.current = creator;
    },
  },
};

export default Creator;
