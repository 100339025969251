import store from "@/store";
import global from "@/assets/js/global";

export default {
  state: () => ({
    items: [],
    openeds: {},
  }),
  actions: {
    addModal: function({ state, commit }, modal) {
      modal.id = global.fastID();
      modal.open = (el) => commit("openModal", { modal, el });
      modal.close = (cb) => commit("closeModal", { modal, cb });
      state.items.push(modal);
      return modal;
    },
    closeAllModals: function({ state, commit }) {
      state.items.forEach((modal) => {
        commit("closeModal", { modal });
      });
    },
  },
  mutations: {
    openModal: (state, { modal, el }) => {
      const view = document.querySelector(`[view-id="${modal.id}"] .modal-card`);

      if (view) {
        Go.animate({ el: view, ...modal }).open(() => {
          store.commit("beforeOpenModal", { modal, el: view });
        });
      }
    },
    closeModal: (state, { modal, cb }) => {
      const view = document.querySelector(`[view-id="${modal.id}"] .modal-card`);

      if (view) {
        Go.animate({ el: view, ...modal }).close(() => {
          store.commit("removeModal", modal);
          store.commit("beforeCloseModal", { modal, cb });
        });
      }

      return;
    },
    beforeOpenModal: (state, { modal, el }) => {},
    beforeCloseModal: async (state, { modal, cb }) => {
      if (Go.is(cb, "Function")) {
        cb(modal);
      }
    },
    removeModal: (state, modal) => {
      let id;

      if (typeof modal === "object") {
        id = modal.id;
      } else {
        id = modal;
      }

      let index = state.items.findIndex((item) => item.id === id);

      if (index >= 0) {
        state.items.splice(index, 1);
        return;
      }

      state.items.splice(id, 1);
    },
  },
};
