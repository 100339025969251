const public_key = "yHMf8r0IQNmA0lxL4g_00nP5NX8";
export default {
  install(Vue, options) {
    Vue.mixin({
      data: function() {
        return {
          oauth: {
            init: function(options) {
              this.loadSDK(document, "script", "oaut-io");
            },
            login: async function(options) {
              const _this = this;
              return new Promise(function(resolve, reject) {
                OAuth.initialize(public_key);
                OAuth.popup(typeof options === "string" ? options : options?.platform)
                  .done(function(result) {
                    result.me().done(function(data) {
                      resolve(data);
                    });
                  })
                  .fail(function(err) {
                    resolve({ error: err });
                  });
              });
            },
            loadSDK: async function(d, s, id) {
              let js,
                fjs = d.getElementsByTagName(s)[0];
              if (d.getElementById(id)) {
                return;
              }
              js = d.createElement(s);
              js.id = id;
              js.async = true;
              js.defer = true;
              js.src = "auth/oauth.js";
              fjs.parentNode.insertBefore(js, fjs);
            },
          },
        };
      },
    });
  },
};
