import { ifAuthenticated, ifNotAuthenticated } from "../assets/js/session";

const routes = [
  {
    path: "/error/:error",
    name: "Error",
    component: () => import("../views/Error.vue"),
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
    },
  },
  {
    path: "*",
    component: () => import("../components/feed/FeedCreator.vue"),
    beforeEnter: ifAuthenticated,
    meta: { deniedTo: "/login", approvedTo: "currentPath", sitemap: { ignoreRoute: true } },
  },
];

export default routes;
