(() => {
  if (process.env.NODE_ENV === "production") {
    const tags = ["G-DNXYPD70WL"];
    const plugin = document.createElement("script");
    plugin.setAttribute("src", `https://www.googletagmanager.com/gtag/js?id=${tags[0]}`);
    plugin.async = true;
    document.head.appendChild(plugin);

    window.dataLayer = window.dataLayer || [];

    function gtag() {
      dataLayer.push(arguments);
    }

    window.gtag = gtag;

    gtag("js", new Date());

    tags.forEach((tag) => {
      gtag("config", tag);
    });
  }
})();
