class Locale {
  constructor({ lang }) {
    this.lang = lang;
    this.locale = {};
    return this.loadLocale();
  }
}

Locale.prototype.loadLocale = async function() {
  this.locale = await require(`./${this.lang}.js`);
  return this.locale;
};

module.exports = async (opts) => new Locale(opts);
