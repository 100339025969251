import socket from "../api/socket";
import store from "../store";

export default {
  state: () => ({
    progress: 0,
  }),
  actions: {
    listenSocketDefaults: async function({ commit, state }) {
      socket.io.on("user", (data) => {
        store.commit("userPostsActivity", data);
      });
    },
  },
  mutations: {
    resetSocketProgress: function(state) {
      state.progress = 0;
    },
  },
};
