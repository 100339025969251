import { methods } from "./methods";
import { components } from "./components";
import user from "./user";
import payments from "./payments";
import modal from "./modal";
import media from "./media";
import alert from "./alert";
import misc from "./misc";
import admin from "./admin";
import post from "./post";
import creator from "./creator";
import profile from "./profile";
import routeMonitor from "./route.monitor.js";
import api from "./api.js";

export const globals = {
  props: ["autoclose"],
  components: components,
  methods: methods,
  mixins: [misc, alert, user, payments, modal, media, admin, post, creator, profile, routeMonitor, api],
  data: function() {
    return { Go: window.Go };
  },
  computed: {
    $starting() {
      return this.$store.getters.starting;
    },
    $lockapp() {
      return this.$store.getters.lockapp;
    },
    $loading() {
      return this.$store.getters.loading;
    },
    $locale() {
      return this.$store.getters.locale;
    },
    $user() {
      return this.$store.getters.user;
    },
    $profile() {
      return this.$store.getters.profile;
    },
    $payload() {
      return this.$store.getters.payload;
    },
    $creator() {
      return this.$route.params.creator?.replace(/^@/, "");
    },
    $creatorFeed() {
      return this.$store.state.creatorFeed;
    },
    $lang() {
      return this.$store.state.lang.current;
    },
    $screen() {
      return this.$store.getters.screen;
    },
    $visible() {
      return this.$store.getters.visible;
    },
    $updates() {
      return this.$store.getters.updates;
    },
    $search() {
      return this.$store.state.search;
    },
    $isCreator() {
      return this.$user && this.$user["roles"]?.includes("creator");
    },
    $isAdmin() {
      return this.$user && this.$user["roles"]?.includes("super");
    },
    $isUser() {
      return this.$user && !this.$user["roles"].includes("super") && !this.$user["roles"].includes("creator");
    },
    $isDev: function() {
      return this.$user && (this.$user["roles"]?.includes("developer") || this.$user["roles"]?.includes("dev"));
    },
    $isMobile: function() {
      return this.$screen.width <= 576;
    },
    $isTablet: function() {
      return this.$screen.width > 576 && this.$screen.width <= 1024;
    },
    $isDesktop: function() {
      return this.$screen.width >= 1024;
    },
    $isPath: function() {
      return function(path) {
        return path === this.$route.path;
      };
    },
    $appKey: function() {
      return this.$store.state.appKey;
    },
    $alert: () => this.ialert,
    $style: function() {
      const serialize = (obj) => {
        let style = "";
        if (!obj) return style;
        Object.keys(obj).forEach((key) => {
          style += key + ":" + obj[key] + ";";
        });
        return style;
      };

      return function(object) {
        return serialize(object);
      };
    },
    $isUserVerified: function() {
      return this.$user?.verified;
    },
    $isVerified: function() {
      return this.$user?.verified;
    },
    $notifications: function() {
      return this.$store.getters?.profile?.notifications;
    },
    $unreadMessages: function() {
      return this.$store.state.messages.messages.unread;
    },
    $iSubscriptor: function() {
      return this.$store.state.posts.iSubscriptor;
    },
    $isView: function() {
      return function(view) {
        return this.$route.query.view === view;
      };
    },
    $apiHost: function() {
      return process.env.VUE_APP_API_HOST;
    },
    $api_host: function() {
      return process.env.VUE_APP_API_HOST;
    },
    $dcolor: function() {
      return process.env.VUE_APP_DCOLOR;
    },
    $adminUser: function() {
      return this.$locale["adminUser"];
    },
    $isLocal: function() {
      return process.env.VUE_APP_MODE === "local";
    },
    $isRC: function() {
      return process.env.VUE_APP_MODE === "dev";
    },
    $isProd: function() {
      return process.env.VUE_APP_MODE === "prod";
    },
    $favsLink: function() {
      return `/?feed=my_content&content=all&view=content`;
    },
    $links: function() {
      return this.$global.links;
    },
    $mpadding: function() {
      return this.$global.mpadding;
    },
    $progress: function() {
      return this.$store.state.progress;
    },
    $visible: function() {
      return this.$store.state.visibility.visible;
    },
  },
  mounted: function() {
    if (this.autoclose) {
      setTimeout(() => {
        this.$emit("autoclosed");
      }, +this.autoclose);
    }
  },
};
