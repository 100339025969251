const Gesture = {};

export default { gestures: Gesture };

Gesture.onmousedown = function(e) {
  this.eventStart(e);
};

Gesture.ontouchstart = function(e) {
  e.clientX = e.touches[0].clientX;
  e.clientY = e.touches[0].clientY;
  this.eventStart(e);
};

Gesture.onmouseup = function(e) {
  this.eventEnd(e);
};

Gesture.ontouchend = function(e) {
  e.clientX = e.changedTouches[0].clientX;
  e.clientY = e.changedTouches[0].clientY;
  this.eventEnd(e);
};

Gesture.eventStart = function(e) {
  this.gStart = e;
  this.gEnd = e;
};

Gesture.eventEnd = function(e) {
  this.gEnd = e;

  const distance = {
    x: this.gEnd.clientX - this.gStart.clientX,
    y: this.gEnd.clientY - this.gStart.clientY,
  };

  const direction = {
    x: distance.x > 0 ? "right" : "left",
    y: distance.y > 0 ? "down" : "up",
  };

  const velocity = {
    x: distance.x / (this.gEnd.timeStamp - this.gStart.timeStamp),
    y: distance.y / (this.gEnd.timeStamp - this.gStart.timeStamp),
  };

  const swipe = {
    distance,
    direction,
    velocity,
  };

  this.isSwipe(swipe);
};

Gesture.isSwipe = function(swipe) {
  this.swipeSpeed = 0.45;
  this.swipeDistance = 50;
  this.distanceX = Go.toPositive(swipe.distance.x);
  this.velocityX = Go.toPositive(swipe.velocity.x);

  if (this.distanceX > this.swipeDistance && this.velocityX > this.swipeSpeed) {
    this.emit("swipe", swipe);
    this.emit("swipe" + swipe.direction.x, swipe);
  }

  this.distanceY = Go.toPositive(swipe.distance.y);
  this.velocityY = Go.toPositive(swipe.velocity.y);

  if (this.distanceY > this.swipeDistance && this.velocityY > this.swipeSpeed) {
    this.emit("swipe", swipe);
    this.emit("swipe" + swipe.direction.y, swipe);
  }
};
