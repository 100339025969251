export default {
  methods: {
    removeModal: function(modal) {
      return this.$store.commit("removeModal", modal);
    },
    modalComponent: function(modal) {
      if (typeof modal === "function") {
        modal = { component: modal };
      }

      if (typeof modal === "string") {
        modal = { component: () => import(`${modal}`) };
      }

      return this.$store.dispatch("addModal", modal);
    },
    view: function(modal) {
      if (!modal.animation && !modal.animate) {
        modal.animation = "fadeIn";
      }

      modal.class = `view ${modal.class || ""}`;
      modal.settings = { ...modal.settings, ...modal };
      modal.bind = { ...modal, component: undefined, modal: undefined, data: undefined };
      return this.modalComponent(modal);
    },
    Modal: function(modal) {
      return this.view(modal);
    },
    addModal: function(modal) {
      return this.view(modal);
    },
    showMessage: function(data = {}) {
      const { icon, message, type, title } = data;
      return this.view({
        title: title || "",
        component: () => import("@/components/widgets/AlertMessage.vue"),
        message: message,
        animation: "midTopIn",
        closeOutside: true,
        icon: icon,
        type: type,
        ...data,
      });
    },
    showAlert: function(data) {
      return this.showMessage(data);
    },
    modalNav: function(path, query) {
      if (this.modal && Go.is(this.modal.close, "Function")) {
        this.modal.close(() => {
          this.$router.push({ path, query }).catch(() => {});
        });
        return;
      }

      this.$router.push({ path, query }).catch(() => {});
    },
    Loader: function(alert = {}) {
      if (Go.is(alert, "String")) {
        alert = { message: alert };
      }

      return this.view({
        header: false,
        clean: true,
        lock: true,
        close: false,
        title: this.$locale["loading"],
        component: () => import("@/components/widgets/Progress.vue"),
        class: "loader",
        ...alert,
      });
    },
    loader: function(data = {}) {
      return this.Loader(data);
    },
  },
};
