export default {
  fadeIn: {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  leftIn: {
    from: {
      transform: "translateX(-180%)",
    },
    to: {
      transform: "translateX(0)",
    },
  },
  rightIn: {
    from: {
      transform: "translateX(180%)",
    },
    to: {
      transform: "translateX(0)",
    },
  },
  topIn: {
    from: {
      transform: "translateY(-180%)",
    },
    to: {
      transform: "translateY(0)",
    },
  },
  bottomIn: {
    from: {
      transform: "translateY(180%)",
    },
    to: {
      transform: "translateY(0)",
    },
  },
  zoomIn: {
    from: {
      transform: "scale(0.5)",
    },
    to: {
      transform: "scale(1)",
    },
  },
  zoomOut: {
    from: {
      transform: "scale(1.5)",
    },
    to: {
      transform: "scale(1)",
    },
  },
  rotateIn: {
    from: {
      transform: "rotate(-90deg)",
    },
    to: {
      transform: "rotate(0)",
    },
  },
  midTopIn: {
    from: {
      opacity: 0,
      transform: "translateY(-50%)",
    },
    to: {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
};
