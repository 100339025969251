import animations from "./go.animations";

const Animate = function(options = {}) {
  this.options = options;
  this.animation = options.animate || animations[options.animation];
  this.animationDuration = options.animationDuration || (this.animation && this.animation.duration) || 250;
  this.el = options.el;
};

export default Animate;

Animate.prototype.getAnimation = function(name) {
  return animations[name];
};

Animate.prototype.open = function(cb) {
  if (!this.el || !this.animation) {
    return;
  }

  Go.setStyle(this.el, { transition: "all 0.3s ease-in-out", ...this.animation.from });

  this.animateCard = this.el.animate([this.animation.from, this.animation.to], {
    duration: this.animationDuration,
    easing: "ease-in-out",
    fill: "forwards",
  });

  this.animateCard.onfinish = () => {
    Go.setStyle(this.el, this.animation.to);

    if (cb && Go.is(cb, "Function")) {
      cb();
    }
  };
};

Animate.prototype.close = function(cb) {
  if (!this.el || !this.animation) {
    return;
  }

  this.animateCard = this.el.animate([this.animation.to, this.animation.from], {
    duration: this.animationDuration,
    easing: "ease-in-out",
    fill: "forwards",
  });

  this.animateCard.onfinish = async () => {
    Go.setStyle(this.el, this.animation.from);

    if (cb && Go.is(cb, "Function")) {
      cb();
    }
  };
};
