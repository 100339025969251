const GO_INCLUDES = {};

export default GO_INCLUDES;

GO_INCLUDES.includes = function(data, includes) {
  if (!data) return false;

  if (!includes) return false;

  if (Go.is(data, "String") && includes.includes(" ")) {
    return GO_INCLUDES.includesMultipleString(data, includes);
  }

  if (Go.is(data, "String")) {
    return data.includes(includes);
  }

  return data.indexOf(includes) !== -1;
};

GO_INCLUDES.includesMultipleString = function(data, includes) {
  let matches = [];

  if (data.includes(" ")) {
    data = data.split(" ");
  }

  for (let string of data) {
    if (Go.includes(includes, string)) {
      matches.push(string);
    }
  }

  return matches.length === data.length;
};
