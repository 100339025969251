export default {
  bind: function(el, { value = {} }, vnode) {
    el.style.position = "sticky";
    el.style.top = value.top;
    el.style.bottom = value.bottom;

    if (value.zIndex) {
      el.style.zIndex = value.zIndex;
    }

    if (value.background) {
      el.style.background = value.background;
    }
  },
};
