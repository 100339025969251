const Evaluate = {};

export default Evaluate;

Evaluate.eval = (expression, context) => {
  return Evaluate.evaluate(expression, context);
};

Evaluate.evaluate = function(expression, context) {
  if (Go.is(expression, "String")) {
    try {
      return eval(expression);
    } catch (error) {
      console.error(error);
    }
  }
};
