export default {
  methods: {
    goToMyLive: function(data = {}) {
      this.view({
        title: this.$locale["live_streaming"],
        class: "menu top",
        animation: "topIn",
        closeOutside: true,
        component: () => import("@/components/creator/CreatorLive.vue"),
      });
    },
    BuySubscription: function(creator) {
      this.view({
        creator,
        title: this.$locale["subscribe"],
        class: "menu bottom",
        animation: "midTopIn",
        component: () => import("@/components/buy/BuySubscription.vue"),
      });
    },
    UnlockContent: function(post) {
      this.view({
        post,
        title: this.$locale["unlock_content"],
        class: "menu bottom",
        animation: "midTopIn",
        component: () => import("@/components/buy/UnlockContent.vue"),
      });
    },
  },
};
