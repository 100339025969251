import api from "@/api";

export default {
  state: () => ({
    likes: [],
  }),
  actions: {
    getLikes: async function({ commit, state }, { query, callback }) {
      if (!query) {
        return callback ? callback({ data: { error: true, message: "Missing post ID" } }) : { data: { error: true, message: "Missing post ID" } };
      }

      const sedData = api.get(`${process.env.VUE_APP_API_HOST}likes?${query}`);
      sedData.then((response) => {
        if (callback) return callback(response);
        return response;
      });

      sedData.catch((response) => {
        if (callback) return callback(response);
        return response;
      });
    },
  },
};
