export default function(config) {
  let data = config.data;
  let map = { files: {} };

  if (!Go.is(data, "FormData")) {
    return JSON.stringify(map);
  }

  data.forEach((value, key) => {
    map[key] = value.length > 0 ? value : "";

    if (Go.is(value, "File")) {
      map[key] = value.size > 0 ? value.size : "";
      map.files[key] = { type: value.type, name: value.name, size: value.size };
    }
  });

  return JSON.stringify(map);
}
