const View = function(data = {}) {
  this.title = data.title || "";
  this.animation = data.animation;
  this.open();
};

export default {
  view: (data) => new View(data),
};

View.prototype.open = function() {
  this.parent = document.createElement("go-view");
  this.body = document.createElement("go-view-content");
};
