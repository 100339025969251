const GO_DATE = {};

export default GO_DATE;

GO_DATE.date = function(date, format) {
  if (!date) {
    date = new Date(date);
  }

  return GO_DATE.dateFormat(date, format);
};

GO_DATE.dateFormat = function(date, data) {
  if (Go.is(data, "String")) {
    data = { format: data };
  }

  let { lang = "en", format = "yyyy-mm-dd", timeZone = "UTC" } = data;

  if (Go.is(date, "String")) {
    date = new Date(date);
  }

  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };

  date = date.toLocaleDateString(lang, options);
  let [mm, dd, yyyy] = date.split("/");

  date = format.replace("yyyy", yyyy);
  date = date.replace("mm", Go.fixZeros(mm));
  date = date.replace("dd", Go.fixZeros(dd));

  return date;
};

GO_DATE.timeFormat = function(date, data) {
  if (Go.is(data, "String")) {
    data = { format: data };
  }

  let { lang = "en", format = "hh:ii:ss", timeZone = "UTC" } = data;

  if (Go.is(date, "String")) {
    date = new Date(date);
  }

  const options = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };

  date = date.toLocaleTimeString(lang, options);
  let [hh, ii, ss] = date.split(":");

  date = format.replace("hh", Go.fixZeros(hh));
  date = date.replace("ii", Go.fixZeros(ii));
  date = date.replace("ss", Go.fixZeros(ss));

  return date;
};

GO_DATE.getYearsDiff = function(date1, date2 = new Date()) {
  if (date1 && date2) {
    if (typeof date1 === "string") {
      date1 = new Date(date1);
    }

    if (typeof date1 === "number") {
      date1 = new Date(date1);
    }

    if (typeof date2 === "string") {
      date2 = new Date(date2);
    }

    if (typeof date2 === "number") {
      date2 = new Date(date2);
    }

    if (typeof date1 === "object" && typeof date2 === "object") {
      if (date1 instanceof Date && date2 instanceof Date) {
        return date2.getFullYear() - date1.getFullYear();
      }
    }
  }

  return 0;
};

GO_DATE.getMonthsDiff = function(date1, date2 = new Date()) {
  if (date1 && date2) {
    if (typeof date1 === "string") {
      date1 = new Date(date1);
    }

    if (typeof date1 === "number") {
      date1 = new Date(date1);
    }

    if (typeof date2 === "string") {
      date2 = new Date(date2);
    }

    if (typeof date2 === "number") {
      date2 = new Date(date2);
    }

    if (typeof date1 === "object" && typeof date2 === "object") {
      if (date1 instanceof Date && date2 instanceof Date) {
        return date2.getMonth() - date1.getMonth();
      }
    }
  }

  return 0;
};

GO_DATE.getDaysDiff = function(date1, date2 = new Date()) {
  if (date1 && date2) {
    if (typeof date1 === "string") {
      date1 = new Date(date1);
    }

    if (typeof date1 === "number") {
      date1 = new Date(date1);
    }

    if (typeof date2 === "string") {
      date2 = new Date(date2);
    }

    if (typeof date2 === "number") {
      date2 = new Date(date2);
    }

    if (typeof date1 === "object" && typeof date2 === "object") {
      if (date1 instanceof Date && date2 instanceof Date) {
        return date2.getDate() - date1.getDate();
      }
    }
  }

  return 0;
};

GO_DATE.getHoursDiff = function(date1, date2 = new Date()) {
  if (date1 && date2) {
    if (typeof date1 === "string") {
      date1 = new Date(date1);
    }

    if (typeof date1 === "number") {
      date1 = new Date(date1);
    }

    if (typeof date2 === "string") {
      date2 = new Date(date2);
    }

    if (typeof date2 === "number") {
      date2 = new Date(date2);
    }

    if (typeof date1 === "object" && typeof date2 === "object") {
      if (date1 instanceof Date && date2 instanceof Date) {
        return date2.getHours() - date1.getHours();
      }
    }
  }

  return 0;
};

GO_DATE.getMinutesDiff = function(date1, date2 = new Date()) {
  if (date1 && date2) {
    if (typeof date1 === "string") {
      date1 = new Date(date1);
    }

    if (typeof date1 === "number") {
      date1 = new Date(date1);
    }

    if (typeof date2 === "string") {
      date2 = new Date(date2);
    }

    if (typeof date2 === "number") {
      date2 = new Date(date2);
    }

    if (typeof date1 === "object" && typeof date2 === "object") {
      if (date1 instanceof Date && date2 instanceof Date) {
        return date2.getMinutes() - date1.getMinutes();
      }
    }
  }

  return 0;
};

GO_DATE.getSecondsDiff = function(date1, date2 = new Date()) {
  if (date1 && date2) {
    if (typeof date1 === "string") {
      date1 = new Date(date1);
    }

    if (typeof date1 === "number") {
      date1 = new Date(date1);
    }

    if (typeof date2 === "string") {
      date2 = new Date(date2);
    }

    if (typeof date2 === "number") {
      date2 = new Date(date2);
    }

    if (typeof date1 === "object" && typeof date2 === "object") {
      if (date1 instanceof Date && date2 instanceof Date) {
        return date2.getSeconds() - date1.getSeconds();
      }
    }
  }

  return 0;
};

GO_DATE.getMillisecondsDiff = function(date1, date2 = new Date()) {
  if (date1 && date2) {
    if (typeof date1 === "string") {
      date1 = new Date(date1);
    }

    if (typeof date1 === "number") {
      date1 = new Date(date1);
    }

    if (typeof date2 === "string") {
      date2 = new Date(date2);
    }

    if (typeof date2 === "number") {
      date2 = new Date(date2);
    }

    if (typeof date1 === "object" && typeof date2 === "object") {
      if (date1 instanceof Date && date2 instanceof Date) {
        return date2.getMilliseconds() - date1.getMilliseconds();
      }
    }
  }

  return 0;
};

GO_DATE.timestamp = function(date = new Date()) {
  if (date) {
    if (typeof date === "string") {
      date = new Date(date);
    }

    if (typeof date === "number") {
      date = new Date(date);
    }

    if (typeof date === "object") {
      if (date instanceof Date) {
        return date.getTime();
      }
    }
  }

  return 0;
};

GO_DATE.fixZeros = function(number) {
  return Number(number) < 10 ? "0" + number : number;
};

GO_DATE.date_locale = function(date, lang = "en") {
  if (Go.is(date, "String")) {
    date = new Date(date);
  }

  const options = {
    day: "numeric",
    month: "long",
    year: "numeric",
    timeZone: "UTC",
  };

  return date.toLocaleDateString(lang, options);
};

GO_DATE.dateLocale = function(date, lang = "en") {
  return GO_DATE.date_locale(date, lang);
};

GO_DATE.time_locale = function(date, lang = "en") {
  if (Go.is(date, "String")) {
    date = new Date(date);
  }

  const options = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "UTC",
  };

  return date.toLocaleTimeString(lang, options);
};

GO_DATE.timeLocale = function(date, lang = "en") {
  return GO_DATE.time_locale(date, lang);
};

GO_DATE.getPastMonthDateRange = function(date = new Date(), format = "YYYY-MM-DD") {
  const start = new Date(date.getFullYear(), date.getMonth() - 1, 1);
  const end = new Date(date.getFullYear(), date.getMonth(), 0);

  if (format) {
    return [GO_DATE.date(start, format), GO_DATE.date(end, format)];
  }

  return [start, end];
};
