import { ifAuthenticated, ifNotAuthenticated } from "../assets/js/session";
const routes = [
  {
    path: "/login",
    component: () => import("./../components/user/userLogin.vue"),
    beforeEnter: ifNotAuthenticated,
    meta: {
      sitemap: {
        priority: 0.9,
      },
    },
    children: [
      {
        path: "/",
        component: () => import("./../components/user/userLoginForm.vue"),
      },
    ],
  },
  {
    path: "/signup",
    component: () => import("./../components/user/userLogin.vue"),
    beforeEnter: ifNotAuthenticated,
    meta: {
      sitemap: {
        priority: 0.8,
      },
    },
    children: [
      {
        path: "/",
        component: () => import("./../components/user/userRegisterForm.vue"),
      },
    ],
  },
  {
    path: "/auth",
    component: () => import("./../components/user/userAuth.vue"),
    beforeEnter: ifNotAuthenticated,
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
    },
    children: [
      {
        path: "code",
        component: () => import("./../components/user/userAuthCodeForm.vue"),
      },
      {
        path: "pass",
        component: () => import("./../components/user/userPassForm.vue"),
        meta: { requireQuery: "code", deniedTo: "/auth/code", approvedTo: "/auth/pass" },
      },
    ],
  },
  {
    path: "/user/creator",
    component: () => import("./../components/creator/Creator.vue"),
    beforeEnter: ifAuthenticated,
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
    },
  },
  {
    path: "/user/verify",
    component: () => import("./../components/verify-user/VerifyUser.vue"),
    beforeEnter: ifAuthenticated,
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
    },
  },
];

export default routes;
