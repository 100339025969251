import animations from "./ialert.animations";

const iAlert = function(options = {}) {
  this.alert = options.alert;
  this.animation = animations[options.animation] || animations["midTopIn"];
  this.animationDuration = options.animationDuration || 250;
  this.open();
};

export default iAlert;

iAlert.prototype.open = function() {
  this.animationOpen();
};

iAlert.prototype.close = function(cb) {
  this.animationClose(cb);
};

iAlert.prototype.animationOpen = function() {
  if (!this.alert) {
    return;
  }

  this.isEmpty = Go.isEmpty(this.alert);

  if (this.isEmpty) {
    return;
  }

  this.card = this.alert.querySelector("#ialert-card");

  if (!this.card) {
    return;
  }

  Go.setStyle(this.card, { transition: "all 0.3s ease-in-out", ...this.animation.from });

  this.animateCard = this.card.animate([this.animation.from, this.animation.to], {
    duration: this.animationDuration,
    easing: "ease-in-out",
    fill: "forwards",
  });

  this.animateCard.onfinish = () => {
    Go.setStyle(this.card, this.animation.to);
  };
};

iAlert.prototype.animationClose = function(cb) {
  this.animateCard = this.card.animate([this.animation.to, this.animation.from], {
    duration: this.animationDuration,
    easing: "ease-in-out",
    fill: "forwards",
  });

  this.animateCard.onfinish = async () => {
    Go.setStyle(this.card, this.animation.from);

    if (cb && Go.is(cb, "Function")) {
      cb();
    }
  };
};
