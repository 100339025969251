const Video = {};

export default Video;

Video.read = function(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function() {
      let url = URL.createObjectURL(file);
      resolve({ src: url });
    };
    reader.onerror = function(e) {
      reject(e);
    };
    reader.readAsDataURL(file);
  });
};

Video.getThumbnail = function(url) {};
