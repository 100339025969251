export default {
  readyEvents: function () {
    this.events = [];
  },
  isGlobalEvents: function () {
    if (!this.events) {
      this.readyEvents();
    }
  },
  on: function (event, callback) {
    if (Array.isArray(event)) {
      event.forEach((e) => {
        this.on(e, callback);
      });
      return;
    }

    this.isGlobalEvents();
    this.events.push({ event, callback });
  },
  emit: function (event, data) {
    this.isGlobalEvents();

    this.events.forEach((e) => {
      if (e.event === event || e.event.startsWith(event + ":")) {
        e.callback(data);
      }
    });
  },
  off: function (event) {
    this.isGlobalEvents();
    Go.removeObjectFromArray(this.events, "event", event);
  },
  once: function (event, callback) {
    this.off(event, callback);
    this.on(event, callback);
  },
};
