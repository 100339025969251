export default {
  methods: {
    PostInfo: function(post) {
      this.view({
        post: post,
        title: this.$locale["post_info"],
        class: "PostInfo menu bottom",
        component: () => import("@/components/posts/PostInfoMenu.vue"),
        closeOutside: true,
        animation: "bottomIn",
        closeOnSwipe: "down",
      });
    },
    getPostPrice: function(post) {
      let discountstatus = post.discountStatus;
      let discountPercent = post.discountPercent;
      let discountDateLimit = post.discountDateLimit;
      let isTimeLeft = this.$global.isTimeLeft(discountDateLimit);
      let netPrice = post.price;

      if (!isTimeLeft) {
        netPrice = post.price;
        return netPrice;
      }

      if (discountstatus === "on") {
        netPrice = (post.price * discountPercent) / 100;
        return post.price - netPrice;
      }

      return post.price;
    },
    isPostValidFileType: function(file) {
      let validFileTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/webp",
        "video/mpg",
        "video/mpeg",
        "video/mp4",
        "video/avi",
        "video/quicktime",
        "video/mov",
        "video/wmv",
        "video/flv",
        "video/3gp",
        "video/3gpp",
        "video/3g2",
        "video/3gpp2",
        "video/mkv",
        "video/webm",
        "video/ogg",
        "video/",
      ];
      return validFileTypes.includes(file.type);
    },
    postIsValidFile: function(file) {
      let [message] = [""];

      if (file.size > this.$locale["maxFileSize"]) {
        message += `${this.$locale["the_file"]} <div ellipsis>${file.name}</div> ${this.$locale["is_too_large"]}`;
        message += `<div padding-top>${this.$locale["max_size"]}`;
        message += `<b>${Go.number_convert(this.$locale["maxFileSize"], "bytes", "megabytes")}</b> MB</div>`;
        return { title: "Error", message };
      }

      if (!this.isPostValidFileType(file)) {
        message += `<div>${this.$locale["the_file"]} <div ellipsis>${file.name}</div></div>`;
        message += `<div dcolor>${this.$locale["is_not_supported"]}</div>`;
        return { title: "Error", message };
      }

      return { success: true, message: null };
    },
  },
};
