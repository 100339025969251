const parseJwt = (nametkn) => {
  const token = localStorage.getItem(nametkn);
  if (token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }
};

const requireRoles = (require, { some }) => {
  const user = parseJwt(process.env.VUE_APP_TOKEN_NAME);
  const userRoles = user && user?.roles;
  const isThreshold = (currentRole) => userRoles.includes(currentRole);
  if (userRoles && some) {
    const isRoles = require.some(isThreshold);
    return isRoles;
  } else if (userRoles) {
    const isRoles = require.every(isThreshold);
    return isRoles;
  }
  return false;
};

const ifAuthenticated = async (to, from, next) => {
  const user = parseJwt(process.env.VUE_APP_TOKEN_NAME);
  const isUser = user && user.name;

  if (to.meta.requireSomeRoles && isUser) {
    if (requireRoles(to.meta.requireSomeRoles, { some: true })) {
      return next();
    } else {
      return next(to.meta.deniedTo ? to.meta.deniedTo : "/");
    }
  }

  if (to.meta.requireRoles && isUser) {
    if (requireRoles(to.meta.requireRoles, { some: false })) {
      return next();
    } else {
      return next(to.meta.deniedTo ? to.meta.deniedTo : "/");
    }
  }

  if (isUser) {
    return next();
  } else {
    to.meta.fullPath = to.fullPath;
    localStorage.setItem("meta-nav", JSON.stringify(to.meta));
    return next(to.meta.deniedTo ? to.meta.deniedTo : "/login");
  }
};

const ifNotAuthenticated = async (to, from, next) => {
  const user = parseJwt(process.env.VUE_APP_TOKEN_NAME);
  const isUser = user && user.name;
  if (to.meta?.requireQuery && !to.query[to.meta.requireQuery]) {
    return next(to.meta.deniedTo);
  } else if (isUser) {
    return next(to.meta.deniedTo);
  } else {
    return next();
  }
};

const isSession = () => {
  const user = parseJwt(process.env.VUE_APP_TOKEN_NAME);
  if (user && user.name) {
    return true;
  }
  return false;
};

module.exports = { ifAuthenticated, ifNotAuthenticated, parseJwt, isSession };
