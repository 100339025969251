import Methods from "./go.component.methods.js";

const Events = function() {
  this.events = [];
};

export default { events: Events };

Object.assign(Events.prototype, Methods);

Events.prototype.on = function(event, callback) {
  if (Array.isArray(event)) {
    event.forEach((e) => {
      this.on(e, callback);
    });
    return;
  }

  this.events.push({ event, callback });
};

Events.prototype.off = function(event) {
  this.events = this.events.filter((e) => e.event !== event);
};

Events.prototype.when = function(event, callback) {
  this.once(event, (data) => {
    callback(data);
  });
};

Events.prototype.once = function(event, callback) {
  this.on(event, (data) => {
    this.off(event);
    callback(data);
  });
};

Events.prototype.emit = function(event, data) {
  this.trigger(event, data);
};

Events.prototype.trigger = function(event, data) {
  this.events.forEach((e) => {
    if (e.event === event) {
      e.callback(data);
    }
  });
};

Events.prototype.clear = function() {
  this.events = [];
};

Events.prototype.clean = function() {
  this.clear();
};
