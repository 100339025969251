export default {
  state: () => ({
    home: {},
    sales: {},
    verifications: [],
    statistics: {},
    bags: {
      items: [],
      statistics: {},
    },
    fans: {
      items: [],
      statistics: {},
    },
    profiles: {
      items: [],
      statistics: {},
    },
    AdminMenuMobile: false,
    DataApplyCreatorComplete: false,
  }),
  mutations: {
    setAdminVerifications: async function(state, { verifications, statistics }) {
      if (statistics.page === 1) {
        state.verifications = verifications;
        state.statistics = statistics;
      } else if (statistics.page > 1) {
        state.verifications.push(...verifications);
        state.statistics.page = statistics.page;
      }
      state.statistics.hasMore = state.statistics.total > statistics.page * statistics.limit;
    },
    setProfileVerifications: async function(state, { items, statistics }) {
      if (statistics.page === 1) {
        state.profiles.items = items;
        state.profiles.statistics = statistics;
      } else if (statistics.page > 1) {
        state.profiles.items.push(...items);
        state.profiles.items.statistics.page = statistics.page;
      }

      state.profiles.statistics.hasMore = state.profiles.statistics.total > statistics.page * statistics.limit;
    },
  },
};
