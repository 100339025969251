export default {
  getExtensionFromFilename: function(filename, ext = "") {
    if (!filename) return;

    ext = filename.split(".").pop();
    ext = ext.split("?")[0];
    return ext;
  },
  isImage: function(filename) {
    let ext = this.getExtensionFromFilename(filename);
    return ["jpg", "jpeg", "png", "gif", "bmp"].includes(ext);
  },
  isVideo: function(filename) {
    let ext = this.getExtensionFromFilename(filename);
    return ["mp4", "webm", "ogg", "ogv", "mov", "avi", "wmv", "flv", "mkv"].includes(ext);
  },
};
