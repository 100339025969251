const Array = {};

export default Array;

Array.arrayGroupOf = function(number, _array, model) {
  // create group of number of elements in _array and return array of groups
  // example: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] => [[1, 2, 3, 4], [5, 6, 7, 8], [9, 10]]
  let array = _array.slice();
  let groups = [];
  while (array.length > 0) {
    if (model) {
      model.id = Go.uuid();
      groups.push({ ...model, items: array.splice(0, number) });
    } else {
      groups.push(array.splice(0, number));
    }
  }
  return groups;
};

Array.findIndex = function(array, value) {
  // find index of value in array
  let index = -1;
  for (let i = 0; i < array.length; i++) {
    if (array[i] === value) {
      index = i;
      break;
    }
  }
  return index;
};

Array.findObjectIndex = function(array, key, value) {
  // find index of object in array by key and value
  let index = -1;
  for (let i = 0; i < array.length; i++) {
    //if (array[i][key] === value) {
    if (Go.getProperty([key], array[i]) === value) {
      index = i;
      break;
    }
  }
  return index;
};

Array.arrayReverse = function(array) {
  // reverse array
  let newArray = [];
  for (let i = array.length - 1; i >= 0; i--) {
    newArray.push(array[i]);
  }
  return newArray;
};

Array.arrayRemoveDuplicates = function(array) {
  // remove duplicates from array
  let newArray = [];
  for (let i = 0; i < array.length; i++) {
    if (newArray.indexOf(array[i]) === -1) {
      newArray.push(array[i]);
    }
  }
  return newArray;
};

Array.arrayShuffle = function(array) {
  // shuffle array
  let newArray = array.slice();
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
};

Array.pushOnPosition = function(array, value, position) {
  // push value on position in array
  position = array.length - 1 - position;

  if (position < 0) {
    position = 0;
  }

  if (position > array.length) {
    position = array.length;
  }

  array.splice(position, 0, value);
};

Array.pushOn = function(array, value, position) {
  return Array.pushOnPosition(array, value, position);
};

Array.pushOnEnd = function(array, value) {
  return Array.pushOnPosition(array, value, 0);
};

Array.pushOnStart = function(array, value) {
  return Array.pushOnPosition(array, value, array.length);
};

Array.pushOnMiddle = function(array, value) {
  return Array.pushOnPosition(array, value, array.length / 2);
};
