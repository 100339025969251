import socket from "../api/socket";

const Counter = {
  state: () => ({
    documentsByVerify: 0,
    profilesByVerify: 0,
    creatorsByVerify: 0,
    complaintsByVerify: 0,
    messagesUnread: 0,
    notificationsUnread: 0,
    postsByReview: 0,
    storiesByReview: 0,
  }),
  actions: {},
  mutations: {},
};

export default Counter;

Counter.mutations.setCounter = async function(state, data) {
  if (!Go.is(data, "Object")) {
    return;
  }

  Object.keys(data.counter).forEach((key) => {
    state[key] = data.counter[key];
  });
};

Counter.actions.listenCounter = function({ commit, state }) {
  socket.io.on("counter", (data) => {
    commit("setCounter", data);
  });
};
