const Error = {};

export default Error;

Error.isError = function(error, codeNumber) {
  if (!error) return false;

  if (!codeNumber) return false;

  if (error.toString().includes(`with status code ${codeNumber}`)) {
    return true;
  }

  return false;
};

Error.getErrorMessage = function(error) {
  if (!error) return "";

  if (error.message) {
    return error.message;
  }

  if (error.response && error.response.data && error.response.data.message) {
    return error.response.data.message;
  }

  return error.toString();
};
