module.exports = {
  getID: function() {
    function chr4() {
      return Math.random()
        .toString(16)
        .slice(-4);
    }
    return chr4() + chr4() + "-" + chr4() + "-" + chr4() + "-" + chr4() + "-" + chr4() + chr4() + chr4();
  },
  getButton: function(button) {
    const buttons = {
      accept: `<a action="accept">Aceptar</a>`,
      cancel: `<a action="cancel">Cancelar</a>`,
      retry: `<a action="retry">Cancelar</a>`,
    };
    return buttons[button];
  },
  getCustomAlert: function(alertID, options) {
    const cancelBtn = options?.type === "confirm" ? this.getButton("cancel") : "";
    const acceptBtn = this.getButton("accept");
    const retryBtn = this.getButton("retry");
    let [optionsButtons, alertIcon] = [`${cancelBtn}${acceptBtn}`, options?.icon];

    if (options?.buttons?.length) {
      optionsButtons = "";
      options.buttons.forEach((button) => (optionsButtons += this.getButton(button)));
    }

    if (options?.type === "notification") {
      optionsButtons = "";
    }

    if (alertIcon) {
      alertIcon = alertIcon === "success" ? `<success><i class="gg-check-o"></i></success>` : alertIcon;
      alertIcon = alertIcon === "error" ? `<error><i class="gg-close-o"></i></error>` : alertIcon;
      alertIcon = alertIcon === "danger" ? `<danger><i class="gg-danger"></i></danger>` : alertIcon;
    }

    const alertHeader = `<div class="ialert-card-header">
      <div class="ialert-card-header-title">
      ${alertIcon ? `<figure class="ialert-card-icon">${alertIcon}</figure>` : ``}
      <b>${options?.title}</b>
      </div>
      <div class="ialert-card-header-options">
        <a class="button" action="close"><i class="gg-close"></i></a>
      </div>
    </div>`;

    const alertHtml = `<div id="ialert-card" class="ialert-card">
      ${alertHeader}
      <div class="ialert-card-message">
        ${typeof options === "string" ? options : options?.message}
      </div>
      ${options.html ? `<div class="ialert-card-html">${options.html}</div>` : ""}
      <div class="ialert-card-ptions">${optionsButtons}</div>
    </div>`;

    return alertHtml;
  },
  getCustomLoader: function(alertID, options) {
    let alertHtml = `<div class="ialert-loader">
      <div class="showbox">
        <div class="loader">
          <svg class="circular" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="${options?.fill ?? "none"}" stroke-width="2" stroke-miterlimit="10"/>
          </svg>
        </div>
        <div>${options?.message ?? ""}</div>
      </div>
    </div>`;

    if (options?.loader === "default") {
      alertHtml = `<div class="default loading"></div>`;
    }

    return alertHtml;
  },
  closeAll: function(alertID, options) {
    Object.keys(options.currentAlerts).forEach((alert) => {
      if (alertID !== alert) {
        this.destroy(alert);
      }
    });
  },
  destroy: function(alertID) {
    const myBody = document.querySelector("body");
    const alertElm = document.querySelector(`#ialert-${alertID}`);
    alertElm?.remove();
    myBody.classList.remove(`ialert-${alertID}`);
  },
};
