export default {
  methods: {
    goToPaySubscription: async function({ creator, priceBy, price }) {
      let payUrl = `https://secure2.segpay.com/billing/poset.cgi?x-eticketid=201702:29055`;
      payUrl += `&amount=${price}`;
      payUrl += `&DynamicPricingID=${creator.subscriptions && creator.subscriptions.DynamicPricingID}`;
      payUrl += `&paypagelanguage=${this.uppercase(this.$lang)}`;
      payUrl += `&x-billemail=${this.$user.email}`;
      payUrl += `&x-billname=${this.$user.name}`;
      payUrl += `&x-billcntry=${this.$user.country}`;
      payUrl += `&x-auth-link=https://www.myfans1a.com/${creator.user}`;
      payUrl += `&x-decl-link=https://www.myfans1a.com${this.$links["my-credits"]}`;
      payUrl += `&x-price=${price}`;
      payUrl += `&whitesite=www.myfans1a.com/${this.lower(creator.user)}`;
      payUrl += `&creator=${creator._id}`;
      payUrl += `&priceby=${priceBy}`;
      payUrl += `&userid=${this.$user.id}`;
      payUrl += `&dynamicdesc=${this.$locale["subscribe_to"]} @${creator.user} ${this.$locale["for"]} $ ${price} ${this.$locale["monthly"]}`;
      top.location.href = payUrl;
    },
    goToPayPackage: async function(item) {
      let payUrl = `https://secure2.segpay.com/billing/poset.cgi?x-eticketid=${item.eticketid}`;
      payUrl += `&paypagelanguage=${this.uppercase(this.$lang)}`;
      payUrl += `&x-billemail=${this.$user.email}`;
      payUrl += `&x-billname=${this.$user.name}`;
      payUrl += `&x-billcntry=${this.$user.country}`;
      payUrl += `&x-auth-link=https://www.myfans1a.com${this.$links["my-credits"]}`;
      payUrl += `&x-decl-link=https://www.myfans1a.com${this.$links["my-credits"]}`;
      payUrl += `&userid=${this.$user.id}`;
      top.location.href = payUrl;
    },
  },
};
