<template>
  <i :class="`iconic ${mapClass()}`" :style="`${setSize()}`" v-if="name" v-html="`${myIcon(name)}`"></i>
</template>

<script>
import icons from "./icons";
export default {
  name: "iconic",
  props: {
    name: String,
    color: { type: String, default: "currentColor" },
    options: { type: Object, default: () => ({}) },
    size: String,
    original: { type: Boolean, default: false },
  },
  data: function() {
    return {
      icons: icons,
    };
  },
  methods: {
    setSize: function() {
      if (this.size) {
        return `font-size:${this.size}`;
      }
    },
    mapClass: function() {
      let $class = "";
      if (this.original || this.options.original) {
        $class += "original";
      }
      return $class;
    },
    evaluate: function(svg) {
      if (!svg) return;
      let elsvg = document.createElement("div");
      elsvg.innerHTML = svg;
      const nodes = elsvg.querySelectorAll("*");
      let color = this.color || "currentColor";
      nodes.forEach((node) => {
        const haveFill = node.hasAttribute("fill") && node.getAttribute("fill") !== "none";
        const isVarFill = node.hasAttribute("fill") && node.getAttribute("fill").includes("var(");

        if (haveFill && !isVarFill) {
          node.setAttribute("fill", this.color);
        }

        if (node.tagName === "path" && !node.hasAttribute("fill")) {
          node.setAttribute("fill", color);
        }

        if (node.hasAttribute("stroke")) {
          node.setAttribute("stroke", this.color);
        }
      });
      const html = Array.prototype.reduce.call(
        nodes,
        function(html, node, count) {
          count++;
          if (count === 1) {
            html = node.outerHTML || node.nodeValue;
          }
          return html;
        },
        ""
      );
      return html;
    },
    myIcon: function(name) {
      const theicon = this.evaluate(icons(name));
      return theicon ?? "?";
    },
  },
};
</script>

<style lang="scss">
.iconic {
  &:not(.original) {
    font-style: normal;
    line-height: 1em;
    svg {
      width: 1em;
      height: 1em;
      vertical-align: middle;
    }
  }
}
</style>
