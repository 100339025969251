export default {
  bind: function(el, { value }, vnode) {
    if (value && Go.is(value, "Array")) {
      el.style.paddingTop = value[0];
      el.style.paddingRight = value[1];
      el.style.paddingBottom = value[2];
      el.style.paddingLeft = value[3];
    }
  },
};
