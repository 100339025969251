export default {
  scroll: {
    timeout: null,
  },
  watchScrolling: function(options = {}) {
    window.removeEventListener("scroll", (e) => this.isScrolling(e, options));
    window.addEventListener("scroll", (e) => this.isScrolling(e, options));
  },
  isScrolling: function({ srcElement }, { state }) {
    const offsetGap = 64;
    const { scrollTop, offsetHeight, scrollHeight } = srcElement.scrollingElement;
    const scrolledToBottom = scrollTop + offsetHeight + offsetGap >= scrollHeight;
    state.body.scrollTop = scrollTop;
    state.body.scrollHeight = scrollHeight;
    this.scrollDirection({ srcElement }, { state });
    if (scrolledToBottom) {
      this.triggerOnBottom();
    }
  },
  triggerOnBottom: function() {
    const offsetGap = 200;
    if (window.innerHeight + window.scrollY + offsetGap >= document.body.scrollHeight) {
      clearTimeout(this.scroll.timeout);
      this.scroll.timeout = setTimeout(() => {
        let elems = document.querySelectorAll(".click-on-bottom");
        [].forEach.call(elems, function(el) {
          if (el) el.click();
        });
      }, 100);
    }
  },
  isBottomScroll: function({ srcElement, offsetGap = 0 }, callback) {
    const { scrollTop, offsetHeight, scrollHeight } = srcElement;
    const scrolledToBottom = scrollTop + offsetHeight + offsetGap >= scrollHeight;
    if (scrolledToBottom) {
      if (callback) callback(srcElement);
    }
  },
  isTopScroll: function({ srcElement, offsetGap = 0 }, callback) {
    const { scrollTop } = srcElement;
    if (scrollTop <= offsetGap) {
      if (callback) callback(srcElement);
    }
  },
  scrollDirection: function({ srcElement }, { state }) {
    const currentScrollPosition = window.pageYOffset;

    if (currentScrollPosition > state.body.lastScrollTop) {
      state.body.scrollDirection = "down";
    } else {
      state.body.scrollDirection = "up";
    }

    state.body.lastScrollTop = window.pageYOffset;

    if (state.screen.width <= state.screen.mobile.end && state.body.scrollTop >= state.headerHeight) {
      if (state.body.scrollDirection === "down") {
        state.headerClass = "scrollinDown";
      } else {
        state.headerClass = "scrollinUp";
      }
    } else {
      state.headerClass = "";
    }
  },
};
