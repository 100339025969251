export default {
  home: "/home",
  "my-settings": "/?feed=my-profile&view=user",
  "my-profile": "/?feed=my-profile&view=user",
  "my-credits": "/?feed=my-credits&view=user",
  "/user/credits": "/?feed=my-credits&view=user",
  "my-subscriptions": "/?feed=my-subscriptions&view=user",
  "my-subscribers": "/?feed=my-subscribers&view=creator",
  "my-favorites": "/?feed=my_content&content=all&view=content",
  "my-transactions": "/?feed=my-transactions&view=user",
  "my-notifications": "/?feed=my-notifications&view=user",
  "my-messages": "/?feed=my-messages&messages=wall",
  "my-credits-history": "/?feed=my-credits-history&view=user",
  "creator-statistics": "/?feed=creator-statistics&view=creator",
  developer: "/?feed=developer&view=user",
  wiki: "/?feed=wiki&view=wiki",
  gallery: "/?feed=gallery",
  "my-content": "/?feed=my_content&content=all",
  "my-saved-content": "/?feed=my_content&content=saved",
  "my-bought-content": "/?feed=my_content&content=bought",
  watch: "/watch",
};
