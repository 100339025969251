export default {
  cleanHTML: function(text) {
    let fisrtNodeTag = null;
    const htmlData = document.createElement("div");
    htmlData.innerHTML = text;
    const elements = htmlData.querySelectorAll("*");

    if (!elements || !elements.length) {
      return text;
    }

    if (!text.startsWith("<")) {
      let firsNodeText = text.split("<")[0];
      let firsNode = document.createElement("span");
      firsNode.innerHTML = firsNodeText;
      fisrtNodeTag = `<span>${firsNodeText}</span>`;
    }

    elements.forEach((element) => {
      this.removeNodeAttributes(element);
    });

    const html = Array.prototype.reduce.call(
      elements,
      function(html, node, count) {
        count++;
        if (count === 1) {
          if (fisrtNodeTag) {
            html = fisrtNodeTag + node.outerHTML || node.nodeValue;
          } else {
            html = node.outerHTML || node.nodeValue;
          }
        }
        return html;
      },
      ""
    );
    return html;
  },
  removeNodeAttributes: function(node) {
    const attributes = node.attributes;

    if (!attributes || !attributes.length) {
      return node;
    }

    for (let i = 0; i < attributes.length; i++) {
      node.removeAttribute(attributes[i].name);
    }

    return node;
  },
};
