export default {
  state: () => ({
    home: {},
    sales: {},
    creators: [],
    statistics: {},
    bags: {
      items: [],
      statistics: {},
    },
    fans: {
      items: [],
      statistics: {},
    },
    users: {
      items: [],
      statistics: {},
    },
    AdminMenuMobile: false,
    salesByCreatorList: {
      items: [],
      statistics: {},
    },
  }),
  mutations: {
    setAdminHomeStatistics: async function(state, { statistics }) {
      state.home = statistics;
    },
    setAdminSalesStatistics: async function(state, { statistics }) {
      state.sales = statistics;
    },
    setAdminCreatorsStatistics: async function(state, { creators, statistics }) {
      if (statistics.page === 1) {
        state.creators = creators;
        state.statistics = statistics;
      } else if (statistics.page > 1) {
        state.creators.push(...creators);
        state.statistics.page = statistics.page;
      }
      state.statistics.hasMore = state.statistics.totalCreators > statistics.page * statistics.limit;
    },
    setAdminBagsStatistics: async function(state, { bags, statistics }) {
      if (statistics.page === 1) {
        state.bags.items = bags;
        state.bags.statistics = statistics;
      } else if (statistics.page > 1) {
        state.bags.items.push(...bags);
        state.bags.statistics.page = statistics.page;
      }
      state.bags.statistics.hasMore = state.bags.statistics.totalItems > statistics.page * statistics.limit;
    },
    setAdminFansStatistics: async function(state, { fans, statistics }) {
      if (statistics.page === 1) {
        state.fans.items = fans;
        state.fans.statistics = statistics;
      } else if (statistics.page > 1) {
        state.fans.items.push(...fans);
        state.fans.statistics.page = statistics.page;
      }
      state.fans.statistics.hasMore = state.fans.statistics.totalItems > statistics.page * statistics.limit;
    },
    setAdminUsersStatistics: async function(state, { users, statistics }) {
      if (statistics.page === 1) {
        state.users.items = users;
        state.users.statistics = statistics;
      } else if (statistics.page > 1) {
        state.users.items.push(...users);
        state.users.statistics.page = statistics.page;
      }
      state.users.statistics.hasMore = state.users.statistics.totalItems > statistics.page * statistics.limit;
    },
    AdminMenuMobile: function(state, AdminMenuMobile) {
      state.AdminMenuMobile = AdminMenuMobile;
    },
  },
};
