const colors = require("./colors");

const App = function() {
  this.version = "1.0.0";
  this.colors = colors;
  this.init();
};

App.prototype.init = function() {
  Object.keys(this.colors).forEach((key) => {
    if (Go.is(this.colors[key], "String")) {
      document.body.style.setProperty(`--color-${key}`, this.colors[key]);
    }
  });
};

module.exports = new App();
