import api from "@/api";
import { isSession } from "./session";
import Response from "./connection.response";

const Connection = function() {
  this.host = `${process.env.VUE_APP_API_HOST}report/connection`;
  this.interval = 1000 * 60;
  this.time = new Date().getTime();
};

Connection.prototype.report = async function() {
  if (!isSession()) return;
  this.con = await api.post(this.host, { time: this.time });
  Response.success(this.con.data);
};

Connection.prototype.start = function() {
  setInterval(this.report.bind(this), this.interval);
};

export default new Connection();
