const Files = {};

export default Files;

Files.getExtensionFromPath = function(path) {
  return path.split(".").pop();
};

Files.base64ToFile = function(base64, filename) {
  var arr = base64.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};
