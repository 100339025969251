module.exports = {
  subscriptions: "#bd0909",
  content: "#e31daa",
  gifts: "#f5b30c",
  flowers: "#bd0909",
  tips: "#4970d9",
  dollars: "#009e42",
  bags: {
    "250": "#bd0909",
    "150": "#f5b30c",
    "50": "#4970d9",
  },
};
