import dictionary from "./dictionary";
import misc from "./misc";
import html from "./html";
import links from "./links";
import cloudfront from "./cloudfront";

export default {
  ...misc,
  ...html,
  links,
  mpadding: "15px",
  cloudfront: cloudfront.cloudfront,
  toggleClass: (el, clss) => {
    if (typeof el === "object") {
      el = document.querySelector(el.el);
      clss = el.class;
    } else {
      el = document.querySelector(el);
    }
    el.classList.toggle(clss);
  },
  dateLocaleFormat: (date) => {
    date = new Date(date);
    const ulang = localStorage.getItem(process.env.VUE_APP_LANG_KEY)?.substring(0, 2) || navigator.language.substring(0, 2);
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      timeZone: "UTC",
    };
    return date.toLocaleDateString(ulang, options);
  },
  timeAMPM: (time) => {
    const myTime = time.split(":");
    let hours = myTime[0];
    let minutes = myTime[1];
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  },
  monthName: function(date) {
    date = new Date(date);
    const ulang = localStorage.getItem(process.env.VUE_APP_LANG_KEY)?.substring(0, 2) || navigator.language.substring(0, 2);
    const options = {
      month: "long",
    };
    return date.toLocaleDateString(ulang, options);
  },
  toInputDate: function(date) {
    if (date && String(date)?.includes("T")) {
      date = date.split("T")[0];
    }
    return date;
  },
  toInputDateTime: function(date) {
    if (date && String(date)?.includes("Z")) {
      date = date.split("Z")[0];
    }
    return date;
  },
  yearsDiff: (d1, d2) => {
    let yearsDiff = new Date(new Date(d2) - new Date(d1)).getFullYear() - 1970;
    return yearsDiff;
  },
  getCurrentYear: function() {
    const d = new Date();
    return d.getFullYear();
  },
  getMainImg: (data) => {
    var found = data.find(function(element) {
      if (element.isMain === true) return element;
    });
    if (found) {
      return found.link;
    }
  },
  getMainlang: (data, lang) => {
    var found = data.find(function(element) {
      if (element.languageId === lang) return element;
    });
    if (found) {
      return found;
    }
  },
  validEmail: (email) => {
    if (/^[^@ ]+@[^@ ]+\.[^@ \.]{2,}$/.test(email)) {
      return true;
    } else {
      return false;
    }
  },
  validPhone: (phone) => {
    if (/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/.test(phone)) {
      return true;
    } else {
      return false;
    }
  },
  validUserName: function(user) {
    if (!user) return false;
    if (user && user.includes(" ")) return false;
    if (user.length < 6) return false;
    if (user.length > 20) return false;
    if (this.specialChars(user)) return false;
    return user;
  },
  specialChars: function(string) {
    const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (format.test(string)) {
      return true;
    } else {
      return false;
    }
  },
  getShort: function(text, limit) {
    let ellipsis = text.length > limit ? "..." : "";
    text = text.replace(/(<([^>]+)>)/gi, " ");
    return typeof text !== "string" ? text : text.substring(0, limit) + ellipsis;
  },
  isMoreText: function(text, limit, callback) {
    const newText = this.getShort(text, limit);
    callback(newText);
  },
  linkIsActive: ($route, input) => {
    const paths = Array.isArray(input) ? input : [input];
    return paths.some((path) => {
      return $route.fullPath.indexOf(path) === 0; // current path starts with this path string
    });
  },
  hasClass: function(element, className) {
    return (" " + element.className + " ").indexOf(" " + className + " ") > -1;
  },
  linkInclude: ($route, input) => {
    if ($route && input) {
      if ($route.fullPath.includes(input)) {
        return true;
      }
    }
    return false;
  },
  scrollToEl: (data) => {
    let el = document.querySelector(data.el);
    let y = el.offsetTop + -data.topgap;
    window.scroll({ top: y, behavior: data.behavior });
  },
  scrollElToEl: (data) => {
    let el = document.querySelector(data.el);
    let to = document.querySelector(data.to);
    let y = to.offsetTop + -data.topgap;
    el.scroll({ top: y, behavior: data.behavior });
  },
  fastID: () => {
    function chr4() {
      return Math.random()
        .toString(16)
        .slice(-4);
    }
    return chr4() + chr4() + "-" + chr4() + "-" + chr4() + "-" + chr4() + "-" + chr4() + chr4() + chr4();
  },
  hasSome: function(data1, data2) {
    if (typeof data1 === "object" && typeof data2 === "object") {
      return data1.some((some) => data2.includes(some));
    }
  },
  dictionary: {
    ...dictionary.dictionary,
  },
  loading: (elm) => {
    const mylmnt = document.querySelector(elm);
    if (mylmnt) mylmnt.classList.add("loading");
  },
  unloading: (elm) => {
    const mylmnt = document.querySelector(elm);
    if (mylmnt) mylmnt.classList.remove("loading");
  },
  objectIndex: (arr, key, val) => {
    return arr.findIndex((obj) => obj[key] === val);
  },
  setAttribute: (element, attribute, value) => {
    const selelems = document.querySelectorAll(element);
    [].forEach.call(selelems, function(el) {
      el.setAttribute(attribute, value);
    });
  },
  replaceAll: function(string, search, replace) {
    return string.split(search).join(replace);
  },
  getApp: function(appKey) {
    const apps = this.dictionary.apps_icons;
    const app = apps.filter((a) => a.key === appKey);
    return app[0];
  },
  getHost: function(url) {
    const theurl = new URL(url);
    return theurl.origin;
  },
  removeCharAt: function(pos, string) {
    if (pos && string) {
      string = string.substring(pos);
    }
    return string;
  },
  useSemantic: function(htmlString) {
    const htmlData = document.createElement("div");
    htmlData.innerHTML = htmlString;
    const elements = htmlData.querySelectorAll("*");
    elements.forEach((element) => element.classList.add("semantic"));
    const html = Array.prototype.reduce.call(
      elements,
      function(html, node, count) {
        count++;
        if (count === 1) {
          html = node.outerHTML || node.nodeValue;
        }
        return html;
      },
      "",
    );
    return html;
  },
  number_format: function(number, decimals, dec_point, thousands_sep) {
    return Go.number_format(number, decimals, dec_point, thousands_sep);
  },
  mediaType: function(type) {
    if (type) {
      if (type.includes("image")) return "image";
      if (type.includes("video")) return "video";
    }
  },
  msToDhms: function({ ms }) {
    const days = Math.floor(ms / (24 * 60 * 60 * 1000));
    const daysms = ms % (24 * 60 * 60 * 1000);
    const hours = Math.floor(daysms / (60 * 60 * 1000));
    const hoursms = ms % (60 * 60 * 1000);
    const minutes = Math.floor(hoursms / (60 * 1000));
    const minutesms = ms % (60 * 1000);
    const seconds = Math.floor(minutesms / 1000);
    return { days, hours, minutes, seconds, total: ms };
  },
  dateDiff: function(from, to) {
    from = String(from)?.split(".")[0];
    to = String(to)?.split(".")[0];

    const date1 = new Date(from);
    const date2 = new Date(to);

    const dateFrom = date1.setTime(date1.getTime() + date1.getTimezoneOffset() * 60 * 1000);
    const dateTo = date2.setTime(date2.getTime() + date2.getTimezoneOffset() * 60 * 1000);

    const diffTime = Math.abs(dateTo - dateFrom);
    return this.msToDhms({ ms: diffTime });
  },
  toClipboard: function(text, callback) {
    const fallback = () => {
      const isIos = navigator.userAgent.match(/ipad|iphone/i);
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);

      if (isIos) {
        const range = document.createRange();
        range.selectNodeContents(textarea);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textarea.setSelectionRange(0, 999999);
      } else {
        textarea.select();
      }

      document.execCommand("copy");
      document.body.removeChild(textarea);
      if (callback) callback({ copied: text });
    };

    if (!navigator.clipboard) {
      fallback(text);
      return;
    }

    navigator.clipboard.writeText(text);
    if (callback) callback({ copied: text });
  },
  normalizeString: function(data = "") {
    let strToNormalize = data;
    strToNormalize = strToNormalize.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    strToNormalize = strToNormalize.toLocaleLowerCase();
    strToNormalize = strToNormalize.split(" ").join("");
    return strToNormalize;
  },
  isTimeLeft: function(date) {
    const dateTo = new Date(date).getTime();
    const currentDate = new Date().getTime();
    return currentDate <= dateTo;
  },
  isObject: function(obj) {
    return obj === Object(obj);
  },
  queryToObject: function(query) {
    const queryObject = {};
    const queryArray = query.split("&");
    queryArray.forEach((item) => {
      const [key, value] = item.split("=");
      queryObject[key] = value;
    });
    return queryObject;
  },
  randomColor: function() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  },
  sleep: (timeout) => new Promise((resolve) => setTimeout(resolve, timeout)),
  phone_format: function(phone) {
    if (phone) {
      phone = phone.replace(/[^0-9+\-Ee.]/g, "");
      if (phone.length === 10) {
        return `${phone.substring(0, 3)}-${phone.substring(3, 6)}-${phone.substring(6, 10)}`;
      }
      if (phone.length === 11) {
        return `${phone.substring(0, 1)}-${phone.substring(1, 4)}-${phone.substring(4, 7)}-${phone.substring(7, 11)}`;
      }
    }
  },
};
