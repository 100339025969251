const Misc = {};

export default Misc;

Misc.is = function(element, type) {
  if (Go.lower(type) === "string") {
    return typeof element === "string";
  }

  if (typeof type === "string") {
    try {
      type = eval(Go.capitalize(type));
    } catch (error) {
      return false;
    }
  }

  if (!type) {
    return Misc.detectType(element);
  }

  return element instanceof type;
};

Misc.detectType = function(element) {
  if (element === null) return "null";
  if (element === undefined) return "undefined";
  if (element === window) return "window";
  if (element === document) return "document";
  if (element === document.body) return "body";
  if (element === document.documentElement) return "html";
  if (element instanceof Element) return "Element";
  if (element instanceof HTMLCollection) return "HTMLCollection";
  if (element instanceof NodeList) return "NodeList";
  if (element instanceof Array) return "Array";
  if (element instanceof Object) return "Object";
  if (element instanceof String) return "String";
  if (element instanceof Number) return "Number";
  if (element instanceof Boolean) return "Boolean";
  if (element instanceof Function) return "Function";
  if (element instanceof Date) return "Date";
  if (element instanceof RegExp) return "RegExp";
  if (element instanceof Error) return "Error";
  if (element instanceof Promise) return "Promise";
  if (element instanceof Map) return "Map";
  if (element instanceof Set) return "Set";
  if (element instanceof WeakMap) return "WeakMap";
  if (element instanceof WeakSet) return "WeakSet";
  if (element instanceof Symbol) return "Symbol";
  if (element instanceof ArrayBuffer) return "ArrayBuffer";
  if (element instanceof SharedArrayBuffer) return "SharedArrayBuffer";
  if (element instanceof Atomics) return "Atomics";
  if (element instanceof DataView) return "DataView";
  if (element instanceof JSON) return "JSON";
  if (element instanceof Math) return "Math";
  if (element instanceof Reflect) return "Reflect";
  if (element instanceof Intl) return "Intl";
  if (element instanceof NodeList) return "NodeList";
  return typeof element;
};

Misc.isFalsy = function(val) {
  if (!val) return true;
  if (val === "false") return true;
  if (val === "null") return true;
  if (val === "undefined") return true;
  if (val === "NaN") return true;
  if (val === "0") return true;
  if (val === 0) return true;
  if (val === false) return true;
  if (val === null) return true;
  if (val === undefined) return true;
  if (val === NaN) return true;
  return false;
};

Misc.isTruthy = function(val) {
  return !Misc.isFalsy(val);
};

Misc.isFalse = function(val) {
  if (val === "false") return true;
  if (val === false) return true;
  return false;
};
