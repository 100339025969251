import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import api from "@/api";
import scroller from "../assets/js/scroller";
import colors from "../assets/js/colors";
import posts from "./posts";
import visibility from "./visibility";
import performance from "./performance";
import comments from "./comments";
import messages from "./messages";
import likes from "./likes";
import user from "./user";
import admin from "./admin";
import creator from "./creator";
import complaints from "./complaints";
import verifications from "./verifications";
import notifications from "./notifications";
import stories from "./stories";
import socketStore from "./socket";
import media from "./media";
import modal from "./modal";
import payload from "./payload";
import subscribers from "./creator.subscribers";
import progress from "./progress";
import search from "./search";
import reels from "./reels";
import credits from "./credits";
import landing from "./landing";
import log from "./log";
import app from "./app";
import counter from "./counter";
import Connection from "../assets/js/connection.worker";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    log,
    performance,
    visibility,
    comments,
    messages,
    modal,
    posts,
    likes,
    user,
    admin,
    media,
    stories,
    creator,
    creatorSubscribers: subscribers,
    complaints,
    notifications,
    verifications,
    socket: socketStore,
    payload,
    progress,
    search,
    reels,
    credits,
    landing,
    counter,
  },
  state: {
    appKey: 0,
    starting: true,
    api: true,
    lang: { default: "en", current: "en" },
    lockapp: false,
    loading: true,
    locale: null,
    subscribe: false,
    incentive: false,
    unlock: false,
    updates: false,
    feedKey: 0,
    screen: {
      width: window.innerWidth,
      height: window.innerHeight,
      mobile: { start: 0, end: 767 },
      tablet: { start: 768, end: 1024 },
      desktop: { start: 1025, end: 1920 },
      tv: { start: 1921, end: 99999 },
    },
    searching: false,
    creatorFeed: null,
    body: {
      scrollTop: 0,
      scrollHeight: 0,
      lastScrollTop: 0,
      scrollDirection: "down",
    },
    LanguageSelector: null,
    FeatureSoon: null,
    states: {},
    headerHeight: 49,
    headerClass: "",
  },
  actions: {
    startApp: async function() {
      const sedData = api.get(process.env.VUE_APP_API_HOST + "?view=home");
      sedData.then((response) => this.dispatch("apiReady", response));
      sedData.catch((response) => this.dispatch("apiError", response));
    },
    apiReady: async function({ commit, state }, response) {
      await this.dispatch("getLocale", response.data);
      await this.dispatch("getProfile");
      commit("payload", { api: true });
      this.dispatch("watchEvents");
      this.dispatch("watchVisibility");
      this.dispatch("socketListen");
    },
    apiError: async function({ commit }, response) {
      await this.dispatch("getLocale", {});
      commit("payload", { api: false });
      this.dispatch("watchEvents");
      this.dispatch("watchVisibility");
      this.state.api = false;
      this.state.loading = false;
      router.push("/error/api").catch(() => {});
    },
    appLoaded: async function({ commit, state }) {
      setTimeout(() => {
        state.api = true;
        state.loading = false;
        state.starting = false;
        document.querySelector("body").classList.remove("starting");
        document.querySelector("iframe#load")?.remove();
      }, 100);
    },
    getLocale: async function({ commit, state }, { language, landing }) {
      let userLang = localStorage.getItem(process.env.VUE_APP_LANG_KEY);
      let mylang = userLang ? userLang.substring(0, 2) : state?.lang?.default ?? navigator.language.substring(0, 2);
      const locales = await require(`../assets/langs/`)({ lang: mylang });
      locales.landing = { ...landing, ...locales.landing };
      state.locale = { ...locales, ...language, colors };
      state.lang.current = mylang;
    },
    watchEvents: function({ commit, state }) {
      scroller.watchScrolling({ state });

      document.addEventListener("click", function(e) {
        const link = e.target.getAttribute("link");
        if (e.target && link) {
          router.push(link);
        }
      });

      window.addEventListener("resize", function(e) {
        let width = window.innerWidth;
        let height = window.innerHeight;
        state.screen.width = width;
        state.screen.height = height;
      });

      const incomingMessage = ({ data: { message, msg } }) => {
        if (message === "appLoaded") {
          return this.dispatch("appLoaded");
        }
        if (msg === "updatefound") {
          this.state.updates = true;
          return;
        }
      };

      if (window.addEventListener) {
        window.addEventListener("message", incomingMessage, false);
      } else {
        window.attachEvent("onmessage", incomingMessage);
      }
    },
    socketListen: function({ commit, state, dispatch }) {
      if (state.user.user) {
        dispatch("initSocketUser", state.user.user);
        dispatch("listenComments");
        Connection.report();
      }
    },
    searchCreator: function({ commit, state }, { string, callback, error, page }) {
      const host = `${process.env.VUE_APP_API_HOST}creator/search?q=${string ? string : ""}`;
      const search = api.get(host);
      search.then((response) => {
        state.search = { ...state.search, word: string, creators: response.data.creators };
        if (callback) callback(state.search);
      });
      search.catch((err) => {
        if (error) error(err);
      });
    },
  },
  mutations: {
    loading: function(state, payload) {
      state.loading = payload;
    },
    lockapp: function(state, payload) {
      state.lockapp = payload;
    },
  },
  getters: {
    progress: (state) => state.progress,
    starting: (state) => state.starting,
    user: (state) => state.user.user,
    admin: (state) => state.admin,
    profile: (state) => state.user.profile,
    locale: (state) => state.locale,
    loading: (state) => state.loading,
    updates: (state) => state.updates,
    payload: (state) => state.payload,
    search: (state) => state.search,
    screen: (state) => state.screen,
    lockapp: (state) => state.lockapp,
    contacts: (state) => state.messages.contacts,
    messages: (state) => state.messages.messages,
    complaints: (state) => state.complaints,
    creator: (state) => state.creator,
    stories: (state) => state.stories,
    socket: (state) => state.socket,
    reels: (state) => state.reels,
    comments: (state) => state.comments,
    verifications: (state) => state.verifications,
    notifications: (state) => state.notifications,
    credits: (state) => state.credits,
    landing: (state) => state.landing,
  },
});
