// Extension of en language file

module.exports = {
  yes: "Yes",
  no: "No",
  share: "Share",
  more: "More",
  copy: "Copy",
  copy_link: "Copy link",
  success: "Success",
  error: "Error",
  "100_premium": "100% Premium",
  exclusive_content: "Exclusive Content",
  become_and_connect: "Be a creator and connect directly with your MyFans1A members",
  pick_up_your_domain: "Pick up your own domain",
  setup_your_subscription: "Set up monthly subscriptions and much more",
  become_a_creator: "Become a creator",
  turn_your_followers: "Turn your followers",
  into_paid_members: "into paid members",
  view_more_creators: "View more creators",
  profile_updated: "Your profile has been updated",
  social_networks: "Social Networks",
  full_name: "Full Name",
  required: "Required",
  country: "Country",
  profile_url: "Profile URL",
  email: "Email",
  phone: "Phone",
  birthday: "Date of Birth",
  description: "Description",
  experienceAsCreator: "Do you have experience as a content creator ?",
  areYouCurrentlyWorking: "Do you currently work as a content creator ? If yes what is the name of the platform.",
  name_of_platform: "Name of platform",
  want_add_commenst: "Do you want to add comments to your application ?",
  comments: "Comments",
  about_us: "About us",
  how_u_know_us: "How did you know about us ?",
  complete_profile: "Complete your profile",
  complete_data: "Complete the data",
  select_document_type: "Select document type",
  creators: "Creators",
  info: "Info",
  user_name: "User name",
  manage: "Manage",
  personal: "Personal",
  my_subscriptions: "My Subscriptions",
  my_credits: "My Credits",
  login: "Login",
  help_support: "Help & Support",
  help_support_dec: "If you need help, contact our support team",
  login_benefits: "Log in and connect directly with your favorite creators or followers",
  signup_benefits: "Become a creator and connect directly with your favorite followers",
  signup_label: "Sign up",
  help_label: "Help",
  favorites: "Favorites",
  messages: "Messages",
  settings: "Settings",
  contact_our_support: "Contact our support team",
  my_transactions: "My Transactions",
  developer: "Developer",
  my_notifications: "My Notifications",
  is_user_platform_exclusive: "Is a user exclusive on this platform ?",
  montly_subscriptions_desc: "Monthly subscriptions price in dollars",
  message_length_limit_excceeded: "Message length limit exceeded",
  characters_max: "characters max",
  credits: "Credits",
  credits_history: "Credits history",
  menu_label: "Menu",
  credits_reload: "Credits reload",
  miscellanous: "Miscellanous",
  account_info: "Account info",
  preferred_language: "Preferred language",
  max: "Max",
  subscribe_to: "Subscribe to",
  for: "for",
  monthly: "monthly",
  new_post: "New post",
  new_post_desc: "Create a new post",
  new_story: "New story",
  new_story_desc: "Create a new story",
  images: "Images",
  videos: "Videos",
  become_premium: "Become Premium",
  subscribe_to_watch: "Subscribe to watch all premium content",
  create_post: "Create post",
  create_story: "Create story",
  save_post: "Save post",
  save_story: "Save story",
  principal_slider: "Principal slider",
  position_on_top_creators: "Position on top creators",
  image_top_creators: "Image top creators",
  image_on_stories: "Image on stories",
  wiki: "Wiki",
  reload_credits: "Reload credits",
  buy_credits: "Buy credits",
  payout: "Payout",
  extra: "Extra",
  pay_label: "Pay",
  obtain_label: "Obtain",
  credits_package: "Credits package",
  credits_package_selected: "Credits package selected",
  unique_payment: "Unique payment",
  total: "Total",
  reload_by: "Reload by",
  credits_hisotry: "Credits history",
  no_subscriptions_message: "You don't have any subscriptions yet",
  accept_terms: "Accept terms",
  user: "User",
  delete_user: "Delete user",
  delete_user_confirm: "Are you sure you want to delete this user ?",
  delete: "Delete",
  deleted: "Deleted",
  select_period: "Select period",
  date_from: "Date from",
  date_to: "Date to",
  apply: "Apply",
  start_date: "Start date",
  end_date: "End date",
  select_start_date: "Select start date",
  select_end_date: "Select end date",
  total_posts: "Total posts",
  total_subscriptions: "Total subscriptions",
  sales: "Sales",
  total_content: "Total content",
  total_gifts: "Total gifts",
  total_flowers: "Total flowers",
  total_tips: "Total tips",
  total_credits: "Total credits",
  shortcuts: "Shortcuts",
  credits_packages: "Credits packages",
  of: "of",
  subscriptions: "Subscriptions",
  fans: "Fans",
  connection_time: "Connection time",
  time: "Time",
  last_month: "Last month",
  last_3_months: "Last 3 months",
  last_6_months: "Last 6 months",
  last_year: "Last year",
  all_time: "All time",
  appearance_on_landing: "Appearance on landing",
  appearance_on_landing_desc: `Control the appearance of the profile on the landing page`,
  user_profile: "User profile",
  user_profile_desc: `Control some features of the profile`,
  filters: "Filters",
  activated: "Activated",
  subscription_expired: "Subscription expired",
  subscription_date: "Subscription date",
  subscription_status: "Subscription status",
  subscription_period: "Subscription period",
  expired: "Expired",
  active: "Active",
  cancel_subscription: "Cancel subscription",
  renew_subscription: "Renew subscription",
  subscribe: "Subscribe",
  subscribeme: "Subscribe me",
  cancel_subscription_confirm: "Are you sure you want to cancel this subscription ?",
  home_label: "Home",
  come_back: "Come back",
  save_profile: "Save profile",
  my_credits_history: "My credits history",
  no_credits_history_message: "You don't have any credits history yet",
  laguage: "Language",
  post_info: "Post info",
  post_info_title: "This package includes:",
  date_post: "Date post",
  close_session: "Close session",
  close_session_confirm: "Are you sure you want to close this session ?",
  close_session_desc: "If you close this session, you will be logged out of all devices",
  creator: "Creator",
  creator_statistics: "Creator statistics",
  no_ubscribers_message: "You don't have any subscribers yet",
  send_message: "Send message",
  subscribe_from: "Subscribe from",
  last_activation: "Last activation",
  last_renewal: "Last renewal",
  app_instagram: "https://www.instagram.com/myfans1a/",
  total_earnings: "Total earnings",
  subscriptions_statistics: "Subscriptions statistics",
  order_by: "Order by",
  date_asc: "Date asc",
  date_asc_desc: "Sort by date ascending",
  date_desc: "Date desc",
  date_desc_desc: "Sort by date descending",
  my_unlock: "My content",
  my_gift: "My gifts",
  my_flower: "My flowers",
  my_tip: "My tips",
  nothing_found: "Nothing found",
  name_label: "Name",
  age: "Age",
  image: "Image",
  video: "Video",
  live_streaming: "Live streaming",
  video_cover: "Video cover",
  video_cover_desc: "Video to show on creator's profile",
  loading: "Loading",
  watch_video: "Watch video",
  faq_label: "FAQ",
  commission: "Commission",
  select_country: "Select country",
  select_year: "Select year",
  sales_unlock: "Sales plus unlock",
  sales_gift: "Sales gifts",
  sales_flower: "Sales flowers",
  sales_tip: "Sales tips",
  unlock_content: "Unlock content",
  unlock: "Unlock",
  unlock_content_message: "Unlock this content for just",
  go_to_post: "Go to post",
  explore_creators: "Explore creators",
  my_favorites_creators: "My favorites creators",
  susbcriptions_by_creator: "Subscriptions by creator",
  duration: "Duration",
  hours: "Hours",
  story: "Story",
  gallery_label: "Gallery",
  content_removal_label: "Content Removal",
  our_gallery: "Our gallery",
  upload_images: "Upload images",
  create_category: "Create category",
  upload_images: "Upload images",
  save_category: "Save category",
  image: "Image",
  creating_category: "Creating category",
  category_name: "Category name",
  delete_category: "Delete category",
  edit_category: "Edit category",
  select_a_category: "Select a category",
  category: "Category",
  uploaded: "Uploaded",
  delete_file: "Delete file",
  delete_confirm: "Are you sure you want to delete this?",
  delete_file_confirm: "Are you sure you want to delete this file?",
  deleting: "Deleting",
  all_galleries: "All galleries",
  remove_filter: "Remove filter",
  adjust_cover: "Adjust cover",
  save: "Save",
  adjust_image: "Adjust image",
  footer_message: `<div> <span>MyFans 1A contains adult content,</span> <span> enter ONLY if you acknowledge that you are 18 years old and agree to our&nbsp;</span> <b link="/terms">Terms and Conditions</b> <span>&nbsp;and our Data&nbsp;</span> <b link="/privacy">Privacy Policy</b> </div>`,
  view_post: "View post",
  explore: "Explore",
  creators_verification: "Creators verification",
  complaints: "Complaints",
  contain: "Contain",
  watch: "Watch",
  documents: "Documents",
  how_know_us: "How did you know us?",
  comment: "Comment",
  no_comment: "No comment",
  price_credits: "Price credits",
  price_dollars: "Price dollars",
  show_on_landing: "Show on landing",
  share_post: "Share post",
  see_more: "See more",
  get_credits: "Get credits",
  for_unlock_exclusive_content: "To unlock exclusive content",
  buy: "Buy",
  delete_account: "Delete account",
  account_settings: "Account settings",
  account_delete_confirm: `<div>Are you sure you want to delete your account?</div><div>Please type the word <i dcolor>delete</i> and then click on accept</div>`,
  type_here: "Type here",
  deleting_account: "Deleting account",
  total_sales: "Total sales",
  total_subscriptions_sales: "Total subscriptions sales",
  total_credits_sales: "Total credits sales",
  my_earnings: "My earnings",
  please_type_keyword: "Please type a keyword",
  reports: "Reports",
  total_sales_dollars: "Total sales dollars",
  total_sales_credits: "Total sales credits",
  general_sales_resume: "General sales resume",
  info_and_manage_users: "Info and manage users",
  total_sales_by_subscriptions: "Total sales by subscriptions",
  total_sales_by_credits: "Total sales by credits",
  past_month: "Past month",
  phone_is_required: "Phone is required",
  is_required: "is required",
  connect_with: "Connect with",
  send_a_gift: "Send a gift",
  send_gift: "Send gift",
  optional_message: "Optional message",
  sending_gift: "Sending gift",
  sending_gift_to: "Sending gift to",
  creator_earnings: "Creator earnings",
  set_commission_percent: "Set commission percent",
  subscribe_advantages: ["Access to exclusive content", "Direct messages", "Cancel your subscription anytime"],
  account: "Account",
  total_sales_net: "Total sales net",
  net: "Net",
  instagram_is_required: "Instagram is required",
  enjoy_all_creator_content: "Enjoy full access to Premium content of $creator",
  language_button: "Language",
  file_size_is_too_big: "File size is too big",
  max_size: "Max size",
  the_file: "The file",
  post_allow_formats: "Post allow formats",
  is_not_supported: "is not supported",
  is_too_large: "is too large",
  subscriptions_cancelled: "Subscriptions cancelled",
  subscriptions_cancelled_desc: "Subscriptions cancelled by users",
  message_empty: "Message empty",
  unlock_content_sure_by: "Are you sure you want to unlock this content by?",
  for_unlock: "For unlock",
  need_update_username_desc: `You need to update your username to continue`,
  username_features: "Your username must be between 6 and 20 characters long and can only contain letters and numbers",
  confirm: "Confirm",
  sure_update_username: "Are you sure you want to update your username?",
  report_label: "Report",
  general: "General",
  date_review: "Date review",
  sending_message: "Sending message",
  payments: "Payments",
  payments_desc: "Payments made by users",
  date: "Date",
  purchaseid: "Purchase ID",
  mount: "Mount",
  tranid: "Transaction ID",
  total_pays: "Total pays",
  total_mount: "Total mount",
  type: "Type",
};
