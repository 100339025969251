const Misc = {};

export default Misc;

Misc.capitalize = function(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

Misc.removeSpaces = function(string) {
  return string.replace(/\s/g, "");
};

Misc.fastID = function() {
  // Generate a random ID that is very likely to be unique.
  const chr4 = () =>
    Math.random()
      .toString(16)
      .slice(-4);
  return chr4() + chr4() + "-" + chr4() + "-" + chr4() + "-" + chr4() + "-" + chr4() + chr4() + chr4();
};

Misc.uuid = function() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

Misc.lowercase = function(string) {
  return string.toLowerCase();
};

Misc.uppercase = function(string) {
  return string.toUpperCase();
};

Misc.lower = function(string) {
  return Misc.lowercase(string);
};

Misc.upper = function(string) {
  return Misc.uppercase(string);
};

Misc.toCamelCase = function(string) {
  return string.replace(/-([a-z])/g, function(g) {
    return g[1].toUpperCase();
  });
};

Misc.toDashCase = function(string) {
  return string.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
};

Misc.toSnakeCase = function(string) {
  return string.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase();
};

Misc.replaceVars = function(string, data) {
  // replace all between {{ and }} with data
  const regex = /{{(.*?)}}/g;
  const matches = string.match(regex);
  if (matches) {
    matches.forEach((match) => {
      const key = match
        .replace("{{", "")
        .replace("}}", "")
        .trim();
      const regex2 = new RegExp(`{{${key}}}`, "g");
      string = string.replace(regex2, Go.getProperty(data, key));
    });
  }
  return string;
};

Misc.cutString = function(string, length) {
  if (string && string.length > length) {
    return string.substring(0, length);
  }
  return string;
};

Misc.getString = function(string, from, length) {
  if (string && string.length > length) {
    return string.substring(from, length);
  }
  return string;
};

Misc.removeLastStringIf = function(string, remove) {
  if (string && string.endsWith(remove)) {
    return string.substring(0, string.length - remove.length);
  }
  return string;
};

Misc.removeFirstStringIf = function(string, remove) {
  if (string && string.startsWith(remove)) {
    return string.substring(remove.length);
  }
  return string;
};

Misc.removeLastString = function(string, length) {
  if (string && string.length > length) {
    return string.substring(0, string.length - length);
  }
  return string;
};
